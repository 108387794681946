<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Merchant Settlement</h1>
            <hr>
            <mdb-row>
                <mdb-col md="2">
                    <div class="form-group">
                        <mdb-date-picker :autoHide="true" @close="getData()" v-model="date_from" label="Date From" />
                    </div>
                </mdb-col>
                <mdb-col md="2">
                    <div class="form-group">
                        <mdb-date-picker :autoHide="true" @close="getData()" v-model="date_to" label="Date To" />
                    </div>
                </mdb-col>
                <!-- <mdb-col md="3" class="align-items-center d-flex">
                    <mdb-select
                    v-model="list"
                    class="w-100"
                    placeholder="Select Merchant"
                    v-on:getValue="selectMerchant"
                    />
                </mdb-col> -->
                <mdb-col md="5" class="align-items-center d-flex">
                    <mdb-btn @click="generateBanking" color="primary"><i class="fas fa-file-download"></i> Generate Banking</mdb-btn>
                </mdb-col>
            </mdb-row>
            <p class="text-danger">Please do not use this to generate merchant settlement earlier than 21 Feb 2021.</p>
            <p class="text-danger">Select only 1 week from Start Monday to Next Monday.</p>
            <mdb-tbl responsive hover v-if="!loading">
                <mdb-tbl-head>
                <tr>
                    <th width="10%">#</th>
                    <th width="50%">Name</th>
                    <th width="50%">Action</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(merchant, index) in list" :key="index">
                    <th scope="row">{{index+1}}</th>
                    <td>
                        {{merchant.text}}
                    </td>
                    <td>
                        <mdb-btn  @click="generateStatement(merchant.value)" color="primary"><i class="fas fa-file-download"></i> Generate Statement</mdb-btn>
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbDatePicker, mdbBtn, mdbTbl, mdbTblHead, mdbTblBody } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_STORAGE_URL} from '../js/config';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'merchant_settlement',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbBtn, mdbTblBody,
    AppNavigation, mdbDatePicker, mdbTbl, mdbTblHead
  },
  data: ()=>({
      merchant_list: [],
      date_from: "",
      date_to: "",
      loading:false,
      list:[],
      selected_merchant: null
  }),
  methods: {
        getData(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/get_merchant_list",
                data: {
                    date_from: this.date_from,
                    date_to: this.date_to
                },
                complete: ()=>this.loading=false,
                success: result=>{
                    this.list=[];
                    let _temp=[];
                    result.data.forEach(element => {
                        let obj={ text: element.merchant_name, value: element.merchant_profile_id.toString() }
                        _temp.push(obj);
                    });
                    this.list=_temp;
                },
            }, this)
        },
        selectMerchant(value){
            this.selected_merchant=value;
        },
        generateStatement(selected_merchant){
            let routeData = this.$router.resolve({ name: "merchant_settlement_print", query: { merchant_id: selected_merchant, from: this.date_from, to: this.date_to } });
            window.open(routeData.href, '_blank');
        },
        generateBanking(){
            ajax({
            url: "admin/generate_merchant_banking",
            data: {
                date_from: this.date_from,
                date_to: this.date_to
            },
            complete: ()=>this.loading=false,
            success: result=>{
                window.open(APP_STORAGE_URL+result.data);
            },
        }, this)
        }
  },
  mounted: function(){
        let date = new Date();
        let month = date.getMonth()+1;
        let today=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
        date.setDate(date.getDate()+1);
        let tomorrow=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
        this.date_from=today;
        this.date_to=tomorrow;
        this.getData();
  }
}
</script>
