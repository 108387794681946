<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>Promotion Banner</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row class="mb-3">
                <mdb-col>
                    <mdb-card>
                        <mdb-card-body>
                            <mdb-view style="height: 200px; width: 100%" class="mb-4">
                            <div class="promotion-img" v-bind:style="{ 'background-image': 'url(' + getImg() + ')' }">
                            </div>
                            <a @click="openFileSelect">
                                <mdb-mask overlay="white-slight"></mdb-mask>
                            </a>
                            <input multiple type="file" id="file-upload" @change="uploadImage" hidden />
                            </mdb-view>
                            <mdb-row>
                                <mdb-col col="12">
                                    <mdb-input v-model="name" label="Name" size="sm" />
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-spinner v-if="loading" />
        <mdb-btn v-if="!loading" @click="submitAdvertismentPopup" color="primary">{{this.id?"Edit":"Add"}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol, mdbView,mdbMask,
  mdbInput, mdbCard, mdbCardBody, mdbSpinner } from 'mdbvue';
  import {APP_FILE_URL} from '../js/config';
  import { ajax, fileUploadAjax } from '../js/ajax';

  export default {
    components: {
      mdbModal, mdbSpinner,
      mdbModalHeader,
      mdbModalTitle, mdbCardBody,
      mdbModalBody, mdbCard,
      mdbModalFooter, mdbInput,
      mdbBtn,mdbContainer, mdbRow, mdbCol, mdbView,mdbMask,
    },
    props: ['visible', 'close', "focus_promotion"],
    data: ()=>({
        img: "",
        app: "",
        url: "",
        name: "",
        id:0,
        loading:false,
    }),
    methods: {
        closeModal(){
            this.img="";
            this.name="";
            this.id=0;
            this.loading=false;
            this.close();
        },
        componentDidMount(){
            if(this.focus_promotion){
                this.img=this.focus_promotion.img;
                this.name=this.focus_promotion.name;
                this.id=this.focus_promotion.id;
            }
        },
        getImg(){
            if(!this.img){
                return require('@/assets/placeholder.jpg');
            }
            return APP_FILE_URL+this.img;
        },
        openFileSelect(){
            document.getElementById("file-upload").click();
        },
        uploadImage(event){
            fileUploadAjax({
                url: "admin/upload_ads_image",
                data: {event: event.target.files},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    this.img=result.data;
                },
                isShowAlert: true
            }, this)
        },
        submitAdvertismentPopup(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/submit_promotion_banner",
                data: this.$data,
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Advertisment has been added.', position:'top right', timeOut: 5000});
                    this.close();
                },
            }, this)
        }
    }
  };
</script>

<style scoped>
.promotion-img{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
    background-color: black;
}
</style>