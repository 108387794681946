<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Add Rider</h1>
            <hr>
            <mdb-row class="mb-3">
                <mdb-col>
                    <mdb-card>
                        <mdb-card-body>
                            <mdb-row>
                                <mdb-col col="12" class="d-flex flex-center">
                                    <div class="avatar-image" @click="openAvatarPicker()" :style="{backgroundImage:`url('`+getAvatar()+`')`}" />
                                    <input multiple type="file" id="avatar-upload" @change="avatarUpload" hidden />
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col>
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">User Information</h4>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input label="Login Username Email" v-model="user.username" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input label="Code Name" v-model="user.name" size="sm" />
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col>
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Profile Information</h4>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input label="Fullname" v-model="partner_profile.fullname" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input type="number" label="IC Number" v-model="partner_profile.identification_number" size="sm" />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input type="number" label="Phone Number" v-model="partner_profile.phone_number" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input label="Full Address" v-model="partner_profile.full_address" size="sm" />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input label="Email Settlement" v-model="partner_profile.business_email" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input label="Vehicle Registration Number" v-model="partner_profile.vehicle_registration_number" size="sm" />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input label="Vehicle Type" v-model="partner_profile.car_type" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input label="Vehicle Color" v-model="partner_profile.car_color" size="sm" />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input type="number" label="Payout Percentage" v-model="partner_profile.payout_percentage" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <!-- <mdb-input label="Type" v-model="partner_profile.type" size="sm" /> -->
                                    <label style="font-size: 0.8rem; color: #757575">Partner Type</label>
                                    <select v-model="partner_profile.type" class="custom-select custom-select">
                                        <option value="PART">Part Time</option>
                                        <option value="FULL">Full Time</option>
                                        <option value="TEST">Tester</option>
                                    </select>
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col md="6">
                                    <!-- <mdb-input label="License Expiry" v-model="partner_profile.license_expiry_date" size="sm" /> -->
                                    <mdb-date-picker :autoHide="true" v-model="partner_profile.license_expiry_date" label="License Expiry" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <!-- <mdb-input label="RoadTax Expiry" v-model="partner_profile.roadtax_expiry_date" size="sm" /> -->
                                    <mdb-date-picker :autoHide="true" v-model="partner_profile.roadtax_expiry_date" label="Road Tax Expiry" />
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col col="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Financial Information</h4>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input label="Bank Benefially Name" v-model="partner_profile.bank_account_name" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input type="number" label="Bank Account Number" v-model="partner_profile.bank_account_number" size="sm" />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row>
                                <mdb-col md="6">
                                    <select v-model="partner_profile.bank_name" class="custom-select custom-select">\
                                        <option value="" disabled>Select Bank Name</option>
                                        <option value="08-AmBank Berhad">08-AmBank Berhad</option>
                                        <option value="12-Alliance Bank Berhad">12-Alliance Bank Berhad</option>
                                        <option value="53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad">53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad</option>
                                        <option value="32-Affin Bank Berhad">32-Affin Bank Berhad</option>
                                        <option value="49-Agro Bank">49-Agro Bank</option>
                                        <option value="42-Bank of China (Malaysia) Berhad">42-Bank of China (Malaysia) Berhad</option>
                                        <option value="41-Bank Muamalat Malaysia Berhad">41-Bank Muamalat Malaysia Berhad</option>
                                        <option value="45-Bank Islam Malaysia Berhad">45-Bank Islam Malaysia Berhad</option>
                                        <option value="02-Bank Rakyat Berhad">02-Bank Rakyat Berhad</option>
                                        <option value="10-Bank Simpanan Nasional Berhad">10-Bank Simpanan Nasional Berhad</option>
                                        <option value="07-Bank of America">07-Bank of America</option>
                                        <option value="52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad">52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad</option>
                                        <option value="60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)">60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)</option>
                                        <option value="35-CIMB Bank Berhad">35-CIMB Bank Berhad</option>
                                        <option value="17-Citibank">17-Citibank</option>
                                        <option value="19-Deutsche Bank (M) Bhd">19-Deutsche Bank (M) Bhd</option>
                                        <option value="24-Hong Leong Bank Berhad/ Hong Leong Finance">24-Hong Leong Bank Berhad/ Hong Leong Finance</option>
                                        <option value="22-HSBC Bank Berhad">22-HSBC Bank Berhad</option>
                                        <option value="59-Indust & Comm Bank of China (M) Berhad">59-Indust & Comm Bank of China (M) Berhad</option>
                                        <option value="48-JP Morgan Chase">48-JP Morgan Chase</option>
                                        <option value="47-Kuwait Finance House">47-Kuwait Finance House</option>
                                        <option value="27-Malayan Banking Berhad">27-Malayan Banking Berhad</option>
                                        <option value="73-Mizuho Bank (M) Berhad">73-Mizuho Bank (M) Berhad</option>
                                        <option value="29-OCBC Bank Berhad">29-OCBC Bank Berhad</option>
                                        <option value="33-Public Bank Berhad/ Public Finance Berhad">33-Public Bank Berhad/ Public Finance Berhad</option>
                                        <option value="18-RHB Bank Berhad">18-RHB Bank Berhad</option>
                                        <option value="14-Standard Chartered Bank Berhad">14-Standard Chartered Bank Berhad</option>
                                        <option value="51-Sumitomo Mitsui Banking Corporation (M) Bhd ">51-Sumitomo Mitsui Banking Corporation (M) Bhd </option>
                                        <option value="46-The Royal Bank of Scotland Bhd">46-The Royal Bank of Scotland Bhd</option>
                                        <option value="26-United Overseas Bank (M) Bhd">26-United Overseas Bank (M) Bhd</option>
                                    </select>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col col="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Attachments</h4>
                            <mdb-row>
                                <mdb-col md="6" class="d-flex flex-column align-items-center">
                                    <img @click="openDocumentPicker('LICENSE')" class="waves-effect" :src="renderDocument('LICENSE')" height="300px" />
                                    <p>License</p>
                                </mdb-col>
                                <mdb-col md="6" class="d-flex flex-column align-items-center">
                                    <img @click="openDocumentPicker('ROADTAX')" class="waves-effect" :src="renderDocument('ROADTAX')" height="300px" />
                                    <p>Road Tax</p>
                                </mdb-col>
                                <input multiple type="file" id="document-upload" @change="documentUpload" hidden />
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-5">
                <mdb-col col="12" class="mb-5">
                    <mdb-card>
                        <mdb-card-body class="d-flex flex-row align-items-end justify-content-end">
                            <mdb-btn v-if="!loading" @click="registerPartner" color="primary">Create Account</mdb-btn>
                            <mdb-spinner v-if="loading" big color="blue" />
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </mdb-container>
        <SuccessModal :visible="success_modal" :text="success_text" :close="closeModal"/>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbCard, mdbCol, mdbRow, mdbCardBody, mdbInput, mdbBtn, mdbDatePicker, mdbSpinner } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {fileUploadAjax, ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import {getColor} from '../js/colors';
import {validateRequired, validateEmail, validateIC, validatePhone, validatePercentage} from '../js/validation';
import SuccessModal from '../components/SuccessModal';

export default {
    name: 'add_partner',
    components: {
        mdbContainer, mdbCard, mdbCol, mdbRow, mdbDatePicker, mdbSpinner,
        AppNavigation, mdbCardBody, mdbInput, mdbBtn, SuccessModal
    },
    data: ()=>({
        user: {
            name: "",
            username: ""
        },
        partner_profile: {
            avatar: "",
            fullname: "",
            identification_number: "",
            phone_number: "",
            full_address: "",
            business_email: "",
            vehicle_registration_number: "",
            car_type: "",
            car_color: "",
            payout_percentage: "",
            license_front: "",
            license_back: "",            
            bank_name: "",
            bank_account_name: "",
            bank_account_number: "",
            license_expiry_date: "",
            roadtax_expiry_date: "",
            type: "",
        },
        document_type: "",
        success_modal: false,
        success_text: "",
        loading: false,
    }),
    methods: {
        getColor(color){
            return getColor(color);
        },
        getAvatar(){
            if(this.partner_profile.avatar){
                return APP_FILE_URL+this.partner_profile.avatar;
            }
            return require('@/assets/profile.png');
        },
        renderDocument(type){
            if(type=="ROADTAX"){
                return this.partner_profile.license_back?APP_FILE_URL+this.partner_profile.license_back:require('@/assets/picture.png');
            }
            if(type=="LICENSE"){
                return this.partner_profile.license_front?APP_FILE_URL+this.partner_profile.license_front:require('@/assets/picture.png');
            }
        },
        openAvatarPicker(){
            document.getElementById("avatar-upload").click();
        },
        avatarUpload(){
            fileUploadAjax({
                url: "admin/upload_partner_avatar",
                data: {event: event.target.files},
                complete: ()=>this.action_loading=false,
                success: result=>{
                this.partner_profile.avatar=result.data;
                },
                isShowAlert: true
            }, this)
        },
        openDocumentPicker(type){
            this.document_type=type;
            document.getElementById("document-upload").click();
        },
        documentUpload(){
            fileUploadAjax({
                url: "admin/upload_document",
                data: {event: event.target.files},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    if(this.document_type=="ROADTAX"){
                        this.partner_profile.license_back=result.data;
                    }
                    if(this.document_type=="LICENSE"){
                        this.partner_profile.license_front=result.data;
                    }
                },
                isShowAlert: true
            }, this)
        },
        registerPartner(){
            if(!validateRequired(this.partner_profile.avatar)){
                this.$notify.error({message: 'Please Upload Avatar', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateEmail(this.user.username)){
                this.$notify.error({message: 'Invalid Login Username', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.user.name)){
                this.$notify.error({message: 'Code Name cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.fullname)){
                this.$notify.error({message: 'Fullname cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateIC(this.partner_profile.identification_number)){
                this.$notify.error({message: 'Invalid Identification Number', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validatePhone(this.partner_profile.phone_number)){
                this.$notify.error({message: 'Invalid Phone Number', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.full_address)){
                this.$notify.error({message: 'Address cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateEmail(this.partner_profile.business_email)){
                this.$notify.error({message: 'Invalid Email Settlement', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.vehicle_registration_number)){
                this.$notify.error({message: 'Vehicle registration number cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.car_type)){
                this.$notify.error({message: 'Vehicle type cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.car_color)){
                this.$notify.error({message: 'Vehicle Color cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validatePercentage(this.partner_profile.payout_percentage)){
                this.$notify.error({message: 'Invalid Payout Percentage', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.type)){
                this.$notify.error({message: 'Please select partner type', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.license_expiry_date)){
                this.$notify.error({message: 'Please insert license expiry date', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.roadtax_expiry_date)){
                this.$notify.error({message: 'Please insert roadtax expiry date', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.bank_account_name)){
                this.$notify.error({message: 'Please insert Bank Beneficial Name', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.bank_account_number)){
                this.$notify.error({message: 'Please insert Bank Account Number', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.bank_name)){
                this.$notify.error({message: 'Please Select Bank', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.license_front)){
                this.$notify.error({message: 'Please Upload License Image', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.partner_profile.license_back)){
                this.$notify.error({message: 'Please Upload RoadTax Image', position:'top right', timeOut: 5000});
                return false;
            }
            // ajax();
            this.loading=true;
            ajax({
                url: "admin/register_partner",
                data: {...this.user, ...this.partner_profile},
                complete: ()=>this.loading=false,
                success: result=>{
                    this.success_modal=true;
                    this.success_text=result.data?result.data:"Use previous sign up password";
                    this.user={name:"", username: ""};
                    this.partner_profile={
                        avatar: "",
                        fullname: "",
                        identification_number: "",
                        phone_number: "",
                        full_address: "",
                        business_email: "",
                        vehicle_registration_number: "",
                        car_type: "",
                        car_color: "",
                        payout_percentage: "",
                        license_front: "",
                        license_back: "",            
                        bank_name: "",
                        bank_account_name: "",
                        bank_account_number: "",
                        license_expiry_date: "",
                        roadtax_expiry_date: "",
                        type: "",
                    }
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        closeModal(){
            this.success_modal=false;
        }
    },
    mounted: function(){

    }
}
</script>

<style scoped>
.avatar-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 200px;
    border-radius: 200px;
}
</style>