<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Admins <mdb-btn @click="admin_modal=true" color="success" size="sm" class="my-0"><mdb-icon  icon="plus" /></mdb-btn></h1>
            <hr>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-tbl responsive striped>
                        <mdb-tbl-head>
                            <tr>
                                <th>#</th>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Last Login</th>
                                <th>Roles</th>
                                <th>Action</th>
                            </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                            <tr v-for="(user, index) in users" :key="user.id">
                                <th scope="row">{{index+1}}</th>
                                <td>
                                    {{user.username}}
                                </td>
                                <td>
                                    {{user.name}}
                                </td>
                                <td>
                                    {{momentFormat(user.last_login)}}
                                </td>
                                <td>
                                    <ol v-if="user.roles.length!=0">
                                        <li v-for="role in user.roles" :key="role.id">{{role.name}}</li>
                                    </ol>
                                </td>
                                <td>
                                    <mdb-btn @click="editUser(user)" color="primary" size="sm" class="my-0"><mdb-icon far icon="edit" /></mdb-btn>
                                    <mdb-btn @click="openConfirmationModal(user)" color="danger" size="sm" class="my-0"><mdb-icon far icon="trash-alt" /></mdb-btn>
                                </td>
                            </tr>
                        </mdb-tbl-body>
                    </mdb-tbl>
                </mdb-col>
            </mdb-row>
            <AdminModal :visible="admin_modal" :close="closeModal" :focus_user="focus_user" :roles="roles"/>
            <ConfirmationModal :visible="confirmation_modal" :close="closeModal" title="Confirm Delete Admin?" :submit="deleteAdmin" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {momentFormat16, momentFormat} from '../js/helper';
// import {APP_FILE_URL} from '../js/config';
import AdminModal from '../components/AdminModal';
import ConfirmationModal from '../components/ConfirmationModal';

export default {
  name: 'admins',
  components: {
    mdbContainer, mdbBtn,
    AppNavigation, mdbIcon, AdminModal,
    mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody,
    ConfirmationModal
  },
  data: ()=>({
      users: [],
      loading:false,
      admin_modal: false,
      focus_user: null,
      roles: [],
      confirmation_modal: false,
  }),
  methods: {
    closeModal(){
        this.focus_user=null;
        this.admin_modal=false;
        this.confirmation_modal=false;
        this.getData();
    },

    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/list_admins",
            complete: ()=>this.loading=false,
            success: result=>{
                this.users=result.data.users;
                this.roles=result.data.roles;
            },
        }, this)
    },    
    momentFormat16(date){
        return momentFormat16(date);
    },
    momentFormat(date){
        return momentFormat(date);
    },
    editUser(user){
        this.focus_user=user;
        this.admin_modal=true;
    },
    openConfirmationModal(user){
        this.focus_user=user;
        this.confirmation_modal=true;
    },
    deleteAdmin(){
        ajax({
            url: "admin/delete_admin",
            data: {id: this.focus_user.id},
            complete: ()=>this.loading=false,
            success: ()=>{
                this.$notify.success({message: "Admin has been deleted.", position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    }
  },
  mounted: function(){
    this.getData();
  }
}
</script>
