<template>
    <AppNavigation>
        <mdb-container fluid>
            <mdb-container>
                <h1 class="h1-responsive">Partner Payout</h1>
                <hr>
                <mdb-row>
                    <mdb-col md="3">
                        <div class="form-group">
                            <mdb-date-picker :autoHide="true" v-model="date_from" label="Date From" />
                        </div>
                    </mdb-col>
                    <mdb-col md="3">
                        <div class="form-group">
                            <mdb-date-picker :autoHide="true"  v-model="date_to" label="Date To" />
                        </div>
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-btn @click="getData()" v-if="!loading" outline="primary"><mdb-icon icon="search" /> Search</mdb-btn>
                        <mdb-spinner small v-if="excel_loading || loading" />
                        <mdb-btn v-if="!excel_loading" @click="downloadExcel" outline="primary"><i class="fas fa-file-download"></i> Download Excel</mdb-btn>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="3">
                        <select v-model="selected_partner" class="browser-default custom-select">
                            <option value="" selected>All</option>
                            <option v-for="partner in partner_list" v-bind:key="partner" :value="partner">{{partner}}</option>
                        </select>
                    </mdb-col>
                    <mdb-col md="9">
                    <div style="font-weight: bold">Total Cash Collected RM {{parseFloat(total_cash).toFixed(2)}}</div>
                    </mdb-col>
                </mdb-row>
                <div v-if="loading">Wait.. Processing dont panic...</div>
                <mdb-tbl responsive hover v-if="!loading">
                    <mdb-tbl-head>
                    <tr>
                        <th width="5%">#</th>
                        <th width="30%">Name</th>
                        <th width="10%">Order Number</th>
                        <th class="text-right" width="10%">Commission</th>
                        <th class="text-right" width="10%">Reward</th>
                        <th class="text-right" width="10%">Subtotal</th>
                        <th class="text-right" width="10%">Payment Type</th>
                        <th class="text-right" width="15%">Date / Time</th>
                    </tr>
                    </mdb-tbl-head>
                    <mdb-tbl-body>
                    <tr v-for="(partner, index) in data" :key="partner.id" @click="showOrderDetail(partner)">
                        <th scope="row">{{index+1}}</th>
                        <td>
                            {{partner.fullname}}
                        </td>
                        <td>
                            {{partner.order_number}}
                            <div>
                                <mdb-tooltip v-if="partner.comment_admin" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Admin Remarks</span>
                                <a slot="reference">
                                <mdb-icon  style="color:#f0bb1b" far icon="sticky-note" />
                                </a>
                                </mdb-tooltip>
                            </div>
                        </td>
                        <td class="text-right">
                            {{partner.commission}}
                        </td>
                        <td class="text-right">
                            <div v-if="partner.service_order.partner_peak">Peak: {{partner.service_order.partner_peak.service_charge_node.amount}}</div>
                            <div v-if="partner.service_order.partner_reward">Reward: {{partner.service_order.partner_reward.service_charge_node.amount}}</div>
                            <div v-if="partner.service_order.partner_ot">OT: {{partner.service_order.partner_ot.service_charge_node.amount}}</div>
                        </td>
                        <td class="text-right">
                            {{partner.subtotal}}
                        </td>
                        <td class="text-right">
                            <mdb-tooltip v-if="partner.payment_type=='ONLINE_BANKING'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Online Banking</span>
                                <a slot="reference">
                                <img :src="require('@/assets/online_banking.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="partner.payment_type=='CASH'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Cash</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/cash.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="partner.payment_type=='CREDIT_DEBIT_CARD'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Card</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/card.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="partner.payment_type=='EWALLET'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">E-Wallet</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/e_wallet.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            RM {{partner.service_order_amount}}
                        </td>
                        <td class="text-right">
                            {{momentFormat16(partner.created_at)}}
                        </td>
                    </tr>
                    </mdb-tbl-body>
                </mdb-tbl>
            </mdb-container>
            <OrderDetailComponent :id="focus_order_id" :visible="order_detail_component" :close="closeOrderDetailComponent" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol, mdbTooltip, mdbDatePicker, mdbBtn, mdbSpinner, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_STORAGE_URL} from '../js/config';
import {momentFormat16} from '../js/helper';
import OrderDetailComponent from '../components/OrderDetailComponent';

export default {
  name: 'partner_payout',
  components: {
    mdbContainer, mdbTooltip,
    AppNavigation, mdbRow, mdbCol,
    mdbTbl, mdbTblHead, mdbTblBody, 
    mdbDatePicker, mdbBtn, mdbSpinner,
    OrderDetailComponent, mdbIcon
  },
  data: ()=>({
      loading:false,
      data: [],
      date_from: "",
      date_to: "",
      rangeLimit: [],
      focus_order_id: 0,
      order_detail_component: false,
      partner_list: [],
      selected_partner: "",
      all_data: [],
      total_cash: 0,
      excel_loading: false,
      collections: [],
      screen: 0
  }),
  methods: {
      getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/get_partner_payout",
            data: {
                date_from: this.date_from,
                date_to: this.date_to
            },
            complete: ()=>this.loading=false,
            success: result=>{
                this.total_cash=0;
                this.data=result.data;
                this.all_data=result.data;
                let collections=new Object();
                let partners = new Set();
                result.data.forEach(partner => {
                    partners.add(partner.fullname);
                    this.total_cash+=partner.payment_type=="CASH"?parseFloat(partner.service_order_amount):0;
                    if(collections[partner.service_order.partner_user_id]!==undefined){
                        let temp = {...collections[partner.service_order.partner_user_id]};
                        switch(partner.payment_type){
                            case "CASH": {temp.cash+=parseFloat(partner.service_order_amount); break;}
                            case "ONLINE_BANKING" : {temp.online_banking+=parseFloat(partner.service_order_amount); break;}
                            case "CREDIT_DEBIT_CARD" : {temp.card+=parseFloat(partner.service_order_amount); break;}
                            case "EWALLET" : {temp.ewallet+=parseFloat(partner.service_order_amount); break;}
                        }
                        collections[partner.service_order.partner_user_id]=temp;
                    }else{
                        collections[partner.service_order.partner_user_id]={
                            fullname: partner.fullname,
                            cash: partner.payment_type=="CASH"?parseFloat(partner.service_order_amount):0,
                            online_banking: partner.payment_type=="ONLINE_BANKING"?parseFloat(partner.service_order_amount):0,
                            card: partner.payment_type=="CREDIT_DEBIT_CARD"?parseFloat(partner.service_order_amount):0,
                            ewallet: partner.payment_type=="EWALLET"?parseFloat(partner.service_order_amount):0
                        }
                    }
                });
                this.partner_list=partners;
                for(let collect in collections){
                    this.collections.push(collections[collect]);
                }
            },
        }, this)
      },
      downloadExcel(){
        if(this.excel_loading){
            return false;
        }
        this.excel_loading=true;
        ajax({
            url: "admin/excel_partner_payout",
            data: {
                date_from: this.date_from,
                date_to: this.date_to
            },
            complete: ()=>this.excel_loading=false,
            success: result=>{
                window.open(APP_STORAGE_URL+result.data);
            },
        }, this)
      },
      momentFormat16(date){
          return momentFormat16(date);
      },
      closeOrderDetailComponent(){
        this.order_detail_component=false;
        this.focus_order_id=0;
      },
      showOrderDetail(partner){
          this.focus_order_id=partner.service_order_id;
          this.order_detail_component=true;
      }
  },
  mounted: function(){
    let date = new Date();
    let month = date.getMonth()+1;
    let today=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
    date.setDate(date.getDate()+1);
    let tomorrow=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
    this.date_from=today;
    this.date_to=tomorrow;
    this.getData();
  },
  watch: {
        selected_partner(){
            this.loading=true;
            this.data=[];
            this.total_cash=0;
            if(this.selected_partner==""){
                this.all_data.forEach(element => {
                    this.data.push(element);
                    this.total_cash+=element.payment_type=="CASH"?parseFloat(element.service_order_amount):0;
                });
            }else{
                this.all_data.forEach(element => {
                    if(element.fullname==this.selected_partner){
                        this.data.push(element);
                        this.total_cash+=element.payment_type=="CASH"?parseFloat(element.service_order_amount):0;
                    }
                });
            }
            
            setTimeout(() => {
                this.loading=false;
            }, 300);
            return true;
        }
  }
}
</script>

<style scoped>
.nav .tabs-indigo{
    z-index: 0 !important;
}
</style>