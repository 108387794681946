<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Zone Coverage</h1>
            <hr>
            <div id="map"></div>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'zone_coverage',
  components: {
    mdbContainer,
    AppNavigation,
  },
  data: ()=>({
      zones: []
  }),
  methods: {
      getData(){
        ajax({
            url: "admin/get_zone_coverage",
            success: result=>{
                this.zones=result.data;
                this.renderMap();
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        }) 
      },
      renderMap(){
        const google = window.google;
        if(!this.map){
            var mapProp = {
                center:new google.maps.LatLng(3.0764785,101.4828676),
                zoom:12,
            };
            this.map=new google.maps.Map(document.getElementById("map"),mapProp);
        }
        this.zones.forEach(zone=>{
            zone.zone_coverage.forEach(coverage=>{
                const coverage_geolocation = [
                    { lat: parseFloat(coverage.lat_1), lng: parseFloat(coverage.lng_1) },
                    { lat: parseFloat(coverage.lat_2), lng: parseFloat(coverage.lng_2) },
                    { lat: parseFloat(coverage.lat_3), lng: parseFloat(coverage.lng_3) },
                    { lat: parseFloat(coverage.lat_4), lng: parseFloat(coverage.lng_4) },
                ];
                // Construct the polygon.
                const coverage_map = new google.maps.Polygon({
                    paths: coverage_geolocation,
                    strokeColor: zone.color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: zone.color,
                    fillOpacity: 0.35,
                });                
                coverage_map.setMap(this.map);  
                var label_latlng = new google.maps.LatLng(coverage.lat_1, coverage.lng_1);

                var marker = new google.maps.Marker({
                    position: label_latlng,
                    title:'CoverageID: '+coverage.id+' Zone Name: '+zone.name
                });
                marker.setMap(this.map);
            })
        })
      }
  },
  mounted: function(){
      this.getData();
  }
}
</script>

<style scoped>
#map{
    height: 70vh;
    width: 100%;
}
</style>