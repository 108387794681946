<template>
    <AppNavigation>
        <mdb-container fluid>
            <mdb-row v-if="merchant" class="mb-3">
                <mdb-col col="1" class="d-flex flex-center">
                    <mdb-btn @click="$router.back()" size="sm" color="primary"><i class="fas fa-arrow-left"></i></mdb-btn>
                </mdb-col>
                <mdb-col col="10" class="d-flex flex-row align-items-center">
                    <div class="promotion-img"  v-bind:style="{ 'background-image': 'url(' + getAvatar() + ')' }"/>
                    <div class="d-flex flex-column justify-content-center ml-3">
                        <div class="font-weight-bold">{{merchant.name}} <!--(Recommended: {{count_top}})--></div>
                        <div><mdb-icon far icon="envelope" /> {{merchant.email}}</div>
                        <div><mdb-icon  icon="map-marker-alt" /> {{merchant.address}}</div>
                        <div><mdb-icon  icon="phone" /> {{merchant.contact_number}}</div>
                        <div v-if="merchant.description"><mdb-icon  icon="comments" /> {{merchant.description}}</div>
                    </div>
                </mdb-col>
            </mdb-row>
            <mdb-row v-if="merchant">
                <mdb-col col="6" style="max-height: 65vh; overflow-y: auto">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive">Product Category <mdb-btn size="sm" @click="showCategoryModal" color="success">Add Category</mdb-btn></h4>
                            <hr />
                            <div @dragstart="dragStart(index, 'CATEGORY')" @dragenter.prevent @dragover.prevent draggable="true" @drop="dropHere(index, 'CATEGORY')" @click="selectCategory(category, index)" :class="{'active': focus_index==index}" class="category-container row" v-for="(category, index) in category_products" :key="category.id">
                                <mdb-col  class="d-flex flex-column" col="6">
                                    {{category.name}}
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="custom-control custom-switch" @click="toggleCategoryActive(category, index)">
                                            <input type="checkbox" class="custom-control-input" v-model="category.is_active">
                                            <label class="custom-control-label"></label>
                                        </div>
                                        <div>{{category.is_active==1?"Active":"Inative"}}</div>
                                    </div>
                                    
                                </mdb-col>
                                <mdb-col class="d-flex flex-row justify-content-end" col="6">
                                    <!-- <mdb-btn @click="selectCategory(category, index)" color="primary" icon="eye" size="sm"></mdb-btn> -->
                                    <mdb-btn @click="editCategory(category)" color="success" icon="edit" size="sm"></mdb-btn>
                                    <mdb-btn @click="showDeleteCategory(category)" color="danger" icon="trash" size="sm"></mdb-btn>
                                </mdb-col>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col col="6" style="max-height: 65vh; overflow-y: auto">
                    <mdb-card>
                        <mdb-card-body v-if="category_products.length>0">
                            <h4 class="h4-responsive">Products <mdb-btn size="sm" @click="showProductModal()" color="success">Add Product</mdb-btn></h4>
                            <hr />
                            <mdb-row class="product-row" v-for="(product, index) in category_products[focus_index].all_products" :key="product.id">
                                <mdb-col>
                                    <div @dragenter.prevent @dragover.prevent draggable @dragstart="dragStart(index, 'PRODUCT')" @drop="dropHere(index, 'PRODUCT')">
                                    <mdb-row>
                                        <mdb-col col="8">
                                            <div class="product-name d-flex flex-row align-items-center">
                                                <div class="custom-control custom-switch" @click="toggleProductActive(product, index)">
                                                    <input type="checkbox" class="custom-control-input" v-model="product.is_active">
                                                    <label class="custom-control-label"></label>
                                                </div>
                                                {{product.name}}
                                            </div> 
                                            <div class="product-description">{{product.description}}</div> 
                                            <div class="product-price">RM {{product.price}}</div> 
                                            <div class="d-flex flex-row">
                                                <mdb-btn @click="submitRecommended(product)" v-if="product.is_top==0" color="primary" icon="arrow-circle-up" size="sm"></mdb-btn>
                                                <mdb-btn @click="submitRecommended(product)" v-if="product.is_top==1" color="danger" icon="arrow-circle-down" size="sm"></mdb-btn>
                                                <mdb-btn @click="focus_product=product; product_modal=true;" color="success" icon="edit" size="sm"></mdb-btn>
                                                <mdb-btn @click="showDeleteProduct(product)" color="danger" icon="trash" size="sm"></mdb-btn>
                                            </div>
                                        </mdb-col>
                                        <mdb-col col="4">
                                            <div class="product-img"  v-bind:style="{ 'background-image': 'url(' + getProductImage(product.avatar) + ')' }"/>
                                        </mdb-col>
                                    </mdb-row>
                                    </div>
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <MerchantCategory :merchant_id="this.$route.params.id" :visible="merchant_category_modal" :close="closeModal" :focus_category="focus_category" />
            <ProductComponent :focus_category="focus_category" :merchant_id="this.$route.params.id" :visible="product_modal" :close="closeModal" :focus_product="focus_product" />
            <ConfirmationModal :visible="confirmation_category" :close="closeModal" title="Confirm Delete Category?" :submit="deleteCategory" />
            <ConfirmationModal :visible="confirmation_product" :close="closeModal" title="Confirm Delete Product?" :submit="deleteProduct" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbIcon, mdbBtn, mdbCard, mdbCardBody } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import MerchantCategory from '../components/MerchantCategory';
import ProductComponent from '../components/ProductsComponent'
import ConfirmationModal from '../components/ConfirmationModal'

export default {
  name: 'merchant_category_products',
  components: {
    mdbContainer, mdbRow, mdbCol, ProductComponent,
    AppNavigation, mdbIcon, MerchantCategory,
    mdbBtn, mdbCard, mdbCardBody, ConfirmationModal
  },
  data: ()=>({
      category_products: [],
      merchant: null,
      loading: false,
      focus_index: 0,
      merchant_category_modal: false,
      product_modal: false,
      focus_category: null,
      focus_product: null,
      drag_index: 0,
      drag_type:"",
      count_top:0,
      focus_delete_id:0,
      confirmation_category: false,
      confirmation_product:false
  }),
  methods: {
      closeModal(){
          this.merchant_category_modal=false;
          this.product_modal=false;
          this.confirmation_category=false;
          this.confirmation_product=false;
          this.focus_category=this.category_products[this.focus_index];
          this.getData();
      },
      getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/get_merchant_detail",
            data: {
                id: this.$route.params.id
            },
            complete: ()=>this.loading=false,
            success: result=>{
                this.merchant=result.data.merchant;
                this.category_products=result.data.category_products;
                this.count_top=result.data.count_top;
                if(result.data.category_products.length>0){
                    this.focus_category=result.data.category_products[this.focus_index];
                }
            },
        }, this)
      },
      getAvatar(){
          if(!this.merchant.avatar){
              return require('@/assets/placeholder_empty.jpg');
          }
          return APP_FILE_URL+this.merchant.avatar;
      },
      getProductImage(avatar){
          if(!avatar){
              return require('@/assets/placeholder_empty.jpg');
          }
          return APP_FILE_URL+avatar;
      },
      toggleCategoryActive(category, index){
        let temp=[...this.category_products];
        temp[index].is_active=temp[index].is_active==0?1:0;
        this.category_products=temp;
        ajax({
            url: "admin/toggle_category_activity",
            data: {
                id: category.id
            },
            complete: ()=>this.loading=false,
            success: ()=>{
                this.$notify.success({message: 'Category has been updated.', position:'top right', timeOut: 5000});
            },
        },this)
      },
      toggleProductActive(product, index){
        let temp=[...this.category_products];
        temp[this.focus_index].all_products[index].is_active=temp[this.focus_index].all_products[index].is_active==0?1:0;
        this.category_products=temp;
        ajax({
            url: "admin/toggle_product_activity",
            data: {
                id: product.id
            },
            complete: ()=>this.loading=false,
            success: ()=>{
                this.$notify.success({message: 'Product has been updated.', position:'top right', timeOut: 5000});
            },
        },this)
      },
      deleteCategory(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/delete_category",
            data: {
                id: this.focus_delete_id
            },
            complete: ()=>this.loading=false,
            success: ()=>{
                this.focus_index=0;
                this.$notify.success({message: 'Category has been Deleted.', position:'top right', timeOut: 5000});
                this.getData();
            },
        },this)
      },
      selectCategory(category, index){
        this.focus_index=index;
        this.focus_category=category;
      },
      deleteProduct(){
          if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/delete_product",
            data: {
                product_id: this.focus_delete_id
            },
            complete: ()=>this.loading=false,
            success: ()=>{
                this.$notify.success({message: 'Product has been Deleted.', position:'top right', timeOut: 5000});
                this.getData();
            },
        },this)
      },
      editCategory(category){
          this.focus_category=category; 
          this.merchant_category_modal=true;
      },
      showProductModal(){
          this.product_modal=true;
          this.focus_product=null;
      },
      showCategoryModal(){
        this.merchant_category_modal=true;
        this.focus_category=null;
      },
      dragStart(index, type){
          console.log('drag start');
        this.drag_index=index;
        this.drag_type=type;
      },
      dropHere(index, type){
        if(type=="CATEGORY" && this.drag_type=="CATEGORY"){
            //switch category sequence
            ajax({
                url: "admin/switch_category_sequence",
                data: {
                    id_1: this.category_products[index].id,
                    id_2: this.category_products[this.drag_index].id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Category Switched.', position:'top right', timeOut: 5000});
                    this.getData();
                },
            },this)
            return true;
        }4
        if(type=="PRODUCT" && this.drag_type=="PRODUCT"){
            //switch product sequence
            ajax({
                url: "admin/switch_product_sequence",
                data: {
                    id_1: this.category_products[this.focus_index].all_products[this.drag_index].id,
                    id_2: this.category_products[this.focus_index].all_products[index].id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Product Switched.', position:'top right', timeOut: 5000});
                    this.getData();
                },
            },this)
            return true;
        }
        if(type=="CATEGORY" && this.drag_type=="PRODUCT"){
            //switch product category
            let category_id=this.category_products[index].id;
            let product_id=this.category_products[this.focus_index].all_products[this.drag_index].id;
            ajax({
                url: "admin/switch_product_category",
                data: {
                    product_id: product_id,
                    category_id: category_id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Product Transfered.', position:'top right', timeOut: 5000});
                    this.getData();
                },
            },this)
            return true;
        }
        this.$notify.danger({message: 'Helo, category cannot transfer into product.', position:'top right', timeOut: 5000});
        return false;
      },
      submitRecommended(product){
        ajax({
            url: "admin/toggle_recommended",
            data: {
                id: product.id,
            },
            success: ()=>{
                this.$notify.success({message: 'Product Updated', position:'top right', timeOut: 5000});
                this.getData();
            },
        },this)
      },
      showDeleteProduct(product){
          this.focus_delete_id=product.id;
          this.confirmation_product=true;
      },
      showDeleteCategory(category){
          this.focus_delete_id=category.id;
          this.confirmation_category=true;
      }
  },
  mounted: function(){
    this.getData();
  },
}
</script>

<style scoped>
.promotion-img{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 120px;
    width: 240px;
    background-color: black;
}
.product-img{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 75px;
    width: 150px;
    background-color: transparent;
}
.product-name{
    font-size: 0.85rem;
    font-weight: bold;
}
.product-description{
    font-size: 0.75rem;
    color: #9e9e9e;
}
.product-price{
    font-size: 0.8rem;
}

.product-row{
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.category-container{
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.category-container.active{
    background-color: #3f51b5;
    color: #fff;
}
</style>