import {APP_DEBUG, APP_API_URL} from './config';
import store from '../store';

export const ajax = (obj, vue)=>{
    const method = obj.method ? obj.method : "POST";
    const header = store.state.api_token?{'Accept': 'application/json', 'Content-Type': 'application/json', 'access-token': store.state.api_token}:{'Accept': 'application/json', 'Content-Type': 'application/json'}
    const body = JSON.stringify(obj.data);
    if(APP_DEBUG){
        console.log(APP_API_URL + obj.url, body);
    }
    return Promise.race([
        new Promise((resolve, reject) =>
            fetch(APP_API_URL + obj.url, {
                method: method,
                headers: header,
                body: body,
                timeout: 2000
            })
            .then((response) => resolve(response.json()))
            .catch((error) => {
                reject(error);
            })
        ),
        timeout(20000)
    ]).then(responseJson=>{
        if(APP_DEBUG){
            console.log(APP_API_URL + obj.url, responseJson);
        }
        obj.complete?obj.complete():null;
        if(responseJson == "ConnectionTimeout"){
            obj.fail?obj.fail({message: "Connection Timeout"}):null;
            if(vue){
                vue.$notify.error({message: "Connection Timeout", position:'top right', timeOut: 5000});
            }
            return false;
        }
        if(responseJson.isSuccess){
            obj.success? obj.success(responseJson):null;
        }else{
            obj.fail?obj.fail(responseJson):null;
            if(vue){
                vue.$notify.error({message: responseJson.message, position:'top right', timeOut: 5000});
            }
            if(responseJson.code=="AUTH_REQUIRED"){
                localStorage.clear();
                window.location.href="/";
            }
        }
    }).catch(e=>{
        if(APP_DEBUG){
            console.log(APP_API_URL + obj.url, e);
        }
        obj.fail?obj.fail(e):null;
        obj.complete?obj.complete():null;
        if(vue){
            vue.$notify.error({message: e, position:'top right', timeOut: 5000});
        }
    })
}

export const fileUploadAjax = (obj, vue)=>{
    const method = obj.method ? obj.method : "POST";
    const header = store.state.api_token?{'Accept': 'application/json', 'access-token': store.state.api_token}:{'Accept': 'application/json', 'Content-Type': 'application/json'}

    var data = new FormData();
    data.append('image', obj.data.event[0]);
    obj.data.event.forEach(element => {
        data.append("images[]", element);
    });
    let keys=Object.keys(obj.data);
    keys.forEach(element => {
        
        if(element!='event'){
            data.append(element, obj.data[element]);
        }
    });
    return Promise.race([
        new Promise((resolve, reject) =>
            fetch(APP_API_URL + obj.url, {
                method: method,
                headers: header,
                body: data
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if(APP_DEBUG){
                    console.log(APP_API_URL + obj.url, responseJson);
                }
                if(responseJson.isSuccess){
                    obj.success? obj.success(responseJson):null;
                }else{
                    obj.fail?obj.fail(responseJson):null;
                    if(vue){
                        vue.$notify.error({message: responseJson.message, position:'top right', timeOut: 5000});
                    }
                    if(responseJson.code=="AUTH_REQUIRED"){
                        localStorage.clear();
                        window.location.href="/";
                    }
                }
                obj.complete?obj.complete():null;
                resolve(responseJson);
            })
            .catch((error) => {
                if(APP_DEBUG){
                    console.log(error);
                }
                if(vue){
                    vue.$notify.error({message: error, position:'top right', timeOut: 5000});
                }
                reject('Something went wrong. Please Contact Administrator');
            })
        ),
        timeout(20000)
    ])
}

export const timeout = (ms = 10000) =>
{
    return new Promise((resolve) =>setTimeout(() => resolve('ConnectionTimeout'), ms));
}