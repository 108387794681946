<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Merchant List</h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <input v-on:keyup.enter="startSearch" v-model="search" type="text" placeholder="Name" class="form-control">
                    </div>
                </mdb-col>
                <mdb-col md="3">
                    <select @change="startSearch" v-model="filter_active" class="browser-default custom-select">
                        <option value="" selected>All Active/Inactive</option>
                        <option value="1">Active</option>
                        <option value="2">Inative</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <select @change="startSearch" v-model="zone_id" class="browser-default custom-select">
                        <option value="0" selected>All Zones</option>
                        <option :value="zone.id" v-for="zone in zones" v-bind:key="zone.id" selected>{{zone.name}}</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <mdb-btn @click="startSearch" size="sm" color="primary">Search</mdb-btn>
                </mdb-col>
            </mdb-row>
            <mdb-tbl responsive striped>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th width="35%">Name</th>
                    <th width="40%">Info</th>
                    <th width="20%">Action</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(merchant, index) in merchants" :key="merchant.id">
                    <th scope="row">{{index+1}}</th>
                    <td class="font-weight-bold d-flex flex-row">
                        <mdb-view :src="getAvatar(merchant.avatar)" class="rounded" alt="image" style="height: 100px; width: 150px;">
                        <a>
                            <mdb-mask overlay="white-slight"></mdb-mask>
                        </a>
                        </mdb-view>
                        <div class="d-flex flex-column justify-content-center ml-1">
                            <div>{{merchant.name}}</div>
                            <div>
                                <mdb-tooltip v-if="merchant.website" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">WebSite</span>
                                    <a slot="reference" target="_blank" :href="merchant.website">
                                        <mdb-icon  style="margin-right: 5px; color: #00C851" icon="globe"/>
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Google Map</span>
                                    <a slot="reference" target="_blank" :href="`https://www.google.com/maps?q=${merchant.lat},${merchant.lng}`">
                                        <mdb-icon style="margin-right: 5px; color: #00C851" icon="map-marked-alt" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="merchant.is_halal_cert==1" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Halal</span>
                                    <a slot="reference">
                                        <img :src="require('@/assets/Icon_Halal_Active.png')" style="height: 20px; width: 20px; margin-right: 5px;"/>
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="merchant.tax==1" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Tax</span>
                                    <a slot="reference">
                                        <img :src="require('@/assets/Icon_Tax_Active.png')" style="height: 20px; width: 20px"/>
                                    </a>
                                </mdb-tooltip>
                            </div>
                            <div v-if="permissionCheck(131072)" class="custom-control custom-switch" @click="toggleActive(merchant, index)">
                                <input type="checkbox" class="custom-control-input" v-model="merchant.is_active">
                                <label class="custom-control-label">Active</label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div><mdb-icon far icon="envelope" /> {{merchant.email}}</div>
                        <div><mdb-icon  icon="map-marker-alt" /> {{merchant.address}}</div>
                        <div><mdb-icon  icon="phone" /> {{merchant.contact_number}}</div>
                        <div v-if="merchant.description"><mdb-icon  icon="comments" /> {{merchant.description}}</div>
                    </td>
                    <td>
                        <div class="d-flex flex-row justify-content-around align-items-center">
                            <mdb-tooltip trigger="hover" v-if="permissionCheck(8192)" :options="{placement: 'top'}">
                                <span slot="tip">View Profile</span>
                                <a @click="showProfile(merchant)" slot="reference">
                                    <i class="far fa-eye px-2"></i>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" v-if="permissionCheck(16384)" :options="{placement: 'top'}">
                                <span slot="tip">Operating Hour</span>
                                <a @click="showOperatingHour(merchant)" slot="reference">
                                    <i class="far fa-clock px-2"></i>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" v-if="permissionCheck(32768)" :options="{placement: 'top'}">
                                <span slot="tip">Product Category</span>
                                <a @click="$router.push({ name: 'merchant_category_products', params: {id: merchant.id} })" slot="reference">
                                    <i class="fas fa-utensils px-2"></i>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Delete Merchant</span>
                                <a @click="openConfirmationModal(merchant)" slot="reference">
                                    <i class="fas fa-trash px-2"></i>
                                </a>
                            </mdb-tooltip>
                        </div>
                        
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <ConfirmationModal :visible="confirmation_modal" :close="closeModalComponent" title="Confirm Delete Merchant?" :submit="deleteMerchant" />
            <ProductComponent :merchant_id="focus_merchant_id" :visible="product_component" :close="closeModalComponent" />
            <MerchantProfile :focus_merchant="focus_merchant" :visible="merchant_profile" :close="closeModalComponent" />
            <MerchantOperatingHour :merchant_id="focus_merchant_id" :visible="operating_hour_component" :close="closeModalComponent" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbIcon, mdbView, mdbMask, mdbTooltip,mdbRow,
    mdbCol, mdbBtn } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import scroll from '../js/infinityScroll';
import ProductComponent from '../components/ProductsComponent';
import MerchantOperatingHour from '../components/MerchantOperatingHour';
import MerchantProfile from '../components/MerchantProfile';
import {checkPermissions} from '../js/helper';
import ConfirmationModal from '../components/ConfirmationModal';

export default {
  name: 'merchant_list',
  components: {
    mdbContainer,
    AppNavigation,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
    mdbView,
    mdbMask,
    mdbTooltip,
    ProductComponent,
    mdbRow,
    mdbCol,
    mdbBtn,
    MerchantOperatingHour,
    MerchantProfile,
    ConfirmationModal
  },
  data: ()=>({
      merchants: [],
      product_component: false,
      operating_hour_component: false,
      merchant_profile: false,
      focus_merchant_id: 0,
      focus_merchant: null,
      search: "",
      filter_active: "",
      zones: [],
      zone_id: 0,
      confirmation_modal: false,
  }),
  methods: {
      getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/list_merchants",
            data: {
                id: this.merchants.length==0?null:this.merchants[this.merchants.length-1].id,
                search: this.search,
                filter_active: this.filter_active,
                zone_id: this.zone_id
            },
            complete: ()=>this.loading=false,
            success: result=>{
                let _temp=[...this.merchants];
                this.merchants=_temp.concat(result.data.merchants);
                this.zones=result.data.zones;
            },
        }, this)
      },
      getAvatar(avatar){
          if(!avatar){
              return require('@/assets/placeholder_empty.jpg');
          }
          return APP_FILE_URL+avatar;
      },
      closeModalComponent(){
          this.product_component=false;
          this.operating_hour_component=false;
          this.merchant_profile=false;
          this.focus_merchant_id=0;
          this.confirmation_modal=false;
      },
      toggleActive(merchant, index){
        let temp=[...this.merchants];
        temp[index].is_active=temp[index].is_active==0?1:0;
        this.merchants=temp;
        ajax({
            url: "admin/toggle_active",
            data: {
                id: merchant.id
            },
            complete: ()=>this.loading=false,
            success: ()=>{
                this.$notify.success({message: merchant.name+' has been updated.', position:'top right', timeOut: 5000});
            },
        },this)
    },
    startSearch(){
        sessionStorage.setItem('merchant_name', this.search);
        this.merchants=[];
        this.getData();
    },
    showOperatingHour(merchant){
        this.focus_merchant_id=merchant.id;
        this.operating_hour_component=true;
    },
    showProducts(merchant){
        this.focus_merchant_id=merchant.id;
        this.product_component=true;
    },
    showProfile(merchant){
        this.focus_merchant=merchant;
        this.merchant_profile=true;
    },
    permissionCheck(permission){
        return checkPermissions(permission, this.$store.state.permissions);
    },
    openConfirmationModal(merchant){
        this.focus_merchant=merchant;
        this.focus_merchant_id=merchant.id;
        this.confirmation_modal=true;
    },
    deleteMerchant(){
        ajax({
            url: "admin/delete_merchant",
            data: {
                merchant_id: this.focus_merchant_id
            },
            complete: ()=>this.loading=false,
            success: ()=>{
                this.merchants=[];
                this.getData();
                this.$notify.success({message: 'Merchant has been updated.', position:'top right', timeOut: 5000});
            },
        },this)
    }
  },
  mounted: function(){
      let temp=sessionStorage.getItem('merchant_name', '');
      if(temp){
        this.search=temp;
      }
      this.getData();
      scroll(()=>this.getData());
    }
}
</script>
