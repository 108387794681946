<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>{{id==0?'Add Permission':'Edit Permission'}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-input v-model="name" label="Name" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-input v-model="slug" label="Slug" size="sm" />
                </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-spinner v-if="loading" />
        <mdb-btn v-if="!loading" @click="submitPermissions" color="primary">{{this.id?"Edit":"Add"}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol,
  mdbInput, mdbSpinner } from 'mdbvue';
  import { ajax } from '../js/ajax';

  export default {
    components: {
      mdbModal, mdbSpinner,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter, mdbInput,
      mdbBtn,mdbContainer, mdbRow, mdbCol,
    },
    props: ['visible', 'close', "focus_permission"],
    data: ()=>({
        loading:false,
        id: 0,
        slug: "",
        name: "",
    }),
    methods: {
        closeModal(){
            this.slug="";
            this.name="";
            this.id=0;
            this.loading=false;
            this.close();
        },
        componentDidMount(){
            if(this.focus_user){
                this.slug=this.focus_user.slug;
                this.name=this.focus_user.name;
                this.id=this.focus_user.id;
            }
        },
        submitPermissions(){
          if(this.loading){
              return false;
          }
          this.loading=true;
          ajax({
              url: "admin/submit_permission",
              data: this.$data,
              complete: ()=>this.loading=false,
              success: ()=>{
                  this.$notify.success({message: this.id==0?"Admin Added":"Admin Updated", position:'top right', timeOut: 5000});
                  this.close();
              },
          }, this)
        }
    }
  };
</script>