<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Roles <mdb-btn @click="admin_modal=true" color="success" size="sm" class="my-0"><mdb-icon  icon="plus" /></mdb-btn></h1>
            <hr>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-tbl responsive>
                        <mdb-tbl-head>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Value</th>
                                <th>Action</th>
                            </tr>
                        </mdb-tbl-head>
                        <mdb-tbl-body>
                            <tr v-for="(role, index) in roles" :key="role.id">
                                <th scope="row">{{index+1}}</th>
                                <td>
                                    {{role.name}}
                                </td>
                                <td>
                                    {{role.slug}}
                                </td>
                                <td>
                                    <ol v-if="role.permissions">
                                        <li v-for="permission in role.permissions" :key="permission.id">{{permission.name}}</li>
                                    </ol>
                                </td>
                                <td>
                                    <mdb-btn @click="editRole(role)" color="primary" size="sm" class="my-0"><mdb-icon far icon="edit" /></mdb-btn>
                                    <mdb-btn @click="deleteRole(role)" color="danger" size="sm" class="my-0"><mdb-icon far icon="trash-alt" /></mdb-btn>
                                </td>
                            </tr>
                        </mdb-tbl-body>
                    </mdb-tbl>
                </mdb-col>
            </mdb-row>
            <AdminRoleModal :visible="admin_modal" :close="closeModal" :focus_role="focus_role" :permissions="permissions" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbIcon } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {momentFormat16, momentFormat, checkPermissionsBinary} from '../js/helper';
// import {APP_FILE_URL} from '../js/config';
import AdminRoleModal from '../components/AdminRoleModal';

export default {
  name: 'permissions',
  components: {
    mdbContainer, mdbBtn, 
    AppNavigation, mdbIcon, AdminRoleModal,
    mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody
  },
  data: ()=>({
      permissions: [],
      roles: [],
      loading:false,
      admin_modal: false,
      focus_role: null,
  }),
  methods: {
    closeModal(){
        this.focus_role=null;
        this.admin_modal=false;
        this.getData();
    },

    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/list_roles",
            complete: ()=>this.loading=false,
            success: result=>{
                this.roles=result.data.roles;
                this.permissions=result.data.permissions;
                this.roles.forEach(role => {
                    role.permissions=[];
                    this.permissions.forEach(permission=>{
                        if(checkPermissionsBinary(role.value, permission.id, permission.value)){
                            role.permissions.push(permission);
                        }
                    })
                });
            },
        }, this)
    },    
    momentFormat16(date){
        return momentFormat16(date);
    },
    momentFormat(date){
        return momentFormat(date);
    },
    editRole(role){
        this.focus_role=role;
        this.admin_modal=true;
    },
    deleteRole(role){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/delete_role",
            data: {id: role.id},
            complete: ()=>this.loading=false,
            success: ()=>{
                this.$notify.error({message: "Role Deleted", position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    },
  },
  mounted: function(){
    this.getData();
  }
}
</script>
