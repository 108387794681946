<template>
  <div>
    <mdb-modal size="lg" :show="visible" @close="onClose">
      <mdb-modal-header>
        <mdb-modal-title>Add User to Promo Code</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-container>
            <mdb-row class="mb-5">
                <mdb-col col="12">
                    <mdb-input v-model="search" @keydown.enter.native="searchUser()" label="Search User Name / Phone Number" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col col="6">
                    <h4 class="lapar-title">Search Results</h4>
                    <mdb-row class="mb-3 user" v-for="user in search_results" v-bind:key="user.id">
                        <mdb-col col="9">
                            <div class="d-flex flex-column">
                                <div style="font-size:0.85rem; font-weight: bold">{{user.name}}</div>
                                <div style="font-size:0.85rem;">{{user.email}}</div>
                                <div style="font-size:0.85rem;">{{user.phone_number}}</div>
                            </div>
                        </mdb-col>     
                        <mdb-col col="3">
                            <mdb-btn @click="addUser(user)" color="primary" size="sm"><mdb-icon far icon="plus-square" /></mdb-btn>
                        </mdb-col>                     
                    </mdb-row>
                </mdb-col>
                <mdb-col col="6">
                    <h4 class="lapar-title">Selected Users</h4>
                    <mdb-row class="mb-3 user" v-for="user in selected_users" v-bind:key="user.id">
                        <mdb-col col="9">
                            <div class="d-flex flex-column">
                                <div style="font-size:0.85rem; font-weight: bold">{{user.name}}</div>
                                <div style="font-size:0.85rem;">{{user.email}}</div>
                                <div style="font-size:0.85rem;">{{user.phone_number}}</div>
                                <mdb-input class="mt-3 mb-0" type="number" v-model="user.count" label="Number of Voucher" size="sm" />
                            </div>
                        </mdb-col>     
                        <mdb-col col="3">
                            <mdb-btn @click="deleteUser(user)" color="danger" size="sm"><mdb-icon icon="trash" /></mdb-btn>
                        </mdb-col>                     
                    </mdb-row>
                </mdb-col>
            </mdb-row>
            
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-btn v-if="!loading && selected_users.length!=0" @click="submitUsers" color="primary">Confirm</mdb-btn>
          <mdb-btn v-if="!loading" @click="onClose" color="danger">Close</mdb-btn>
          <mdb-spinner v-if="loading" small />
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
    import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn, mdbModalBody, mdbContainer, mdbRow, 
    mdbCol, mdbIcon, mdbInput, mdbSpinner} from 'mdbvue';
    import {ajax} from '../js/ajax';
    import {momentFormatDate} from '../js/helper';

    export default {
        components: {
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalFooter,
        mdbBtn, mdbInput, mdbContainer, mdbRow, mdbCol,
        mdbModalBody, mdbIcon, mdbSpinner
        },
        props: ['visible', 'close', 'submit', 'promo_code_id'],
        data: ()=>({
            search: "",
            search_results: [],
            selected_users: [],
            loading:false
        }),
        methods: {
            onClose(){
                this.search="";
                this.search_results=[];
                this.selected_users=[];
                this.loading=false;
                this.close();
            },
            searchUser(){
                this.search_loading=true;
                ajax({
                    url: "admin/search_user",
                    data: {search: this.search},
                    complete: ()=>this.search_loading=false,
                    success: result=>{
                        this.search_results=result.data;
                    },
                }, this)
            },
            momentFormat(date){
                return momentFormatDate(date);
            },
            addUser(user){
                let selected_users=[...this.selected_users];
                let found_index = selected_users.findIndex(element=>{
                    return element.id==user.id;
                })
                if(found_index==-1){
                    selected_users.push({...user, count: 1});
                }
                this.selected_users=selected_users;
            },
            deleteUser(user){
                let selected_users=[...this.selected_users];
                let found_index = selected_users.findIndex(element=>{
                    return element.id==user.id;
                })
                if(found_index!=-1){
                    selected_users.splice(found_index,1);
                }
                this.selected_users=selected_users;
            },
            submitUsers(){
                this.loading=true;
                ajax({
                    url: "admin/add_promo_code_user",
                    data: {users: this.selected_users, promo_code_id: this.promo_code_id},
                    complete: ()=>this.loading=false,
                    success: ()=>{
                        this.$notify.success({message: "Promo Code Binded to Users", position:'top right', timeOut: 5000});
                        this.onClose();
                    },
                }, this)
            }
        },
    };
</script>

<style scoped>
.lapar-title{
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffbb33;
}
.user{
    border-bottom: 1px solid #eeeeee;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
</style>