<template>
    <AppNavigation>
        <mdb-container fluid>
            <textarea id="copy-text"/>
            <h1 class="h1-responsive">Orders <mdb-btn color="primary" size="sm" @click="search"><i class="fas fa-sync"></i></mdb-btn></h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <input v-on:keyup.enter="search" v-model="order_number" type="text" placeholder="#Order Number" class="form-control">
                    </div>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="status" class="browser-default custom-select">
                        <option value="" selected>Status</option>
                        <option value="NEW">New</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="CANCELLED">Cancelled</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="payment_type" class="browser-default custom-select">
                        <option value="" selected>All Payment Method</option>
                        <option value="CASH">Cash</option>
                        <option value="ONLINE_BANKING">Online Banking</option>
                        <option value="EWALLET">E-wallet</option>
                        <option value="CREDIT_DEBIT_CARD">Card</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="refund" class="browser-default custom-select">
                        <option value="0" selected>All Orders</option>
                        <option value="1">Refund Orders only</option>
                    </select>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col md="3">
                    <select v-model="merchant_select" class="browser-default custom-select">
                        <option value="">All Merchants</option>
                        <option :value="merchant.id" v-bind:key="merchant.id" v-for="merchant in merchants">{{merchant.name}}</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <select v-model="service_id" class="browser-default custom-select">
                        <option value="0">All Services</option>
                        <option value="1">Food Delivery</option>
                        <option value="2">Virtual Merchant Delivery</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <input v-on:keyup.enter="search" v-model="phone_number" type="text" placeholder="#Phone Number" class="form-control">
                </mdb-col>
            </mdb-row>
            <p class="text-danger" v-if="loading">Please Wait... Fetching Data dont panic don't spam refresh!</p>
            <mdb-tbl responsive hover>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order No.</th>
                    <th width="15%">Merchant</th>
                    <th width="15%">Customer</th>
                    <th width="19%">Rider</th>
                    <th width="10%">Status</th>
                    <th width="13%" class="text-right">Bill Charge</th>
                    <th width="13%" class="text-right">Date Time</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(order, index) in orders" :key="order.id"  @click="showOrderDetail(order.id)" style="cursor: pointer">
                    <th scope="row">{{index+1}}</th>
                    <td @click.prevent.stop>
                        <div v-if="order.service_id==1" class="small">Food Delivery</div>
                        <div v-if="order.service_id==2" class="small">Virtual Merchant</div>
                        <div class="order-number-text" @click="copyClipboardOrderNumber(order.order_number)">{{order.order_number}}</div>
                        <div>
                            <mdb-tooltip v-if="order.comment_admin" trigger="hover" :options="{placement: 'top'}">
                            <span slot="tip">Admin Remarks</span>
                            <a slot="reference">
                               <mdb-icon  style="color:#f0bb1b" far icon="sticky-note" />
                            </a>
                            </mdb-tooltip>
                        </div>
                    </td>
                    <td>
                        <div v-if="order.service_id==1">{{order.merchant.name}}</div>
                        <div v-if="order.service_id==1">{{order.merchant.contact_number}}</div>
                        <div v-if="order.service_id==2">{{order.virtual_merchant.name_en}}</div>
                    </td>
                    <td>
                        {{order.customer_user.name}}<br />
                        {{order.customer_user.phone_number}}
                    </td>
                    <td>
                        <div v-if="order.job && order.job.partner_user_id!=null && order.partner_user_id==null" class="text-warning">{{order.job.partner_user.partner_profile.fullname}}</div>
                        <div v-if="order.partner_user_id" class="text-success">{{order.partner_user.name}}</div>
                        <select  v-on:click.stop v-if="checkAllowToSwitchPartner(order)" class="browser-default custom-select" @change="assignRider(order)" v-model="order.job.partner_user_id">
                            <option v-for="partner in partners" v-bind:key="partner.id" :value="partner.id">{{partner.name}}</option>
                        </select>
                    </td>
                    <td>
                        <div v-if="order.status=='CANCELLED'" class="status-cancelled">{{order.status}}</div>
                        <div v-if="order.status=='NEW'" class="status-new">{{order.status}}</div>
                        <div v-if="order.status=='COMPLETED'" class="status-completed">{{order.status}}</div>
                        <div class="text-center">{{order.current_step}}</div>
                        <div class="text-center">{{order.cancel_reason}}</div>
                        <div class="text-center">{{momentFormat(order.time_cancel_job)}}</div>
                    </td>
                    <td class="d-flex flex-column">
                        <div style="text-decoration: line-through;" v-if="order.service_charge.subtotal != order.service_charge.grand_total">RM {{order.service_charge.subtotal}}</div>
                        <div class="d-flex flex-row">
                            <mdb-tooltip v-if="order.service_charge.payment_type=='ONLINE_BANKING'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Online Banking</span>
                                <a slot="reference">
                                <img :src="require('@/assets/online_banking.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="order.service_charge.payment_type=='CASH'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Cash</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/cash.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="order.service_charge.payment_type=='CREDIT_DEBIT_CARD'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Card</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/card.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="order.service_charge.payment_type=='EWALLET'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">E-Wallet</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/e_wallet.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="order.service_charge.payment_type=='TNG'" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Touch and Go eWallet</span>
                                <a slot="reference">
                                    <img :src="require('@/assets/tng.png')" style="height: 20px; width: 20px" />
                                </a>
                            </mdb-tooltip>
                            <div class="ml-2">RM {{order.service_charge.grand_total}}</div>
                        </div>
                    </td>
                    <td class="text-right">
                        <div>{{momentFormat16(order.created_at)}}</div>
                        <div class="text-success">{{momentFormat(order.time_complete)}}</div>
                        <div class="text-danger">{{momentFormat(order.time_cancel_job)}}</div>
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <OrderDetailComponent :promo_codes="promo_codes" :id="focus_order_id" :visible="order_detail_component" :close="closeOrderDetailComponent" :callback="callback" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol, 
mdbTooltip, mdbBtn, mdbIcon 
  } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import scroll from '../js/infinityScroll';
import moment from 'moment';
import OrderDetailComponent from '../components/OrderDetailComponent';
import {checkPermissions} from '../js/helper';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'orders',
  components: {
    mdbContainer,
    AppNavigation, mdbTooltip,
    mdbTbl, mdbTblHead, mdbTblBody,
    mdbRow, mdbCol, mdbBtn,
    OrderDetailComponent, mdbIcon
  },
  data: ()=>({
      orders:[],
      order_number: "",
      order_detail_component: false,
      focus_order_id: 0,
      loading: false,
      status: "",
      payment_type: "",
      refund: 0,
      partners: [],
      merchant_select: "",
      users: [],
      user_list: [],
      customer_user_id: "",
      merchants: [],
      timer: null,
      promo_codes: [],
      service_id: 0,
      phone_number: ""
  }),
  methods: {
    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/get_orders",
            data: {
                id: this.orders.length==0?null:this.orders[this.orders.length-1].id,
                order_number: this.order_number,
                status: this.status,
                payment_type: this.payment_type,
                refund:this.refund,
                merchant_select: this.merchant_select,
                phone_number: this.phone_number,
                service_id: this.service_id
            },
            complete: ()=>this.loading=false,
            success: result=>{
                let _temp=[...this.orders];
                this.orders=_temp.concat(result.data);
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    getRiderData(){
        ajax({
            url: "admin/get_partner_list",
            success: result=>{
                this.partners=result.data;
                this.partners.sort((a, b)=>{
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    search(){
        this.orders=[];
        this.getData();
    },
    momentFormat16(date){
        if(date){
            return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY h:mm a');
        }
        return "";
    },
    momentFormat(date){
        if(date){
            return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY h:mm a');
        }
        return "";
    },
    showOrderDetail(id){
        this.focus_order_id=id;
        this.order_detail_component=true;
    },
    closeOrderDetailComponent(){
        this.order_detail_component=false;
        this.focus_order_id=0;
    },
    assignRider(order){
        ajax({
            url: "admin/assign_job_rider",
            data: {service_order_id: order.id, partner_user_id: order.job.partner_user_id},
            success: ()=>{
                this.$notify.success({message: "Partner Assigned", position: 'top right', timeOut: 5000});
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    permissionCheck(permission){
        return checkPermissions(permission, this.$store.state.permissions);
    },
    checkAllowToSwitchPartner(order){
        if(order.current_step=="PENDING_PAYMENT" || order.current_step=="NEW"){
            return false;
        }
        if(order.job && order.status=='NEW' && this.permissionCheck(512)){
            return true;
        }
        return false;
    },
    callback(){
        this.search();
    },
    requestSearch(value){
        clearTimeout(this.timer);
        this.users=[];
        this.user_list=[];
        if(value==""){
            return false;
        }
        this.timer=setTimeout(()=>{
            ajax({
                url: "admin/list_filter_users",
                data: {search: value},
                success: result=>{
                    result.data.forEach(user => {
                        this.user_list.push(user.name)
                    });
                    this.users=result.data;
                },
                fail: ()=>{
                    this.users=[];
                    this.user_list=[];
                },
            })
        }, 800);
    },
    filterName(item, search){
        if(item.toLowerCase().indexOf(search.toLowerCase()) > -1){
            console.log('test');
        }
    },
    copyClipboardOrderNumber(order_number){
        let textArea = document.querySelector('#copy-text')
        textArea.value=order_number;
        textArea.select();
        document.execCommand('copy');
        this.$notify.success({message: "Order Number Copied", position: 'top right', timeOut: 5000});
    }
  },
  mounted: function(){
        this.getData();
        this.getRiderData();
        scroll(()=>this.getData());
        ajax({
            url: "admin/get_compensate_voucher",
            success: result=>{
                this.promo_codes=result.data;
            }
        })
        ajax({
            url: "admin/list_all_merchants",
            success: result=>{
                this.merchants=result.data;
            },
        })
  },
  watch: {
      status(){
        this.search();
      },
      payment_type(){
        this.search();
      },
      merchant_select(){
          this.search();
      },
      refund(){
        if(this.refund==0){
            this.status="";
            this.search();
        }else{
            this.status="CANCELLED";
            this.search();
        }
      },
      service_id(){
          this.search();
      }
  }
}
</script>

<style scoped>
.status-cancelled{
    border: 1.5px solid #ff4444;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4444;
}
.status-completed{
    border: 1.5px solid #00C851;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00C851;
}
.status-new{
    border: 1.5px solid #4285F4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4285F4;
}
#copy-text{
    position: absolute;
    top: -9999999999px;
    left: -9999999999px;
}
.order-number-text:hover{
    color: #4285F4;
    font-weight: bold;
}
</style>