<template>
  <div>
    <mdb-modal size="lg" @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>Merchants</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row class="mb-3 merchant-row">
              <mdb-col col="4" v-for="(merchant) in all_merchants" :key="merchant.id">
                <mdb-container>
                  <mdb-row>
                    <mdb-col col="12">
                      <mdb-view :src="getAvatar(merchant.avatar)" alt="merchant image">
                        <a>
                          <mdb-mask overlay="white-slight"></mdb-mask>
                        </a>
                      </mdb-view>
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="12">
                      <div style="font-size: 0.8rem">{{merchant.name}}</div>
                      <div>
                        <mdb-btn size="sm" @click="addMerchant(merchant)" v-if="!checkAdded(merchant)" color="primary"><i class="fas fa-plus"></i></mdb-btn>
                        <mdb-btn size="sm" @click="deleteMerchant(merchant)" v-if="checkAdded(merchant)" color="danger"><mdb-icon far icon="trash-alt" /></mdb-btn>
                      </div>
                    </mdb-col>
                  </mdb-row>
                </mdb-container>
              </mdb-col>
                
            </mdb-row>
            <mdb-row>
              <mdb-col col="12">
                <mdb-spinner v-if="loading" />
              </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbBtn, mdbContainer, mdbRow, mdbCol, mdbSpinner, mdbView, mdbMask, mdbIcon } from 'mdbvue';
  import { APP_FILE_URL } from '../js/config';
  import { ajax } from '../js/ajax';

  export default {
    components: {
      mdbModal, mdbSpinner,
      mdbModalHeader, mdbView, mdbMask,
      mdbModalTitle,
      mdbModalBody, mdbIcon,
      mdbBtn,mdbContainer, mdbRow, mdbCol,
    },
    props: ['visible', 'close', 'type', 'id', "all_merchants"],
    data: ()=>({
        loading:false,
        search: "",
        timer:null,
        selected_merchants: [],
        add: false,
    }),
    mounted: function(){
    },
    methods: {
      closeModal(){
        this.add=false;
        this.loading=false;
        this.selected_merchants=[];
        this.search="";
        this.close();
      },
      componentDidMount(){
        this.getData();
      },
      getData(){
          if(this.loading){
              return false;
          }
          this.loading=true;
          ajax({
            url: "admin/list_app_home_merchant",
            data: {id: this.id, type: this.type},
            complete: ()=>this.loading=false,
            success: result=>{
              this.selected_merchants=result.data.all_merchants;
            },
          }, this)
      },
      getAvatar(avatar){
        if(!avatar){
          return require('@/assets/placeholder.jpg');
        }
        return APP_FILE_URL+avatar;
      },
      checkAdded(merchant){
        let result=false;
        this.selected_merchants.forEach(element => {
          if(element.id==merchant.id){
            result=true;
          }
        });
        return result;
      },
      addMerchant(merchant){
        if(this.loading){
          return false;
        }
        this.loading=true;
        ajax({
          url: "admin/add_merchant_into_group",
          data: {type: this.type, merchant_id: merchant.id, id: this.id},
          complete: ()=>this.loading=false,
          success: ()=>{
            this.$notify.success({message: this.type=="PROMOTION_BANNER"?"Promotion Banner Merchants Updated":"App Home Merchants Updated", position:'top right', timeOut: 5000});
            this.getData();
          },
        }, this)
      },
      deleteMerchant(merchant){
        if(this.loading){
          return false;
        }
        this.loading=true;
        ajax({
          url: "admin/delete_merchant_into_group",
          data: {type: this.type, merchant_id: merchant.id, id: this.id},
          complete: ()=>this.loading=false,
          success: ()=>{
            this.$notify.success({message: this.type=="PROMOTION_BANNER"?"Promotion Banner Merchants Updated":"App Home Merchants Updated", position:'top right', timeOut: 5000});
            this.getData();
          },
        }, this)
      }
    }
  };
</script>

<style scoped>
.promotion-img{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100%;
    background-color: black;
}

.merchant-row{
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
</style>