<template>
  <div>
    <mdb-modal :show="visible" @close="onClose">
      <mdb-modal-header>
        <mdb-modal-title>{{title?title:'Are you sure?'}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-footer>
          <mdb-btn @click="onSubmit" color="primary">Confirm</mdb-btn>
          <mdb-btn @click="onClose" color="danger">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn} from 'mdbvue';

  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalFooter,
      mdbBtn
    },
    props: ['visible', 'close', 'title', 'submit'],
    methods: {
        onSubmit(){
            this.submit();
            this.close();
        },
        onClose(){
            this.close();
        }
    },
  };
</script>

<style scoped>
</style>