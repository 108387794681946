export const COLOR = {
    'DANGER_COLOR' : "#ff4444",
    'DANGER_COLOR_DARK' : "#cc0000",
    'WARNING_COLOR' : "#ffbb33",
    'WARNING_COLOR_DARK' : "#ff8800",
    'SUCCESS_COLOR' : "#00C851",
    'SUCCESS_COLOR_DARK' : "#007E33",
    'INFO_COLOR' : "#33B5E5",
    'INFO_COLOR_DARK' : "#0099CC",
    'PRIMARY_COLOR' : "#4285F4",
    'PRIMARY_COLOR_DARK' : "#0D47A1",
    'SECONDARY_COLOR' : "#AA66CC",
    'SECONDARY_COLOR_DARK' : "#9933CC",
    'ELEGANT_COLOR' : "#2E2E2E",
    'ELEGANT_COLOR_DARK' : "#212121",
    'APP_1' : "#673ab7",
    'APP_2' : "#9575cd",
    'APP_3' : "#5c6bc0",
    'APP_4' : "#FA15A6",

    //red
    'RED' : "#F44336",
    'RED_LIGHTEN_1' : "#EF5350",
    'RED_LIGHTEN_2' : "#E57373",
    'RED_LIGHTEN_3' : "#EF9A9A",
    'RED_LIGHTEN_4' : "#FFCDD2",
    'RED_LIGHTEN_5' : "#FFEBEE",
    'RED_DARKEN_1' : "#E53935",
    'RED_DARKEN_2' : "#D32F2F",
    'RED_DARKEN_3' : "#C62828",
    'RED_DARKEN_4' : "#B71C1C",
    //pink
    'PINK' : "#E91E63",
    'PINK_LIGHTEN_1' : "#EC407A",
    'PINK_LIGHTEN_2' : "#F06292",
    'PINK_LIGHTEN_3' : "#F48FB1",
    'PINK_LIGHTEN_4' : "#F8BBD0",
    'PINK_LIGHTEN_5' : "#FCE4EC",
    'PINK_DARKEN_1' : "#D81B60",
    'PINK_DARKEN_2' : "#C2185B",
    'PINK_DARKEN_3' : "#AD1457",
    'PINK_DARKEN_4' : "#880E4F",
    //purple
    'PURPLE' : "#9c27b0",
    'PURPLE_LIGHTEN_1': "#ab47bc",
    'PURPLE_LIGHTEN_2': "#ba68c8",
    'PURPLE_LIGHTEN_3': "#ce93d8",
    'PURPLE_LIGHTEN_4': "#e1bee7",
    'PURPLE_LIGHTEN_5': "#f3e5f5",
    'PURPLE_DARKEN_1': "#8e24aa",
    'PURPLE_DARKEN_2': "#7b1fa2",
    'PURPLE_DARKEN_3': "#6a1b9a",
    'PURPLE_DARKEN_4': "#4a148c",
    //deep purple
    "DEEP_PURPLE" : "#673ab7",
    "DEEP_PURPLE_LIGHTEN_1" : "#7e57c2",
    "DEEP_PURPLE_LIGHTEN_2" : "#9575cd",
    "DEEP_PURPLE_LIGHTEN_3" : "#b39ddb",
    "DEEP_PURPLE_LIGHTEN_4" : "#d1c4e9",
    "DEEP_PURPLE_LIGHTEN_5" : "#ede7f6",
    "DEEP_PURPLE_DARKEN_1" : "#5e35b1",
    "DEEP_PURPLE_DARKEN_2" : "#512da8",
    "DEEP_PURPLE_DARKEN_3" : "#4527a0",
    "DEEP_PURPLE_DARKEN_4" : "#311b92",
    //indigo
    "INDIGO" : "#3f51b5",
    "INDIGO_LIGHTEN_1" : "#5c6bc0",
    "INDIGO_LIGHTEN_2" : "#7986cb",
    "INDIGO_LIGHTEN_3" : "#9fa8da",
    "INDIGO_LIGHTEN_4" : "#c5cae9",
    "INDIGO_LIGHTEN_5" : "#e8eaf6",
    "INDIGO_DARKEN_1" : "#3949ab",
    "INDIGO_DARKEN_2" : "#303f9f",
    "INDIGO_DARKEN_3" : "#283593",
    "INDIGO_DARKEN_4" : "#1a237e",
    //blue
    "BLUE" : "#2196f3",
    "BLUE_LIGHTEN_1" : "#42a5f5",
    "BLUE_LIGHTEN_2" : "#64b5f6",
    "BLUE_LIGHTEN_3" : "#90caf9",
    "BLUE_LIGHTEN_4" : "#bbdefb",
    "BLUE_LIGHTEN_5" : "#e3f2fd",
    "BLUE_DARKEN_1" : "#1e88e5",
    "BLUE_DARKEN_2" : "#1976d2",
    "BLUE_DARKEN_3" : "#1565c0",
    "BLUE_DARKEN_4" : "#0d47a1",
    //light blue
    "LIGHT_BLUE" : "#03a9f4",
    "LIGHT_BLUE_LIGHTEN_1" : "#29b6f6",
    "LIGHT_BLUE_LIGHTEN_2" : "#4fc3f7",
    "LIGHT_BLUE_LIGHTEN_3" : "#81d4fa",
    "LIGHT_BLUE_LIGHTEN_4" : "#b3e5fc",
    "LIGHT_BLUE_LIGHTEN_5" : "#e1f5fe",
    "LIGHT_BLUE_DARKEN_1" : "#039be5",
    "LIGHT_BLUE_DARKEN_2" : "#0288d1",
    "LIGHT_BLUE_DARKEN_3" : "#0277bd",
    "LIGHT_BLUE_DARKEN_4" : "#01579b",
    //green
    "GREEN" : "#4caf50",
    "GREEN_LIGHTEN_1" : "#66bb6a",
    "GREEN_LIGHTEN_2" : "#81c784",
    "GREEN_LIGHTEN_3" : "#a5d6a7",
    "GREEN_LIGHTEN_4" : "#c8e6c9",
    "GREEN_LIGHTEN_5" : "#e8f5e9",
    "GREEN_DARKEN_1" : "#43a047",
    "GREEN_DARKEN_2" : "#388e3c",
    "GREEN_DARKEN_3" : "#2e7d32",
    "GREEN_DARKEN_4" : "#1b5e20",
    //light green
    "LIGHT_GREEN" : "#8bc34a",
    "LIGHT_GREEN_LIGHTEN_1" : "#9ccc65",
    "LIGHT_GREEN_LIGHTEN_2" : "#aed581",
    "LIGHT_GREEN_LIGHTEN_3" : "#c5e1a5",
    "LIGHT_GREEN_LIGHTEN_4" : "#dcedc8",
    "LIGHT_GREEN_LIGHTEN_5" : "#f1f8e9",
    "LIGHT_GREEN_DARKEN_1" : "#7cb342",
    "LIGHT_GREEN_DARKEN_2" : "#689f38",
    "LIGHT_GREEN_DARKEN_3" : "#558b2f",
    "LIGHT_GREEN_DARKEN_4" : "#33691e",
    //yellow
    "YELLOW" : "#ffeb3b",
    "YELLOW_LIGHTEN_1" : "#ffee58",
    "YELLOW_LIGHTEN_2" : "#fff176",
    "YELLOW_LIGHTEN_3" : "#fff59d",
    "YELLOW_LIGHTEN_4" : "#fff9c4",
    "YELLOW_LIGHTEN_5" : "#fffde7",
    "YELLOW_DARKEN_1" : "#fdd835",
    "YELLOW_DARKEN_2" : "#fbc02d",
    "YELLOW_DARKEN_3" : "#f9a825",
    "YELLOW_DARKEN_4" : "#f57f17",
    //amber
    "AMBER" : "#ffc107",
    "AMBER_LIGHTEN_1" : "#ffca28",
    "AMBER_LIGHTEN_2" : "#ffd54f",
    "AMBER_LIGHTEN_3" : "#ffe082",
    "AMBER_LIGHTEN_4" : "#ffecb3",
    "AMBER_LIGHTEN_5" : "#fff8e1",
    "AMBER_DARKEN_1" : "#ffb300",
    "AMBER_DARKEN_2" : "#ffa000",
    "AMBER_DARKEN_3" : "#ff8f00",
    "AMBER_DARKEN_4" : "#ff6f00",
    //orange
    "ORANGE" : "#ff9800",
    "ORANGE_LIGHTEN_1" : "#ffa726",
    "ORANGE_LIGHTEN_2" : "#ffb74d",
    "ORANGE_LIGHTEN_3" : "#ffcc80",
    "ORANGE_LIGHTEN_4" : "#ffe0b2",
    "ORANGE_LIGHTEN_5" : "#fff3e0",
    "ORANGE_DARKEN_1" : "#fb8c00",
    "ORANGE_DARKEN_2" : "#f57c00",
    "ORANGE_DARKEN_3" : "#ef6c00",
    "ORANGE_DARKEN_4" : "#e65100",
    //deep orange
    "DEEP_ORANGE" : "#ff5722",
    "DEEP_ORANGE_LIGHTEN_1" : "#ff7043",
    "DEEP_ORANGE_LIGHTEN_2" : "#ff8a65",
    "DEEP_ORANGE_LIGHTEN_3" : "#ffab91",
    "DEEP_ORANGE_LIGHTEN_4" : "#ffccbc",
    "DEEP_ORANGE_LIGHTEN_5" : "#fbe9e7",
    "DEEP_ORANGE_DARKEN_1" : "#f4511e",
    "DEEP_ORANGE_DARKEN_2" : "#e64a19",
    "DEEP_ORANGE_DARKEN_3" : "#d84315",
    "DEEP_ORANGE_DARKEN_4" : "#bf360c",
    //grey
    "GREY" : "#9e9e9e",
    "GREY_LIGHTEN_1" : "#bdbdbd",
    "GREY_LIGHTEN_2" : "#e0e0e0",
    "GREY_LIGHTEN_3" : "#eeeeee",
    "GREY_LIGHTEN_4" : "#f5f5f5",
    "GREY_LIGHTEN_5" : "#fafafa",
    "GREY_DARKEN_1" : "#757575",
    "GREY_DARKEN_2" : "#616161",
    "GREY_DARKEN_3" : "#424242",
    "GREY_DARKEN_4" : "#212121",
}

export function getColor(colorCode){
    if(colorCode === "black"){
        return "#000";
    }
    if(colorCode === "white"){
        return "#fff";
    }
    return COLOR[colorCode];
}
    
