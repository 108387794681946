<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Partner List</h1>
            <hr>
            <mdb-tbl responsive striped>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th width="15%"></th>
                    <th width="40%">Info</th>
                    <th width="25%">Vehicle</th>
                    <th width="15%">Action</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(partner, index) in partners" :key="partner.id">
                    <th scope="row">{{index+1}}</th>
                    <td class="d-flex flex-center">
                        <div class="avatar-image" :style="{backgroundImage:`url('`+getAvatar(partner.partner_profile.avatar)+`')`}" />
                    </td>
                    <td>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Code Name</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.name}}</span>
                        </div>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Full Name</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.partner_profile.fullname}}</span>
                        </div>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Phone Number</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.partner_profile.phone_number}}</span>
                        </div>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Full Address</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.partner_profile.full_address}}</span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Rider Type</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.partner_profile.type}}</span>
                        </div>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Vehicle Number</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.partner_profile.vehicle_registration_number}}</span>
                        </div>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e" >Vehicle Type</div>
                            <div class="d-flex flex-row align-items-center" style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">{{partner.partner_profile.car_type}} <div style="height: 15px; width: 15px; border-radius: 5px; border: 0.5px solid #2E2E2E" class="ml-1" :style="{'background-color': partner.partner_profile.car_color}"></div></div>
                        </div>
                        <div class="d-flex flex-column mb-2">
                            <div style="font-size: 0.75rem; color: #9e9e9e">Payout per Order</div>
                            <span style="font-size: 1rem; color: #2E2E2E; border-bottom: 1px solid #9e9e9e">RM {{(parseFloat(partner.partner_profile.payout_percentage) * parseFloat(service_config.delivery_fare)).toFixed(2)}}</span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-row justify-content-around align-items-center">
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">View More</span>
                                <a @click="showPartnerInfo(partner)" slot="reference">
                                    <i class="far fa-eye px-2"></i>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">View Activity</span>
                                <a @click="showActivity(partner.id)" slot="reference">
                                    <i class="far fa-calendar-check px-2"></i>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Delete  Rider</span>
                                <a @click="showConfirmation(partner)" slot="reference">
                                    <i class="fas fa-trash px-2"></i>
                                </a>
                            </mdb-tooltip>
                        </div>
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
        </mdb-container>
        <PartnerActivityModal :visible="partner_activity_modal" :activities="activities" :close="closeModal" />
        <PartnerProfileModal :visible="partner_profile_modal" :partner="focus_partner" :close="closeModal" />
        <ConfirmationModal :visible="confirmation_modal" :close="closeModal" title="Confirm Delete Merchant?" :submit="deletePartner" />
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbTooltip  } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import PartnerActivityModal from '../components/PartnerActivityModal';
import PartnerProfileModal from '../components/PartnerProfileModal';
import ConfirmationModal from '../components/ConfirmationModal';

export default {
  name: 'partner_online',
  components: {
    mdbContainer,
    AppNavigation, PartnerProfileModal,
    mdbTbl, mdbTblHead, mdbTblBody,
    mdbTooltip, PartnerActivityModal, ConfirmationModal
  },
  data: ()=>({
      partners:[],
      service_config: null,
      partner_activity_modal: false,
      activities: [],
      partner_profile_modal: false,
      focus_partner: null,
      confirmation_modal: false
  }),
  methods: {
        getData(){
            this.loading=true;
            ajax({
                url: "admin/list_app_config",
                success: result=>{
                    this.service_config=result.data.services[0];
                    ajax({
                        url: "admin/get_partner_list",
                        complete: ()=>this.loading=false,
                        success: result=>{
                            this.partners=result.data;
                        },
                        fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
                    })
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        getAvatar(avatar){
          return APP_FILE_URL+avatar;
        },
        showActivity(user_id){
            ajax({
                url: "admin/get_partner_events",
                data: {user_id:user_id},
                complete: ()=>this.loading=false,
                success: result=>{
                    this.activities=result.data;
                    this.partner_activity_modal=true;
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        closeModal(){
            this.activities=[];
            this.partner_activity_modal=false;
            this.partner_profile_modal=false;
            this.focus_partner=null;
            this.confirmation_modal=false;
        },
        showPartnerInfo(partner){
            this.partner_profile_modal=true;
            this.focus_partner=partner;
        },
        deletePartner(){
            ajax({
                url: "admin/delete_partner",
                data: {
                    partner_profile_id: this.focus_partner.partner_profile.id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.getData();
                    this.$notify.success({message: 'Rider has been deleted.', position:'top right', timeOut: 5000});
                },
            },this)
        },
        showConfirmation(partner){
            this.focus_partner=partner;
            this.confirmation_modal=true;
        }
  },
  mounted: function(){
      this.getData();
  }
}
</script>
<style scoped>
.avatar-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 120px;
    width: 120px;
    border-radius: 120px;
}
</style>