<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Active Orders</h1>
            <hr>
            
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'active_orders',
  components: {
    mdbContainer,
    AppNavigation,
  },
  data: ()=>({
      orders:[]
  }),
  methods: {
    getData(){
        this.loading=true;
        ajax({
            url: "admin/get_orders",
            complete: ()=>this.loading=false,
            success: result=>{
                this.orders=result.data;
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
  },
  mounted: function(){
      this.getData();
  }
}
</script>
