<template>
  <div>
    <mdb-modal success :show="visible" @close="onClose">
      <mdb-modal-header>
        <mdb-modal-title class="text-white">{{title?title:'Success'}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-container>
            <mdb-row>
                <mdb-col col="12" offsetMd="4" md="4" class="d-flex flex-center mb-4">
                    <img :src="require('@/assets/success.gif')" class="img-fluid" />
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col col="12" class="d-flex flex-center text-success">
                    {{text?text:""}}
                </mdb-col>
            </mdb-row>
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-btn @click="onSubmit" color="success">OK</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn, mdbModalBody, mdbContainer, mdbRow, mdbCol} from 'mdbvue';

  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalFooter,
      mdbBtn,
      mdbModalBody, mdbContainer, mdbRow, mdbCol
    },
    props: ['visible', 'close', 'title', 'submit', 'text'],
    methods: {
        onSubmit(){
          this.submit?this.submit():null;
          this.close();
        },
        onClose(){
          this.close();
        }
    },
  };
</script>

<style scoped>
</style>