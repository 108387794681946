<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>{{id==0?"New Category":"Edit Category"}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-input label="Category Name" v-model="name" size="sm" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="loading" small />
        <mdb-btn  v-if="!loading" @click="submitCategory" color="success">Submit</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbInput, mdbSpinner } from 'mdbvue';
  import {ajax} from '../js/ajax';
  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn, mdbInput, mdbSpinner
    },
    props: ['visible', 'close', 'focus_category', 'merchant_id'],
    data: ()=>({
        id: 0,
        name:"",
        loading:true,
    }),
    methods:{
        hideModal(){
            this.id=0;
            this.name='';
            this.loading=true
            this.close();
        },
        componentDidMount(){
            if(this.focus_category){
                this.id=this.focus_category.id;
                this.name=this.focus_category.name;
            }
            this.loading=false;
        },
        submitCategory(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/submit_category",
                data: {id: this.id, merchant_id: this.merchant_id, name: this.name},
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Category Submitted', position:'top right', timeOut: 5000});
                    this.hideModal();
                },
            }, this)
        }
    }
  };
</script>