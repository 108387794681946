<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Merchant Payout</h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <mdb-date-picker :autoHide="true" @close="getData()" v-model="date_from" label="Date From" />
                    </div>
                </mdb-col>
                <mdb-col md="3">
                    <div class="form-group">
                        <mdb-date-picker :autoHide="true" @close="getData()" v-model="date_to" label="Date To" />
                    </div>
                </mdb-col>
                <mdb-col md="3">
                    <mdb-spinner small v-if="loading" />
                    <!-- <mdb-btn v-if="!loading" @click="downloadExcel" outline="primary"><i class="fas fa-file-download"></i> Download Excel</mdb-btn> -->
                </mdb-col>
            </mdb-row>
            <mdb-tbl responsive hover>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th>Name</th>
                    <th>Order Number</th>
                    <th class="text-right">Food Price</th>
                    <th class="text-right">Merchant Fee</th>
                    <th class="text-right">Subsidy</th>
                    <th class="text-right">Discount</th>
                    <th class="text-right">Subtotal</th>
                    <th class="text-right">Date / Time</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(merchant, index) in data" :key="merchant.id" @click="showOrderDetail(merchant)">
                    <th scope="row">{{index+1}}</th>
                    <td>
                        {{merchant.merchant_name}}
                    </td>
                    <td>
                        {{merchant.order_number}}
                    </td>
                    <td class="text-right">
                        {{merchant.item_price}}
                    </td>
                    <td class="text-right">
                        {{merchant.commission}} ({{merchant.payout_percentage}})
                    </td>
                    <td class="text-right">
                        {{merchant.subsidy}}
                    </td>
                    <td class="text-right">
                        {{merchant.discount}}
                    </td>
                    <td class="text-right">
                        <mdb-tooltip v-if="merchant.payment_type=='ONLINE_BANKING'" trigger="hover" :options="{placement: 'top'}">
                            <span slot="tip">Online Banking</span>
                            <a slot="reference">
                               <img :src="require('@/assets/online_banking.png')" style="height: 20px; width: 20px" />
                            </a>
                        </mdb-tooltip>
                        <mdb-tooltip v-if="merchant.payment_type=='CASH'" trigger="hover" :options="{placement: 'top'}">
                            <span slot="tip">Cash</span>
                            <a slot="reference">
                                <img :src="require('@/assets/cash.png')" style="height: 20px; width: 20px" />
                            </a>
                        </mdb-tooltip>
                        <mdb-tooltip v-if="merchant.payment_type=='CARD'" trigger="hover" :options="{placement: 'top'}">
                            <span slot="tip">Card</span>
                            <a slot="reference">
                                <img :src="require('@/assets/card.png')" style="height: 20px; width: 20px" />
                            </a>
                        </mdb-tooltip>
                        <mdb-tooltip v-if="merchant.payment_type=='EWALLET'" trigger="hover" :options="{placement: 'top'}">
                            <span slot="tip">E-Wallet</span>
                            <a slot="reference">
                                <img :src="require('@/assets/e_wallet.png')" style="height: 20px; width: 20px" />
                            </a>
                        </mdb-tooltip>
                        RM {{merchant.subtotal}}
                    </td>
                    <td class="text-right">
                        {{momentFormat16(merchant.created_at)}}
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <OrderDetailComponent :id="focus_order_id" :visible="order_detail_component" :close="closeOrderDetailComponent" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol, mdbTooltip, mdbDatePicker, mdbSpinner } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {momentFormat16} from '../js/helper';
import OrderDetailComponent from '../components/OrderDetailComponent';

export default {
  name: 'partner_payout',
  components: {
    mdbContainer, mdbTooltip,
    AppNavigation, mdbRow, mdbCol,
    mdbTbl, mdbTblHead, mdbTblBody, 
    mdbDatePicker, mdbSpinner,
    OrderDetailComponent
  },
  data: ()=>({
      loading:false,
      data: [],
      date_from: "",
      date_to: "",
      focus_order_id: 0,
      order_detail_component: false,
  }),
  methods: {
      getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/get_merchant_payout",
            data: {
                date_from: this.date_from,
                date_to: this.date_to
            },
            complete: ()=>this.loading=false,
            success: result=>{
                this.data=result.data;
            },
        }, this)
      },
    //   downloadExcel(){
    //     if(this.loading){
    //         return false;
    //     }
    //     this.loading=true;
    //     ajax({
    //         url: "admin/excel_partner_payout",
    //         data: {
    //             date_from: this.date_from,
    //             date_to: this.date_to
    //         },
    //         complete: ()=>this.loading=false,
    //         success: result=>{
    //             window.open(APP_STORAGE_URL+result.data);
    //         },
    //     }, this)
    //   },
      momentFormat16(date){
          return momentFormat16(date);
      },
      closeOrderDetailComponent(){
        this.order_detail_component=false;
        this.focus_order_id=0;
      },
      showOrderDetail(merchant){
          this.focus_order_id=merchant.service_order_id;
          this.order_detail_component=true;
      }
  },
  mounted: function(){
    let date = new Date();
    let month = date.getMonth()+1;
    let today=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
    date.setDate(date.getDate()+1);
    let tomorrow=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
    this.date_from=today;
    this.date_to=tomorrow;
    this.getData();
  }
}
</script>
