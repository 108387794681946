<template>
  <mdb-side-nav-2
    v-model="show"
    :data="navigation"
    :over="over"
    @setOffset="togglerOffset = $event"
    v-mdb-resize:start="checkWidth"
  >
    <div slot="header">
      <div class="d-flex flex-center p-2" >
        <img
          :src="require('@/assets/lapar_transparent.png')"
          class="img-fluid"
          style="height: 150px; width: 150px"
        />
      </div>
    </div>
    <mdb-navbar
      slot="nav"
      position="top"
      color="purple"
      dark
      :toggler="false"
    >
      <mdb-navbar-nav left>
        <mdb-icon
          :style="{
            paddingLeft: togglerOffset + 'px',
            transition: 'padding-left .3s linear'
          }"
          icon="bars"
          color="dark-grey-text"
          size="lg"
          @click.native="show = !show"
        />
      </mdb-navbar-nav>
      <mdb-navbar-nav class="nav-flex-icons" right>
        <mdb-nav-item
          anchor-class="text-white"
          @click="logout"
          waves-fixed
          icon="user"
          far
          ><span class="ml-1">{{this.$store.state.user.name}}</span></mdb-nav-item
        >
      </mdb-navbar-nav>
    </mdb-navbar>
    <div slot="main" style="height: 85vh; margin-top: 5rem;">
      <slot></slot>
    </div>
  </mdb-side-nav-2>
</template>

<script>
  import {checkPermissions, checkPermissionsBinary} from '../js/helper';
  import {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbSideNav2,
    mdbIcon,
    waves,
    mdbResize
  } from "mdbvue";

  export default {
    name: "DoubleNavigationPagev1",
    components: {
      mdbNavbar,
      mdbNavItem,
      mdbNavbarNav,
      mdbSideNav2,
      mdbIcon
    },
    data() {
      return {
        show: true,
        over: false,
        togglerOffset: 0,
        navigation: []
      };
    },
    methods: {
      checkWidth() {
        this.over = window.innerWidth < 900;
      },
      logout(){
        localStorage.clear();
        window.location.href="/";
      }
    },
    mixins: [waves],
    directives: {
      mdbResize
    },
    mounted: function(){
      let permissions=this.$store.state.permissions;
      this.navigation.push({
        name: "Dashboard",
        icon: "chart-bar",
        to: "/landing"
      })
      if(checkPermissionsBinary(2, null, permissions)){
        this.navigation.push({
          name: "Jobs",
          icon: "file-invoice",
          children: [
            {
              name: "Orders",
              to: "/orders"
            },
            {
              name: "Refund Orders",
              to: "/refund_orders"
            },
          ]
        })
      }
      if(checkPermissionsBinary(4, null, permissions)){
        this.navigation.push({
          name: "Admins Management",
          icon: "user-tie",
          children: [
            {
              name: "Admins",
              to: "/admins"
            },
            {
              name: "Permissions",
              to: "/permissions"
            },
            {
              name: "Roles",
              to: "/roles"
            }
          ]
        })
      }
      if(checkPermissionsBinary(8, null, permissions)){
        this.navigation.push({
          name: "Users Management",
          icon: "users",
          children: [
            {
              name: "Users",
              to: "/users"
            },
          ]
        })
      }
      if(checkPermissionsBinary(16, null, permissions)){
        let children=[];
        if(checkPermissionsBinary(65536, null, permissions)){
          children.push({
            name: "Add Merchant",
            to: "/add_merchant"
          })
        }
        if(checkPermissionsBinary(1048576, null, permissions)){
          children.push({
            name: "Merchant List",
            to: "/merchant_list"
          })
        }
        if(checkPermissionsBinary(262144, null, permissions)){
          children.push({
            name: "Merchant Payout",
            to: "/merchant_payout"
          })
        }
        if(checkPermissionsBinary(524288, null, permissions)){
          children.push({
            name: "Merchant Settlement",
            to: "/merchant_settlement"
          })
        }

        if(checkPermissionsBinary(permissions, 38)){
          children.push({
            name: "Pending Approve",
            to: "/pending_approve"
          })
        }
        
        this.navigation.push({
          name: "Merchants Management",
          icon: "utensils",
          children: children
        })
        
      }
      if(checkPermissionsBinary(32, null, permissions)){
        let children=[];
        if(checkPermissionsBinary(permissions, 33)){
          children.push({
            name: "Add Rider",
            to: "/add_partner"
          })
        }
        if(checkPermissionsBinary(permissions, 34)){
          children.push({
            name: "Rider Online",
            to: "/partner_online"
          })
        }
        if(checkPermissionsBinary(permissions, 35)){
          children.push({
            name: "Rider List",
            to: "/partner_list"
          })
        }
        if(checkPermissionsBinary(permissions, 36)){
          children.push({
            name: "Rider Payout",
            to: "/partner_payout"
          })
        }
        if(checkPermissionsBinary(permissions, 37)){
          children.push({
            name: "Rider Collection",
            to: "/partner_collection"
          })
        }
        this.navigation.push({
          name: "Riders Management",
          icon: "motorcycle",
          children: children
        })
      }
      if(checkPermissions(2097152, permissions)){
        let children=[];
        if(checkPermissionsBinary(permissions, 31)){
          children.push({
            name: "Add Promo Code",
            to: "/add_promotion"
          })
        }
        if(checkPermissionsBinary(permissions, 32)){
          children.push({
            name: "Promotions List",
            to: "/promotion_list"
          })
        }
        children.push({
          name: "Add Cashback",
          to: "/add_cashback"
        })
        children.push({
          name: "Cashback Lists",
          to: "/cashback_list"
        })
        this.navigation.push({
          name: "Promotion Management",
          icon: "percentage",
          children: children
        })
      }
      if(checkPermissions(128, permissions)){
        let children=[];
        if(checkPermissionsBinary(permissions, 41)){
          children.push({
            name: "App Version Control",
            to: "/app_version_control"
          })
        }
        if(checkPermissionsBinary(permissions, 42)){
          children.push({
            name: "Main Screen Configuration",
            to: "/app_configuration"
          })
        }
        if(checkPermissionsBinary(permissions, 43)){
          children.push({
            name: "Zone Coverage",
            to: "/zone_coverage"
          })
        }
        this.navigation.push({
          name: "App Config",
          icon: "tools",
          children: children
        })
      }
    }
  };
</script>

<style scoped>
  .navbar i {
    cursor: pointer;
    color: white;
  }
</style>