<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Pending Approve Merchant</h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <input v-on:keyup.enter="startSearch" v-model="search" type="text" placeholder="Name" class="form-control">
                    </div>
                </mdb-col>
                <mdb-col md="3">
                    <select @change="startSearch" v-model="status" class="browser-default custom-select">
                        <option value="PENDING">Pending</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Rejected</option>
                    </select>
                </mdb-col>
                <mdb-col md="3">
                    <mdb-btn @click="startSearch" size="sm" color="primary">Search</mdb-btn>
                </mdb-col>
            </mdb-row>
            <mdb-tbl responsive striped>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th width="35%">Name</th>
                    <th width="40%">Created Datetime</th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(merchant, index) in merchants" :key="merchant.id">
                    <th scope="row">{{index+1}}</th>
                    <td class="font-weight-bold d-flex flex-row">
                        <mdb-view :src="getAvatar(merchant.avatar)" class="rounded" alt="image" style="height: 100px; width: 150px;">
                        <a>
                            <mdb-mask overlay="white-slight"></mdb-mask>
                        </a>
                        </mdb-view>
                        <div class="d-flex flex-column ml-1">
                            <div>{{merchant.name}}</div>
                            <div>Zone: {{merchant.zone?merchant.zone.name:"Not Found"}}</div>
                            <div v-if="merchant.referral">Referral: {{merchant.referral}}</div>
                            <div v-if="merchant.services_used"><span class="mr-2" v-for="(service) in JSON.parse(merchant.services_used)" :key="service">{{service}}</span></div>
                        </div>
                    </td>
                    <td>
                        <div><mdb-icon far icon="envelope" /> {{merchant.email}}</div>
                        <div><mdb-icon  icon="map-marker-alt" /> {{merchant.address}}</div>
                        <div><mdb-icon  icon="phone" /> {{merchant.contact_number}}</div>
                        <div v-if="merchant.description"><mdb-icon  icon="comments" /> {{merchant.description}}</div>
                    </td>
                    <td>
                        <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                            <span slot="tip">View Profile</span>
                            <a @click="showProfile(merchant)" slot="reference">
                                <i class="far fa-eye px-2"></i>
                            </a>
                        </mdb-tooltip>
                        <div>{{momentFormat16(merchant.created_at)}}</div>
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbBtn, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, 
mdbIcon, mdbView, mdbMask, mdbTooltip
} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import scroll from '../js/infinityScroll';
import {ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import {momentFormat16} from '../js/helper';

export default {
    name: 'pending_approve_merchant',
    components: {
        mdbContainer, mdbBtn, mdbRow, mdbCol,
        AppNavigation, mdbTbl, mdbTblHead, mdbTblBody, 
        mdbIcon, mdbView, mdbMask, mdbTooltip,
    },
    data: ()=>({
        loading: false,
        search: "",
        merchants: [],
        status: "PENDING"
    }),
    methods: {
        getData(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/list_merchant_approval",
                data: {
                    id: this.merchants.length==0?0:this.merchants[this.merchants.length-1].id,
                    search: this.search,
                    status: this.status
                },
                complete: ()=>this.loading=false,
                success: result=>{
                    let _temp=[...this.merchants];
                    this.merchants=_temp.concat(result.data);
                },
            }, this)
        },
        startSearch(){
            this.merchants=[];
            this.getData();
        },
        getAvatar(avatar){
          if(!avatar){
              return require('@/assets/placeholder_empty.jpg');
          }
          return APP_FILE_URL+avatar;
        },
        momentFormat16(time){
            return momentFormat16(time);
        },
        showProfile(merchant){
            this.$router.push({ name: 'view_pending_merchant', params: {id: merchant.id} })
        }
    },
  mounted: function(){
      this.getData();
      scroll(()=>this.getData());
    }
}
</script>
