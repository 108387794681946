<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Add Cashback</h1>
            <hr>
            <mdb-card class="mb-4">
              <mdb-card-body>
                <mdb-row class="mb-3">
                  <mdb-col md="12">
                    <mdb-input v-model="name" label="Cashback Event Name" size="sm" />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col md="6">
                    <mdb-date-picker :autoHide="true" v-model="date_start" label="Date From" />
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-date-picker :autoHide="true" v-model="date_end" label="Date To" />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col md="4">
                    <mdb-input v-model="percentage" label="Percentage (0.02)" size="sm" />
                  </mdb-col>
                  <mdb-col md="4">
                    <mdb-input v-model="amount_cap" label="Amount Cap" size="sm" />
                  </mdb-col>
                  <mdb-col md="4">
                    <mdb-input v-model="min_spend" label="Minimum Spend" size="sm" />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col md="6">
                    <mdb-input v-model="valid_days" label="Valid Days" size="sm" />
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-input v-model="merchant_percentage" label="Merchant Percentage (0.02)" size="sm" />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col md="6">
                    <mdb-input v-model="notification_title" label="Notification Title" size="sm" />
                  </mdb-col>
                  <mdb-col md="6">
                    <mdb-input v-model="notification_body" label="Notification Body" size="sm" />
                  </mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mb-4">
              <mdb-card-body>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input v-model="search_merchant" label="Merchant Name" size="sm" />
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col col="12" class="d-flex flex-wrap">
                    <div v-for="name in selected_merchants_name" class="selected-merchant-badge" v-bind:key="name">{{name}}</div>
                  </mdb-col>
                </mdb-row>
                <mdb-col class="merchant-list" md="6" v-for="merchant in display_merchants" v-bind:key="merchant.id" @click.native="toggleMerchant(merchant)">
                    <mdb-row>
                      <mdb-col col="1" class="d-flex flex-center">
                        <mdb-icon class="text-success" v-if="checkSelect(merchant)" far icon="check-square" />
                        <mdb-icon v-if="!checkSelect(merchant)" far icon="square" />
                      </mdb-col>
                      <mdb-col col="11" class="mb-1">
                        <div style="font-size: 0.8rem; font-weight: bold;">{{merchant.name}}</div>
                        <div style="font-size: 0.75rem; color: #2E2E2E">{{merchant.zone?merchant.zone.name:'No Zone Found'}}</div>
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mb-4">
              <mdb-card-body>
                <mdb-row class="mb-3">
                  <mdb-col md="12">
                    <mdb-btn color="primary" v-if="!loading" @click="submitCashbackConfig">Submit</mdb-btn>
                    <mdb-spinner v-if="loading" small />
                  </mdb-col>
                </mdb-row>
              </mdb-card-body>
            </mdb-card>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbDatePicker, mdbInput, mdbBtn, mdbIcon, mdbSpinner} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {validatePercentage, validateRequired, validateNumber} from '../js/validation';

export default {
  name: 'add_cashback',
  components: {
    mdbContainer, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbDatePicker, mdbInput, mdbBtn, mdbIcon, mdbSpinner,
    AppNavigation,
  },
  data: ()=>({
    name: "",
    percentage: "",
    date_start: "",
    date_end: "",
    valid_days: "",
    min_spend: "",
    amount_cap: "",
    merchant_percentage: "",
    notification_title: "",
    notification_body: "",
    selected_merchants: [],
    selected_merchants_name: [],
    all_merchants: [],
    search_merchant: "",
    display_merchants: [],
    timer: null,
    loading: false,
  }),
  methods: {
    checkSelect(merchant){
      let found_index= this.selected_merchants.findIndex(element=>{
        return element==merchant.id;
      })
      return found_index>=0?true:false;
    },
    toggleMerchant(merchant){
      let selected_merchants=[...this.selected_merchants];
      let selected_merchants_name=[...this.selected_merchants_name];
      let found_index = selected_merchants.findIndex(element=>{
        return element==merchant.id;
      })
      if(found_index>=0){
        //delete
        selected_merchants.splice(found_index, 1);
        selected_merchants_name.splice(found_index, 1);
      }else{
        //add
        selected_merchants.push(merchant.id);
        selected_merchants_name.push(merchant.name);
      }
      this.selected_merchants=selected_merchants;
      this.selected_merchants_name=selected_merchants_name;
    },
    submitCashbackConfig(){
      if(!validateRequired(this.name)){
        this.$notify.error({message: "HELO! Where is event name?", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateRequired(this.date_start)){
        this.$notify.error({message: "HELO! Start Date?", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateRequired(this.date_end)){
        this.$notify.error({message: "HELO! Date End?", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validatePercentage(this.percentage)){
        this.$notify.error({message: "HELO! Percentage format is wrong", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateNumber(this.amount_cap)){
        this.$notify.error({message: "HELO! Amount Cap please fill in", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateNumber(this.min_spend)){
        this.$notify.error({message: "Minimum Spend please put 0 if no minimum spend required", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateNumber(this.valid_days)){
        this.$notify.error({message: "Please fill in valid days", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validatePercentage(this.merchant_percentage)){
        this.$notify.error({message: "Merchant percentage format is not correct", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.selected_merchants.length==0){
        this.$notify.error({message: "No merchants were selected. Access Denied", position:'top right', timeOut: 5000});
        return false;
      }
      this.loading=true;
      ajax({
      url: "admin/submit_cashbacks",
      data: {
        selected_merchants: this.selected_merchants,
        name: this.name,
        percentage: this.percentage,
        date_start: this.date_start,
        date_end: this.date_end,
        amount_cap:this.amount_cap,
        min_spend:this.min_spend,
        valid_days: this.valid_days,
        merchant_percentage: this.merchant_percentage,
        notification_title: this.notification_title,
        notification_body: this.notification_body
      },
      complete: ()=>this.loading=false,
      success: ()=>{
        this.$notify.success({message: "Cashback Config Added", position:'top right', timeOut: 5000});
        this.$router.push({ name: "cashback_list" })
      },
    }, this)
    }
  },
  mounted: function(){
    ajax({
      url: "admin/merchant_selections",
      data: {zone: ""},
      complete: ()=>this.loading=false,
      success: result=>{
        this.all_merchants=result.data;
      },
    }, this)
  },
  watch: {
    search_merchant(val){
      clearTimeout(this.timer);
      if(!val){
        this.display_merchants=[];
        return false;
      }
      this.timer=setTimeout(()=>{
        var search = new RegExp(val , 'i');
        let searched_merchants = this.all_merchants.filter(merchant => search.test(merchant.name));
        this.display_merchants=searched_merchants;
      }, 1500)
    }
  }
}
</script>

<style scoped>
.merchant-list:hover{
  cursor: pointer;
  background-color: #eeeeee;
}
.merchant-list{
  padding-top: 10px;
  padding-bottom: 10px;
}
.selected-merchant-badge{
  background-color: #eeeeee;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}
</style>