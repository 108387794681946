import moment from 'moment';

export const momentFormat16=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY h:mm a');
    }
    return "";
}

export const momentFormat=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY h:mm a');
    }
    return "";
}

export const momentFormatDate=(date)=>{
    if(date){
        return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY');
    }
    return "";
}

export const checkPermissions=(value, permission)=>{
    if(!value){
        return false;
    }
    if(value & permission){
        return true;
    }
    return false;
}

export const checkPermissionsBinary=(value, position, permission)=>{
    if(!value){
        return false;
    }
    if(value <= 2147483648){
        if(value & permission){
            return true;
        }else{
            return false;
        }
    }
    let binary=value.toString(2);
    if(binary.length <= position){
        return false;
    }
    let actual_position =parseInt(binary.length) - parseInt(position);
    actual_position--;
    if(binary.charAt(actual_position)==1){
        return true;
    }
    return false;
}

export const getRandomColor =()=>{
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}