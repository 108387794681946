<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Users</h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <div class="form-group">
                        <input v-on:keyup.enter="searchData" v-model="search" type="text" placeholder="#Name" class="form-control">
                    </div>
                </mdb-col>
                <mdb-col md="3" class="d-flex align-items-center">
                    <mdb-spinner small v-if="loading" />
                    <div v-if="!loading">Total Count: {{count}}</div>
                </mdb-col>
            </mdb-row>
            <mdb-tbl responsive hover>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th>Username/Name</th>
                    <th>Phone Number</th>
                    <th>Referral Code</th>
                    <th>Last Login</th>
                    <th class="hover-sort" @click="changeSort">Date Time <i v-if="sort_by=='asc'" class="fas fa-arrow-up"></i><i v-if="sort_by=='desc'" class="fas fa-arrow-down"></i></th>
                </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                <tr v-for="(user, index) in users" :key="user.id">
                    <th scope="row">{{index+1}}</th>
                    <td>
                        {{user.username}}<br/>
                        {{user.name}}
                    </td>
                    <td>
                        {{user.phone_number}}
                    </td>
                    <td>
                        {{user.referral_code}}
                    </td>
                    <td>
                        {{momentFormat(user.last_login)}}
                    </td>
                    <td>
                        {{momentFormat16(user.created_at)}}
                    </td>
                </tr>
                </mdb-tbl-body>
            </mdb-tbl>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol, mdbSpinner } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import scroll from '../js/infinityScroll';
import {momentFormat16, momentFormat} from '../js/helper';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'users',
  components: {
    mdbContainer, mdbSpinner,
    AppNavigation, mdbRow, mdbCol,
    mdbTbl, mdbTblHead, mdbTblBody,
  },
  data: ()=>({
      loading:false,
      users: [],
      search: "",
      count: 0,
      sort_by: "asc"
  }),
  methods: {
      getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/list_users",
            data: {
                id: this.users.length==0?null:this.users[this.users.length-1].id,
                search: this.search,
                sort_by: this.sort_by
            },
            complete: ()=>this.loading=false,
            success: result=>{
                let _temp=[...this.users];
                this.users=_temp.concat(result.data.users);
                this.count=result.data.count;
            },
        }, this)
      },
      momentFormat(date){
          return momentFormat(date);
      },
      momentFormat16(date){
          return momentFormat16(date);
      },
      searchData(){
          this.users=[];
          this.getData();
      },
      changeSort(){
          this.sort_by=this.sort_by=="asc"?"desc":"asc";
          this.searchData();
      }
  },
  mounted: function(){
      this.getData();
      scroll(()=>this.getData());
  }
}
</script>

<style scoped>
.hover-sort:hover{
    cursor: pointer;
    color: #4285F4;
}
</style>
