<template>
  <div>
    <mdb-modal size="lg" @shown="componentDidMount" :show="visible" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>{{this.id==0?"Add New Product":"Edit Product"}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-spinner size='lg' v-if="loading" />
        <mdb-container v-if="!loading">
          <mdb-row class="mb-3">
            <mdb-col>
              <mdb-card>
                <mdb-card-body>
                  <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Product Information</h4>
                  <mdb-view :src="getAvatar()" alt="image" style="height: 200px; width: 400px;">
                  <a @click="openFileSelect">
                      <mdb-mask overlay="white-slight"></mdb-mask>
                  </a>
                  <input multiple type="file" id="file-upload" @change="avatarUpload" hidden />
                  </mdb-view>
                  <mdb-row>
                    <mdb-col col="8">
                      <mdb-input v-model="name" label="Product Name" size="sm" />
                    </mdb-col>
                    <mdb-col col="4">
                      <mdb-input type="number" v-model="price" label="Base Price" size="sm" />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="12">
                      <mdb-input v-model="description" label="Product Description" size="sm" />
                    </mdb-col>
                  </mdb-row>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
          <mdb-card>
            <mdb-card-body>
              <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Product Variety</h4>
              <div draggable="true" @dragstart="dragStartOption(index, 'VARIETY')" @drop="dropHereOption(index, 'VARIETY')" @dragenter.prevent @dragover.prevent v-for="(variety, index) in varieties" v-bind:key="variety.id">
                <mdb-row>
                  <mdb-col col="12" class="variation-container">
                    <mdb-row>
                      <mdb-col col="6">
                        <mdb-input class="m-1" label="Variety Name" v-model="variety.name" size="sm" />
                      </mdb-col>
                      <mdb-col col="6">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" :name="'variety-container-edit'+variety.id" v-model="variety.type" value="RADIO" class="custom-control-input" :id="'variety-edit-radio-'+variety.id">
                          <label class="custom-control-label" :for="'variety-edit-radio-'+variety.id">Radio</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" :name="'variety-container-edit'+variety.id" v-model="variety.type" value="CHECKBOX" class="custom-control-input" :id="'variety-edit-checkbox-'+variety.id">
                          <label class="custom-control-label" :for="'variety-edit-checkbox-'+variety.id">Checkbox</label>
                        </div>  
                      </mdb-col>
                    </mdb-row>
                    <mdb-row class="variation-child-container" v-for="(option, _index) in variety.options" v-bind:key="_index">
                      <mdb-col col="8" >
                        <mdb-input class="m-1" label="Name" v-model="option.name" size="sm" />
                      </mdb-col>
                      <mdb-col col="4">
                        <mdb-input class="m-1" type="number" label="Price" v-model="option.price" size="sm" />
                        <mdb-icon  icon="times" class="cross-option-btn" @click.native="deleteVarietyOption(index, _index)"/>
                      </mdb-col>
                    </mdb-row>
                    <mdb-row>
                      <mdb-col col="12">
                        <mdb-btn size="sm" @click="addVarietyOption(index)" color="primary"><mdb-icon icon="plus" /></mdb-btn>
                        <mdb-btn size="sm" @click="deleteVariety(index)" color="danger"><mdb-icon far icon="trash-alt" /></mdb-btn>
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>
              </div>
              <mdb-row class="variation-container">
                <mdb-col col="12">
                  <mdb-row>
                    <mdb-col col="12">
                      <h5 class="h5-responsive">Add New Variety</h5>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" name="variety-container" v-model="focus_variety.type" value="RADIO" class="custom-control-input" id="variety-option-1">
                        <label class="custom-control-label" for="variety-option-1">Radio</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" name="variety-container" v-model="focus_variety.type" value="CHECKBOX" class="custom-control-input" id="variety-option-2">
                        <label class="custom-control-label" for="variety-option-2">Checkbox</label>
                      </div>    
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col col="8">
                      <mdb-input label="Variety Name" v-model="focus_variety.name" size="sm" />
                    </mdb-col>
                    <mdb-col col="4">
                      <mdb-btn color="primary" v-if="!show_add_option" size="sm" @click="show_add_option=true"><mdb-icon icon="plus" /> Add Option</mdb-btn>
                    </mdb-col>
                  </mdb-row>
                  <div draggable="true" @dragstart="dragStartOption(index, 'OPTION')" @drop="dropHereOption(index, 'OPTION')" @dragenter.prevent @dragover.prevent v-for="(option, index) in focus_variety.options" v-bind:key="index">
                  <mdb-row>
                    <mdb-col col="8">
                      <mdb-input class="m-1" label="Option Name" v-model="option.name" size="sm" />
                    </mdb-col>
                    <mdb-col col="4">
                      <mdb-input class="m-1" label="Option Price" type="number" v-model="option.price" size="sm" />
                      <mdb-icon  icon="times" class="cross-btn" @click.native="deleteOption(index)"/>
                    </mdb-col>
                  </mdb-row>
                  </div>
                  <mdb-row>
                    <mdb-col col="8" v-if="show_add_option">
                      <mdb-input label="Option Name" v-model="focus_option.name" size="sm" />
                    </mdb-col>
                    <mdb-col col="4" v-if="show_add_option">
                      <mdb-input label="Option Price" type="number" v-model="focus_option.price" size="sm" />
                    </mdb-col>
                    <mdb-col col="12">
                      <mdb-btn color="success" v-if="!show_add_option && this.focus_variety.options.length!=0" size="sm" @click="submitVariety"><mdb-icon icon="plus" /> Submit Variety</mdb-btn>
                      <mdb-btn color="primary" v-if="show_add_option && this.focus_option.name && this.focus_option.price" size="sm" @click="submitOption"><mdb-icon icon="plus" /> Submit Option</mdb-btn>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="12">
                  <mdb-btn color="primary" size="sm" v-if="show_add_variety" @click="show_add_variety=true"><mdb-icon icon="plus" /> Add New Variety</mdb-btn>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner small v-if="loading" />
        <mdb-btn v-if="!loading" color="primary" @click="submitProduct">Submit</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbSpinner, mdbRow, mdbCol, mdbCard, mdbCardBody,
  mdbView, mdbMask, mdbContainer, mdbInput, mdbIcon } from 'mdbvue';
  import {APP_FILE_URL} from '../js/config';
  import {fileUploadAjax, ajax} from '../js/ajax';
  import {getColor} from '../js/colors';

  export default {
    components: {
      mdbModal, mdbContainer,
      mdbModalHeader, mdbInput,
      mdbModalTitle,
      mdbModalBody, mdbIcon,
      mdbModalFooter,
      mdbBtn,
      mdbSpinner, mdbRow, mdbCol, mdbCard, mdbCardBody,
      mdbView, mdbMask
    },
    props: ['visible', 'close', 'focus_product', 'focus_category', 'merchant_id'],
    data: ()=>({
      id: 0,
      avatar: "",
      loading: true,
      price: "",
      description: "",
      name: "",
      category_id:0,
      varieties: [],
      show_add_variety: false,
      show_add_option: false,
      focus_variety: {
        name: "",
        type: "RADIO",
        options: []
      },
      focus_option: {
        name: "",
        price: ""
      },
      drag_index: 0,
      drag_type: ""
    }),
    methods: {
      componentDidMount(){
        if(this.focus_product){
          this.id=this.focus_product.id;
          ajax({
            url: "admin/get_product_detail",
            data: {
              id: this.focus_product.id
            },
            complete: ()=>this.loading=false,
            success: result=>{
              this.avatar=result.data.product.avatar;
              this.price=result.data.product.price;
              this.description=result.data.product.description;
              this.name=result.data.product.name;
              result.data.varieties.forEach(element => {
                element.options=JSON.parse(element.options);
              });
              this.varieties=result.data.varieties;
            },
          }, this)
        }else{
          this.loading=false;
        }
        this.category_id=this.focus_category.id;
      },
      hideModal(){
        this.id=0;
        this.avatar="";
        this.category_id=0;
        this.loading=true;
        this.price="";
        this.description="";
        this.name="";
        this.avatar="";
        this.price="";
        this.description="";
        this.name="";
        this.varieties=[];
        this.focus_variety={
          name: "",
          type: "RADIO",
          options: []
        };
        this.focus_option={
          name: "",
          price: ""
        };
        this.show_add_variety=false;
        this.show_add_option=false;
        this.close();
      },
      getAvatar(){
        if(!this.avatar){
            return require('@/assets/placeholder.jpg');
        }
        return APP_FILE_URL+this.avatar;
      },
      openFileSelect(){
        document.getElementById("file-upload").click();
      },
      avatarUpload(event){
        fileUploadAjax({
            url: "admin/upload_product_avatar",
            data: {event: event.target.files, id: this.id},
            complete: ()=>this.action_loading=false,
            success: result=>{
              this.avatar=result.data;
            },
            isShowAlert: true
        }, this)
      },
      submitProduct(){
        if(this.loading){
          return false;
        }
        //check for empty option
        let continue_submit=true;
        this.varieties.forEach(variety=>{
          variety.options.forEach(option=>{
            if(!option.name || !option.price){
              continue_submit=false;
            }
          })
        })
        if(!continue_submit){
          this.$notify.error({message: 'Helo! please remove the empty option!', position:'top right', timeOut: 5000});
          return false;
        }
        this.loading=true;
        ajax({
          url: "admin/submit_product",
          data: {
            category_id: this.category_id,
            merchant_id: this.merchant_id,
            name: this.name,
            price: this.price,
            avatar: this.avatar,
            product_id: this.id,
            description: this.description,
            varieties: this.varieties
          },
          complete: ()=>this.loading=false,
          success: result=>{
            this.merchant=result.data.merchant;
            this.category_products=result.data.category_products;
            this.$notify.success({message: this.name+' has been updated.', position:'top right', timeOut: 5000});
            this.close();
          },
        }, this)
      },
      getColor(color){
        return getColor(color);
      },
      addVariety(){
        let new_variety={
          name: "",
          options: [],
          type: "RADIO",
        }
        let temp = [...this.varieties];
        temp.push(new_variety);
        this.varieties=temp;
      },
      submitOption(){
        let temp={...this.focus_variety};
        temp.options.push(this.focus_option);
        this.focus_variety=temp;
        this.focus_option={
          name: "",
          price: ""
        }
        this.show_add_option=false;
        // this.focus_variety.options.push(this.focus_option);
        // this.show_add_option=false;
      },
      deleteOption(index){
        let temp=[...this.focus_variety.options]
        temp.splice(index, 1);
        this.focus_variety.options=temp;
      },
      dragStartOption(index, type){
        this.drag_index=index;
        this.drag_type=type;
      },
      dropHereOption(index, type){
        if(type !== this.drag_type){
          return false;
        }
        if(type=="OPTION"){
          let temp_variety={...this.focus_variety};
          let temp_option=temp_variety.options[index];
          temp_variety.options[index]=temp_variety.options[this.drag_index];
          temp_variety.options[this.drag_index]=temp_option;
          this.focus_variety=temp_variety;
        }
        if(type=="VARIETY"){
          let temp_varieties=[...this.varieties];
          let temp_variety=temp_varieties[index];
          temp_varieties[index]=temp_varieties[this.drag_index];
          temp_varieties[this.drag_index]=temp_variety;
          this.varieties=temp_varieties;
        }
      },
      submitVariety(){
        this.focus_variety.options=this.focus_variety.options;
        this.varieties.push(this.focus_variety);
        this.focus_variety={
          name: "",
          type: "RADIO",
          options: []
        }
      },
      deleteVariety(index){
        let temp=[...this.varieties];
        temp.splice(index, 1);
        this.varieties=temp;
      },
      addVarietyOption(index){
        let temp=this.varieties[index].options;
        temp.push({name: "", price: ""});
        this.varieties[index].option=temp;
      },
      deleteVarietyOption(parent_index, child_index){
        this.varieties[parent_index].options.splice(child_index, 1);
      }
    }
  };
</script>

<style scoped>
.variation-container{
  border: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #fafafa;
}
.cross-btn{
  position: absolute;
  right: 5px;
  top: 5px;
  color: #ff4444;
  z-index: 1;
}
.cross-option-btn{
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ff4444;
  z-index: 1;
}
</style>