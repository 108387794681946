export const validateEmail = (txt) => {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(txt);
}

export const validateRequired = (attribute) => {
    if (attribute=="" || attribute.trim() == '' || attribute == null) {
        return false;
    }
    return attribute.trim();
}

export const validateIC = (txt) => {
    if(txt.length == 12) {
        var regex = /^\d+$/;
        return regex.test(txt);
    }
    return false;
}

export const validatePhone = (txt) => {
    if(txt.length >= 10 && txt.length <= 15) {
        var regex = /^\d+$/;
        return regex.test(txt);
    }
    return false;
}

export const validatePercentage=(txt)=>{
    if(parseFloat(txt)>=0 && parseFloat(txt)<=1){
        return true;
    }
    return false;
}

export const validateSpecialCharacter=(txt)=>{
    var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return format.test(txt);
}

export const validateNumber=(txt)=>{
    if(/^\d+(?:\.\d{1,2})?$/.test(txt)){
        return true;
    }
    return false;
}