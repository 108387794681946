<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Refund Orders</h1>
            <hr>
            <mdb-row>
                <mdb-col md="3">
                    <mdb-date-picker :autoHide="true" v-model="from" label="Date From" />
                </mdb-col>
                <mdb-col md="3">
                    <mdb-date-picker :autoHide="true" v-model="to" label="Date To" />
                </mdb-col>
                <mdb-col md="3" class="align-self-center">
                    <mdb-btn @click="getData()" color="primary" size="sm">Search</mdb-btn>
                </mdb-col>
            </mdb-row>
            <p class="text-danger" v-if="loading">Please Wait... Fetching Data dont panic don't spam refresh!</p>
            <mdb-tbl responsive hover>
                <mdb-tbl-head>
                <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order No.</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th class="text-right">Amount</th>
                    <th class="text-right">Date Time</th>
                    <th class="text-right">Action</th>
                </tr>
                </mdb-tbl-head>
                    <mdb-tbl-body>
                    <tr v-for="(order, index) in refund" :key="order.id"  @click="showOrderDetail(order.service_order.id)" style="cursor: pointer">
                        <th scope="row">{{index+1}}</th>
                        <th scope="row" @click.prevent.stop>{{order.service_order.order_number}}</th>
                        <th scope="row">
                            <div class="text-warning" v-if="order.is_refunded==0 && order.is_deleted==0">Pending Refund</div>
                            <div class="text-danger" v-if="order.is_deleted==1">Refund Rejected</div>
                            <div class="text-success" v-if="order.is_refunded==1">Refund Success</div>
                        </th>
                        <th scope="row">
                            <div>{{order.reason}}</div>
                        </th>
                        <th scope="row" class="text-right">
                            <div class="d-flex flex-row justify-content-end">
                                <mdb-tooltip v-if="order.service_order.service_charge.payment_type=='ONLINE_BANKING'" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Online Banking</span>
                                    <a slot="reference">
                                    <img :src="require('@/assets/online_banking.png')" style="height: 20px; width: 20px" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.service_order.service_charge.payment_type=='CREDIT_DEBIT_CARD'" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Card</span>
                                    <a slot="reference">
                                        <img :src="require('@/assets/card.png')" style="height: 20px; width: 20px" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.service_order.service_charge.payment_type=='EWALLET'" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">E-Wallet</span>
                                    <a slot="reference">
                                        <img :src="require('@/assets/e_wallet.png')" style="height: 20px; width: 20px" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.service_order.service_charge.payment_type=='TNG'" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Touch and Go eWallet</span>
                                    <a slot="reference">
                                        <img :src="require('@/assets/tng.png')" style="height: 20px; width: 20px" />
                                    </a>
                                </mdb-tooltip>
                                <mdb-tooltip v-if="order.service_order.service_charge.payment_type=='CASH'" trigger="hover" :options="{placement: 'top'}">
                                    <span slot="tip">Cash</span>
                                    <a slot="reference">
                                        <img :src="require('@/assets/cash.png')" style="height: 20px; width: 20px" />
                                    </a>
                                </mdb-tooltip>
                                <div class="ml-2">RM {{order.refund_amount}}</div>
                            </div>
                        </th>
                        <th scope="row" class="text-right">
                            <div>{{momentFormat16(order.created_at)}}</div>
                            <div class="text-success">{{momentFormat(order.refunded_at)}}</div>
                        </th>
                        <th class="text-right" @click.prevent.stop>
                            <mdb-spinner small v-if="order.is_loading"/>
                            <mdb-tooltip v-if="!order.is_loading && order.is_refunded==0 && order.is_deleted==0" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Refund Success</span>
                                <a slot="reference">
                                    <mdb-btn @click="showSubmitRefund(order)" color="success" size="sm"><mdb-icon icon="check" /></mdb-btn>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip v-if="!order.is_loading && order.is_refunded==0 && order.is_deleted==0" trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Refund Decline</span>
                                <a slot="reference">
                                    <mdb-btn @click="rejectRefund(order)" color="danger" size="sm"><mdb-icon icon="times" /></mdb-btn>
                                </a>
                            </mdb-tooltip>
                        </th>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <ConfirmationModal :visible="confirmation_refund" :close="closeOrderDetailComponent" title="Confirm Refunded?" :submit="submitRefund" />
            <OrderDetailComponent :promo_codes="promo_codes" :id="focus_order_id" :visible="order_detail_component" :close="closeOrderDetailComponent" :callback="callback" />
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbTooltip, mdbBtn, mdbIcon, mdbSpinner, mdbDatePicker} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import moment from 'moment';
import OrderDetailComponent from '../components/OrderDetailComponent';
import ConfirmationModal from '../components/ConfirmationModal'

export default {
  name: 'refund_orders',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbTooltip, mdbBtn, mdbIcon,
    AppNavigation, OrderDetailComponent, mdbSpinner, mdbDatePicker, ConfirmationModal
  },
  data: ()=>({
      loading: false,
      refund: [],
      from: "",
      to: "",
      promo_codes: [],
      focus_order_id: 0,
      order_detail_component: false,
      confirmation_refund: false,
      focus_order: null
  }),
  methods: {
    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/refund_orders",
            data: {
                from: this.from,
                to: this.to,
            },
            complete: ()=>this.loading=false,
            success: result=>{
                result.data.forEach(refund => {
                    refund.is_loading=false;
                });
                this.refund=result.data;
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    momentFormat16(date){
        if(date){
            return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY h:mm a');
        }
        return "";
    },
    momentFormat(date){
        if(date){
            return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY h:mm a');
        }
        return "";
    },
    showOrderDetail(id){
        this.focus_order_id=id;
        this.order_detail_component=true;
    },
    closeOrderDetailComponent(){
        this.order_detail_component=false;
        this.confirmation_refund=false;
        this.focus_order_id=0;
    },
    callback(){
        this.getData();
    },
    submitRefund(){
        this.focus_order.is_loading=true;
        ajax({
            url: "admin/submit_refund",
            data: {
                id: this.focus_order.id
            },
            complete: ()=>this.focus_order.is_loading=false,
            success: ()=>{
                this.focus_order.is_refunded=1;
                this.closeOrderDetailComponent();
                this.$notify.success({message: "Refund Success", position: 'top right', timeOut: 5000})
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    showSubmitRefund(order){
        this.confirmation_refund=true;
        this.focus_order=order;
    },
    rejectRefund(order){
        order.is_loading=true;
         ajax({
            url: "admin/delete_refund",
            data: {
                id: order.id
            },
            complete: ()=>order.is_loading=false,
            success: ()=>{
                order.is_deleted=1;
                this.$notify.success({message: 'Refund Declined', position: 'top right', timeOut: 5000})
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    }
  },
  mounted: function(){
        let date = new Date();
        let month = date.getMonth()+1;
        let today=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
        date.setDate(date.getDate()+1);
        let tomorrow=date.getFullYear()+'-'+("0" + month).slice(-2)+'-'+("0" + date.getDate()).slice(-2);
        this.from=today;
        this.to=tomorrow;
        this.getData();
        scroll(()=>this.getData());
        ajax({
            url: "admin/get_compensate_voucher",
            success: result=>{
                this.promo_codes=result.data;
            }
        })
  }
}
</script>
