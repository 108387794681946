<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Partner Online</h1>
            <hr>
            <mdb-row>
                <mdb-col>
                    <mdb-tab tabs color="primary" justify>
                        <mdb-tab-item icon="motorcycle" :active="pillsActive==0" @click.native.prevent="switchTab(0)">Partner Online List</mdb-tab-item>
                        <mdb-tab-item icon="map-marked-alt" :active="pillsActive==1" @click.native.prevent="switchTab(1)">Map</mdb-tab-item>
                    </mdb-tab>
                    <mdb-tab-content>
                        <mdb-tab-pane class="fade" key="show1" v-if="pillsActive==0">
                            <mdb-tbl responsive striped>
                                <mdb-tbl-head>
                                <tr>
                                    <th width="5%">#</th>
                                    <th width="30%">Name</th>
                                    <th width="15%">Vehicle No.</th>
                                    <th width="20%">Type</th>
                                    <th width="15%">Online Time</th>
                                    <th width="15%">Last Update</th>
                                </tr>
                                </mdb-tbl-head>
                                <mdb-tbl-body>
                                <tr v-for="(partner, index) in partners" :key="partner.id">
                                    <th scope="row">{{index+1}}</th>
                                    <td class="d-flex flex-row align-items-center">
                                        {{partner.user.name}}
                                        <div v-if="!partner.has_job" class="partner-noJob" />
                                        <div v-if="partner.has_job" class="partner-hasJob" />
                                    </td>
                                    <td>
                                        {{partner.partner_profile.vehicle_registration_number}}
                                    </td>
                                    <td>
                                        {{partner.partner_profile.type}}
                                        (RM {{parseFloat(partner.partner_profile.payout_percentage) * parseFloat(services.delivery_fare)}}/Order)
                                    </td>
                                    <td>
                                        {{momentFormat16(partner.created_at)}}
                                    </td>
                                    <td>
                                        {{momentFormat(partner.last_update)}}
                                    </td>
                                </tr>
                                </mdb-tbl-body>
                            </mdb-tbl>
                        </mdb-tab-pane>
                        <mdb-tab-pane class="fade" key="show2" v-if="pillsActive==1">
                            <div id="map"></div>
                        </mdb-tab-pane>
                    </mdb-tab-content>
                </mdb-col>
            </mdb-row>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer,mdbTbl, mdbTblHead, mdbTblBody, mdbRow, mdbCol, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {momentFormat16, momentFormat} from '../js/helper';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'partner_online',
  components: {
    mdbContainer,
    AppNavigation,
    mdbTbl, 
    mdbTblHead, 
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbTab,
    mdbTabItem,
    mdbTabContent,
    mdbTabPane,
  },
  data: ()=>({
        loading: false,
        partners: [],
        pillsActive: 0,
        verticalWithin: 0,
        coordinates: [],
        markers: [],
        map:null,
        services: null
  }),
  methods: {
    getData(){
        this.loading=true;
        ajax({
            url: "admin/get_partner_online",
            complete: ()=>this.loading=false,
            success: result=>{
                this.coordinates=new Array();
                this.partners=result.data.partner_online;
                this.services=result.data.services[0];
                this.partners.sort((a, b)=>{
                    if(a.user.name < b.user.name) { return -1; }
                    if(a.user.name > b.user.name) { return 1; }
                    return 0;
                })
                result.data.partner_online.forEach(element => {
                    this.coordinates.push({
                        latitude: element.lat,
                        longitude: element.lng,
                        title: element.partner_profile.fullname,
                    })
                });
                if(this.pillsActive==1){
                    setTimeout(()=>this.renderMap(), 500);
                }
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    switchTab(tab){
        this.pillsActive=tab;
        this.getData();
    },
    renderMap(){
        const google = window.google;
        if(!this.map){
            var mapProp = {
                center:new google.maps.LatLng(3.0764785,101.4828676),
                zoom:12,
            };
            this.map=new google.maps.Map(document.getElementById("map"),mapProp);
        }
        //reset markers
        this.markers.forEach(element => {
            element.setMap(null);
        });
        this.markers=[];
        this.coordinates.forEach(element => {
            let partner_points = new google.maps.LatLng(element.latitude,element.longitude);
            var partner_image = {
                url: require('@/assets/rider_marker.png'),
                scaledSize: new google.maps.Size(25, 25)
            };
            var partner_marker = new google.maps.Marker({
                position: partner_points,
                title:element.title,
                icon: partner_image
            });
            partner_marker.setMap(this.map);
            this.markers.push(partner_marker);
        });
    },
    momentFormat16(date){
        return momentFormat16(date);
    },
    momentFormat(date){
        return momentFormat(date);
    }
  },
  mounted: function(){
      this.getData();
      this.timer=setInterval(()=>this.getData(), 15000);
  },
  beforeDestroy(){
      clearInterval(this.timer);
  }
}
</script>

<style scoped>
  .slide-toggle-enter-active {
    transition: .3s ease-in;
    opacity: 1;
    max-height: 500px;
  }

  .slide-toggle-enter,
  .slide-toggle-leave-active {
    opacity: 0;
    max-height: 0;
  }

  .slide-toggle-leave {
    opacity: 1;
    max-height: 500px;
  }

  #map{
    height: 400px;
    width: 100%;
}
.partner-noJob{
    height: 8px;
    width: 8px;
    background-color: #00C851;
    border-radius: 8px;
    margin-left: 10px;
}
.partner-hasJob{
    height: 8px;
    width: 8px;
    background-color: #ff4444;
    border-radius: 8px;
    margin-left: 10px;
}
</style>