<template>
    <AppNavigation>
        <mdb-container fluid>
          <h1 class="h1-responsive">Promo Code List</h1>
          <hr>
          <mdb-row class="mb-3">
            <mdb-col col="3">
              <input v-on:keyup.enter="getData('reset')" v-model="search" type="text" placeholder="Search Code" class="form-control">
            </mdb-col>
            <mdb-col col="3">
              <select @change="getData('reset')" v-model="filter_active" class="browser-default custom-select">
                <option value="" selected>All</option>
                <option value="0">Past</option>
                <option value="1">Going</option>
                <option value="2">Future</option>
              </select>
            </mdb-col>
            <mdb-col col="3">
              <select @change="getData('reset')" v-model="is_global" class="browser-default custom-select">
                <option value="" selected>All User/Global</option>
                <option value="0">User</option>
                <option value="1">Global</option>
              </select>
            </mdb-col>
          </mdb-row>
          <mdb-tabs
            :active="0"
            default
            :transition-duration="0.5"
            transition-style="linear"
            @activeTab="getActiveTabIndex"
            :links="[
              { text: 'Free'},
              { text: 'Paid' },
              { text: 'Cashback'},
              { text: 'Compensate'}]"
          />
          <mdb-row>
            <mdb-col>
              <mdb-tbl responsive hover>
                <mdb-tbl-head>
                  <tr>
                      <th width="5%">#</th>
                      <th width="10%">Code</th>
                      <th>Date to Date</th>
                      <th>Time Available</th>
                      <th width="20%">Critiria</th>
                      <th width="10%">Info</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr v-for="(promo, index) in promo_config" :key="index">
                    <td scope="row">{{index+1}}</td>
                    <td  @click="viewPromoCodeDetail(promo)">
                      <div class="font-weight-bold" :style="{color: checkPromoStatus(promo)}">{{promo.code}}</div>
                      <div class="text-muted" style="font-size: 0.75rem">{{promo.description}}</div>
                    </td>
                    <td>{{momentFormat(promo.date_from)}} to {{momentFormatDay(promo.date_to)}}</td>
                    <td>{{promo.time_from}} to {{promo.time_to}}</td>
                    <td>
                      <mdb-row>
                        <mdb-col style="font-size: 0.85rem; color: #2E2E2E;" col="6">
                          Type: 
                        </mdb-col>
                        <mdb-col style="font-weight: bold;" col="6">
                          {{promo.type}}
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col style="font-size: 0.85rem; color: #2E2E2E;" col="6">
                          Min: 
                        </mdb-col>
                        <mdb-col style="font-weight: bold;" col="6">
                          {{promo.min_spend}}
                        </mdb-col>
                      </mdb-row>
                      <mdb-row v-if="promo.amount > 0">
                        <mdb-col style="font-size: 0.85rem; color: #2E2E2E;" col="6">
                          Fix Amount: 
                        </mdb-col>
                        <mdb-col style="font-weight: bold;" col="6">
                          {{promo.amount}}
                        </mdb-col>
                      </mdb-row>
                      <mdb-row v-if="promo.amount_percentage > 0">
                        <mdb-col style="font-size: 0.85rem; color: #2E2E2E;" col="6">
                          Percentage: 
                        </mdb-col>
                        <mdb-col style="font-weight: bold;" col="6">
                          {{parseFloat(promo.amount_percentage)*100}}%
                        </mdb-col>
                      </mdb-row>
                      <mdb-row v-if="promo.amount_cap > 0">
                        <mdb-col style="font-size: 0.85rem; color: #2E2E2E;" col="6">
                          Cap: 
                        </mdb-col>
                        <mdb-col style="font-weight: bold;" col="6">
                          {{promo.amount_cap}}
                        </mdb-col>
                      </mdb-row>
                    </td>
                    <td>
                      <mdb-tooltip v-if="promo.is_global==1" trigger="hover" :options="{placement: 'top'}">
                        <span slot="tip">Global Promo Code</span>
                        <a slot="reference">
                          <mdb-icon  style="margin-right: 5px; color: #00C851" icon="globe"/>{{promo.quantity}}
                        </a>
                      </mdb-tooltip>
                      <mdb-tooltip v-if="promo.is_global==0" trigger="hover" :options="{placement: 'top'}">
                        <span slot="tip">Add User</span>
                        <a slot="reference" @click="openAddUserPromoCodeModal(promo)">
                          <mdb-icon icon="user-plus" style="margin-right: 5px; color: #00C851" />{{promo.used_count}}/{{promo.total_count}}
                        </a>
                      </mdb-tooltip>
                    </td>
                  </tr>
              </mdb-tbl-body>
              </mdb-tbl>
            </mdb-col>
          </mdb-row>
          <AddUserPromoCode :visible="add_user_promo_code" :close="closeModal" :promo_code_id="promo_code_id" />
          <PromoCodeModal :all_merchants="all_merchants" :focus_promo="focus_promo" :visible="show_promo_code_modal" :close="closeModal"/>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody, mdbTooltip, mdbIcon, mdbTabs } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';
import AddUserPromoCode from '../components/AddUserPromoCode';
import moment from 'moment';
import scroll from '../js/infinityScroll';
import PromoCodeModal from '../components/PromoCodeModal';

export default {
  name: 'promotion_list',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTooltip, mdbIcon, PromoCodeModal,
    AppNavigation, mdbTbl, mdbTblHead, mdbTblBody, AddUserPromoCode, mdbTabs  },
  data: ()=>({
    promo_config: [],
    loading: false,
    add_user_promo_code: false,
    promo_code_id: 0,
    filter_active: "",
    search: "",
    is_global: "",
    type: "FREE",
    show_promo_code_modal: false,
    focus_promo: null,
    all_merchants: []
  }),
  methods: {
    getActiveTabIndex(index){
      switch(index){
        case 0: {
          this.type="FREE";
          break;
        }
        case 1: {
          this.type="PAID";
          break;
        }
        case 2: {
          this.type="CASHBACK";
          break;
        }
        case 3: {
          this.type="COMPENSATE";
          break;
        }
      }
      this.promo_config=[];
      this.getData();
    },
    getData(reset){
      if(this.loading){
        return false;
      }
      if(reset=='reset'){
        this.promo_config=[];
      }
      this.loading=true;
      ajax({
        url: "admin/get_promocode_config",
        data: {search: this.search, is_global: this.is_global, is_active: this.filter_active, type: this.type, id: this.promo_config.length==0?0:this.promo_config[this.promo_config.length-1].id},
        complete: ()=>this.loading=false,
        success: result=>{
          let _temp=[...this.promo_config];
          this.promo_config=_temp.concat(result.data);
        },
      }, this)
    },
    closeModal(){
      this.add_user_promo_code=false;
      this.show_promo_code_modal=false;
    },
    openAddUserPromoCodeModal(promo){
      this.add_user_promo_code=true;
      this.promo_code_id=promo.id;
    },
    momentFormatDay(date){
      return moment(date, "YYYY-MM-DD").subtract(1, 'd').format('DD/MM/YYYY');
    },
    momentFormat(date){
      return moment(date, "YYYY-MM-DD").format('DD/MM/YYYY');
    },
    checkPromoStatus(element){
      if(element.is_global=="0"){
        return "#2E2E2E";
      }
      let today=new Date();
      if(today > new Date(element.date_from) && today< new Date(element.date_to)){
        return "#00C851";
      }if(today>new Date(element.date_from) && today > new Date(element.date_to)){
        return "#ff4444";
      }else{
        //future
        return "#f0bb1b";
      }
    },
    viewPromoCodeDetail(promo){
      this.focus_promo=promo;
      this.show_promo_code_modal=true;
    },
    getMerchantList(){
      ajax({
        url: "admin/merchant_selections",
        success: result=>{
          this.all_merchants=result.data;
        },
      }, this)
    }
  },
  mounted: function(){
    this.getData();
    scroll(()=>this.getData());
    this.getMerchantList();
  },
}
</script>
