<template>
  <AppNavigation>
    <mdb-container fluid>
      <h2 class="h2-responsive animated bounce infinite">Welcome Back <span class="font-weight-bold">{{this.$store.state.user.name}}</span>.</h2>
      <h6 class="animated slideInLeft h6-responsive">Keep Calm and dont panic.</h6>
      <mdb-row v-if="!loading" class="mb-5">
        <mdb-col md="6">
          <mdb-doughnut-chart
            :data="order_status_data"
            :options="doughnutChartOptions"
            :width="600"
            :height="300"
          ></mdb-doughnut-chart>
          <h5 class="h5-responsive text-center">Todays Order</h5>
        </mdb-col>
        <mdb-col md="6">
          <mdb-bar-chart
            :data="order_zone_data"
            :options="doughnutChartOptions"
            :width="600"
            :height="300"
          ></mdb-bar-chart>
          <h5 class="h5-responsive text-center">Todays Order By Zone</h5>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="!loading" class="mb-5">
        <mdb-col md="6">
          <mdb-bar-chart
            :data="merchant_active_data"
            :options="doughnutChartOptions"
            :width="600"
            :height="300"
          ></mdb-bar-chart>
          <h5 class="h5-responsive text-center">Active Merchant by Zone</h5>
        </mdb-col>
        <mdb-col md="6">
          <mdb-bar-chart
            :data="merchant_deactive_data"
            :options="doughnutChartOptions"
            :width="600"
            :height="300"
          ></mdb-bar-chart>
          <h5 class="h5-responsive text-center">Inactive Merchant by Zone</h5>
        </mdb-col>
      </mdb-row>
      <mdb-row v-if="!loading">
        <mdb-col md="6">
          <mdb-doughnut-chart
            :data="rider_data"
            :options="doughnutChartOptions"
            :width="600"
            :height="300"
          ></mdb-doughnut-chart>
          <h5 class="h5-responsive text-center">Realtime Rider Status</h5>
        </mdb-col>
        <mdb-col md="6">
          <mdb-doughnut-chart
            :data="promo_code_data"
            :options="doughnutChartOptions"
            :width="600"
            :height="300"
          ></mdb-doughnut-chart>
          <h5 class="h5-responsive text-center">Promo Code Used Count</h5>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbDoughnutChart, mdbBarChart} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {getRandomColor} from '../js/helper';

export default {
  name: 'home',
  components: {
    mdbContainer, mdbRow, mdbCol, 
    AppNavigation, mdbDoughnutChart, mdbBarChart
  },
  data: ()=>({
    cancelled_orders: 0,
    completed_orders: 0,
    merchant_active: [],
    merchant_deactive: [],
    new_orders: [],
    rider_online: [],
    rider_total: [],
    promo_code: [],
    zones: [],
    loading: true,
    order_status_data: {
      labels: ["Completed", "Cancelled", "On Going"],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#4caf50",
            "#F44336",
            "#ffeb3b"
          ],
          hoverBackgroundColor: [
            "#66bb6a",
            "#EF5350",
            "#ffee58",
          ]
        }
      ]
    },
    order_zone_data: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Today's Order by Zone",
          backgroundColor: []
        }
      ]
    },
    merchant_active_data: {
      labels: [],
      datasets: [
        {
          label: "Active Merchant by Zone",
          data: [],
          backgroundColor: []
        }
      ]
    },
    merchant_deactive_data: {
      labels: [],
      datasets: [
        {
          label: "Inactive Merchant by Zone",
          data: [],
          backgroundColor: []
        }
      ]
    },
    rider_data: {
      labels: ['online', 'offline'],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#66bb6a",
            '#EF5350',
          ]
        }
      ]
    },
    promo_code_data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: []
        }
      ]
    },
    doughnutChartOptions: {
      responsive: false,
      maintainAspectRatio: false
    }
  }),
  methods: {
  },
  mounted: function(){
    ajax({
      url: "admin/get_dashboard",
      success: result=>{
        this.cancelled_orders=result.data.cancelled_orders;
        this.completed_orders=result.data.completed_orders;
        this.merchant_active=result.data.merchant_active;
        this.merchant_deactive=result.data.merchant_deactive;
        this.new_orders=result.data.new_orders;
        this.rider_online=result.data.rider_online;
        this.rider_total=result.data.rider_total;
        this.zones=result.data.zones;
        this.promo_code=result.data.promo_code;

        //generate graph for order
        this.order_status_data.datasets[0].data=[this.completed_orders, this.cancelled_orders, this.new_orders];
        this.rider_data.datasets[0].data=[this.rider_online, this.rider_total];

        //generate zone order
        let zone=[];
        zone=Object.keys(this.zones);
        zone.forEach(element => {
          this.order_zone_data.labels.push(element);
          this.order_zone_data.datasets[0].data.push(this.zones[element]);
          this.order_zone_data.datasets[0].backgroundColor.push(getRandomColor());
        });

        //merchant active
        let active=[];
        active=Object.keys(this.merchant_active);
        active.forEach(element => {
          this.merchant_active_data.labels.push(element);
          this.merchant_active_data.datasets[0].data.push(this.merchant_active[element]);
          this.merchant_active_data.datasets[0].backgroundColor.push(getRandomColor());
        });

        //merchant deactive
        let deactive=[];
        deactive=Object.keys(this.merchant_deactive);
        deactive.forEach(element => {
          this.merchant_deactive_data.labels.push(element);
          this.merchant_deactive_data.datasets[0].data.push(this.merchant_deactive[element]);
          this.merchant_deactive_data.datasets[0].backgroundColor.push(getRandomColor());
        });

        let promo_code_key=[];
        promo_code_key=Object.keys(this.promo_code);
        promo_code_key.forEach(element => {
          this.promo_code_data.labels.push(element);
          this.promo_code_data.datasets[0].data.push(this.promo_code[element]);
          this.promo_code_data.datasets[0].backgroundColor.push(getRandomColor());
        });
        this.loading=false
      },
    }, this)
  }
}
</script>
