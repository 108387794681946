<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">App Main Configuration</h1>
            <hr>
            <h3 class="h3-responsive">App Advertisment Popup <mdb-btn class="btn-sm" @click="advertisment_popup=true" color="success">Add Ads Popup</mdb-btn></h3>
            <mdb-row>
                <mdb-col class="mb-2" md="3" v-for="(ad, index) in ads"  :key="ad.id">
                    <div draggable="true" @dragstart="dragStart(index, 'ADS')" @drop="dropHere(index, 'ADS')" @dragenter.prevent @dragover.prevent>
                        <mdb-card>
                            <div class="promotion-img" v-bind:style="{ 'background-image': 'url(' + ad.img + ')' }">
                                <div @click="toggleActive(ad, index)" class="custom-control custom-switch ml-1 mt-1">
                                    <input type="checkbox" class="custom-control-input" v-model="ad.is_active">
                                    <label class="custom-control-label"></label>
                                </div>
                            </div>
                            <mdb-card-body class="d-flex flex-column align-items-center">
                                <mdb-card-title class="text-center">{{ad.name}}</mdb-card-title>
                                <mdb-card-text class="text-center">{{ad.app}}</mdb-card-text>
                                <div class="d-flex flex-row justify-content-end">
                                    <mdb-btn class="btn-sm" color="info" @click="editAds(ad)"><mdb-icon far icon="edit" /></mdb-btn>
                                    <mdb-btn class="btn-sm" color="primary" v-if="ad.url" @click="openUrl(ad.url)"><mdb-icon icon="globe" /></mdb-btn>
                                    <mdb-btn class="btn-sm" color="danger" @click="deleteAds(ad)"><mdb-icon far icon="trash-alt" /></mdb-btn>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div> 
                </mdb-col>
            </mdb-row>
            <hr class="my-5" />
            <h3 class="h3-responsive">Promotion Banner <mdb-btn class="btn-sm" @click="promotion_banner_modal=true" color="success">Add Promotion Banner</mdb-btn></h3>
            <mdb-row>
                <mdb-col class="mb-2" md="3" v-for="(banner, index) in promotion_banner"  :key="banner.id">
                    <div draggable="true" @dragstart="dragStart(index, 'BANNER')" @drop="dropHere(index, 'BANNER')" @dragenter.prevent @dragover.prevent>
                        <mdb-card>
                            <div class="promotion-img" v-bind:style="{ 'background-image': 'url(' + getImage(banner.img) + ')' }" >
                                <div @click="toggleBannerActive(banner, index)" class="custom-control custom-switch ml-1 mt-1">
                                    <input type="checkbox" class="custom-control-input" v-model="banner.is_active">
                                    <label class="custom-control-label"></label>
                                </div>
                            </div>
                            <mdb-card-body class="d-flex flex-column align-items-center">
                                <mdb-card-title class="text-center">{{banner.name}}</mdb-card-title>
                                <div class="d-flex flex-row justify-content-end">
                                    <mdb-btn class="btn-sm" color="info" @click="editBanner(banner)"><mdb-icon far icon="edit" /></mdb-btn>
                                    <mdb-btn @click="viewMerchant(banner, 'PROMOTION_BANNER')" class="btn-sm" color="primary"><mdb-icon far icon="eye" /></mdb-btn>
                                    <mdb-btn class="btn-sm" color="danger" @click="deleteBanner(banner)"><mdb-icon far icon="trash-alt" /></mdb-btn>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </mdb-col>
            </mdb-row>
            <hr class="my-5" />
            <h3 class="h3-responsive">App Home <mdb-btn class="btn-sm" @click="app_home_modal=true" color="success">Add App Home</mdb-btn></h3>
            <mdb-row class="mb-5">
                <mdb-col class="mb-2" md="3" v-for="(app, index) in app_home"  :key="app.id">
                    <div draggable="true" @dragstart="dragStart(index, 'APPHOME')" @drop="dropHere(index, 'APPHOME')" @dragenter.prevent @dragover.prevent>
                        <mdb-card>
                            <mdb-card-body class="d-flex flex-column align-items-center">
                                <div @click="toggleAppHomeActive(app, index)" class="custom-control custom-switch ml-1 mt-1">
                                    <input type="checkbox" class="custom-control-input" v-model="app.is_active">
                                    <label class="custom-control-label"></label>
                                </div>
                                <mdb-card-title class="text-center">{{app.name_en}}</mdb-card-title>
                                <mdb-card-title class="text-center">{{app.name_bm}}</mdb-card-title>
                                <div class="d-flex flex-row justify-content-end">
                                    <mdb-btn class="btn-sm" color="info" @click="editAppHome(app)"><mdb-icon far icon="edit" /></mdb-btn>
                                    <mdb-btn class="btn-sm" color="primary" @click="viewMerchant(app, 'APP_HOME')"><mdb-icon far icon="eye" /></mdb-btn>
                                    <mdb-btn class="btn-sm" color="danger" @click="deleteAppHome(app)"><mdb-icon far icon="trash-alt" /></mdb-btn>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div>
                </mdb-col>
            </mdb-row>
        </mdb-container>
        <AdvertismentPopup :focus_advertisment="focus_advertisment" :visible="advertisment_popup" :close="closeModal" />
        <PromotionBanner :focus_promotion="focus_promotion" :visible="promotion_banner_modal" :close="closeModal"  />
        <AppHome :focus_app_home="focus_app_home" :visible="app_home_modal" :close="closeModal"  />
        <AppMainScreenMerchant :all_merchants="all_merchants" :id="app_main_screen_merchant_id" :type="app_main_screen_merchant_type" :visible="app_main_screen_merchant" :close="closeModal" />
    </AppNavigation>
</template>

<script>
import {mdbContainer,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardTitle, mdbBtn, mdbCardText, mdbIcon} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import AdvertismentPopup from '../components/AdvertismentPopup';
import PromotionBanner from '../components/PromotionBanner';
import AppHome from '../components/AppHome';
import AppMainScreenMerchant from '../components/AppMainScreenMerchant';

export default {
  name: 'app_configuration',
  components: {
    mdbContainer, mdbCard, mdbCardBody, mdbCardTitle,
    AppNavigation,mdbRow, mdbCol, mdbBtn, mdbCardText,
    AdvertismentPopup, mdbIcon, PromotionBanner, AppHome,
    AppMainScreenMerchant
  },
  data: ()=>({
      loading:false,
      promotion_banner: [],
      ads: [],
      app_home:[],
      action_loading:false,
      advertisment_popup: false,
      promotion_banner_modal: false,
      app_main_screen_merchant: false,
      app_home_modal: false,
      focus_advertisment: null,
      focus_promotion: null,
      focus_app_home: null,
      app_main_screen_merchant_type: "",
      app_main_screen_merchant_id: 0,
      drag_index: 0,
      drag_type: "",
      all_merchants: []
  }),
  methods: {
    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/get_app_main_configuration",
            complete: ()=>this.loading=false,
            success: result=>{
                this.promotion_banner=result.data.promotion_banner;
                this.ads=result.data.ads;
                this.app_home=result.data.app_home;
                this.all_merchants=result.data.all_merchants;
            },
        }, this)
    },
    getImage(img){
        return APP_FILE_URL+img;
    },
    openUrl(url){
        if(url){
            window.open(url);
        }
        return null;
    },
    toggleActive(ad, index){
        if(this.action_loading){
            return false;
        }
        let _temp=[...this.ads];
        _temp[index].is_active=_temp[index].is_active==1?0:1;
        this.ads=_temp;
        this.action_loading=true;
        ajax({
            url: "admin/toggle_ads_active",
            data: {id: ad.id},
            complete: ()=>this.action_loading=false,
            success: ()=>{
                this.$notify.success({message: 'Advertisment Updated', position:'top right', timeOut: 5000});
            },
        }, this)
    },
    toggleAppHomeActive(app_home, index){
        if(this.action_loading){
            return false;
        }
        let _temp=[...this.app_home];
        _temp[index].is_active=_temp[index].is_active==1?0:1;
        this.app_home=_temp;
        this.action_loading=true;
        ajax({
            url: "admin/toggle_app_home_active",
            data: {id: app_home.id},
            complete: ()=>this.action_loading=false,
            success: ()=>{
                this.$notify.success({message: 'App Home Updated', position:'top right', timeOut: 5000});
            },
        }, this)
    },
    toggleBannerActive(banner, index){
        if(this.action_loading){
            return false;
        }
        let _temp=[...this.promotion_banner];
        _temp[index].is_active=_temp[index].is_active==1?0:1;
        this.promotion_banner=_temp;
        this.action_loading=true;
        ajax({
            url: "admin/toggle_banner",
            data: {id: banner.id},
            complete: ()=>this.action_loading=false,
            success: ()=>{
                this.$notify.success({message: 'Banner Updated', position:'top right', timeOut: 5000});
            },
        }, this)
    },
    deleteAds(ad){
        if(this.action_loading){
            return false;
        }
        this.action_loading=true;
        ajax({
            url: "admin/delete_ads",
            data: {id: ad.id},
            complete: ()=>this.action_loading=false,
            success: ()=>{
                this.$notify.success({message: 'Advertisment Deleted', position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    },
    closeModal(){
        this.getData();
        this.focus_advertisment=null;
        this.focus_promotion=null;
        this.focus_app_home=null;
        this.app_home_modal=false;
        this.advertisment_popup=false;
        this.promotion_banner_modal=false;
        this.app_main_screen_merchant=false;
    },
    editAds(ads){
        this.focus_advertisment=ads;
        this.advertisment_popup=true;
    },
    editBanner(banner){
        this.focus_promotion=banner;
        this.promotion_banner_modal=true;
    },
    deleteBanner(banner){
        if(this.action_loading){
            return false;
        }
        this.action_loading=true;
        ajax({
            url: "admin/delete_banner",
            data: {id: banner.id},
            complete: ()=>this.action_loading=false,
            success: ()=>{
                this.$notify.success({message: 'Promotion Banner Deleted', position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    },
    editAppHome(app_home){
        this.focus_app_home=app_home;
        this.app_home_modal=true;
    },
    deleteAppHome(app_home){
        if(this.action_loading){
            return false;
        }
        this.action_loading=true;
        ajax({
            url: "admin/delete_app_home",
            data: {id: app_home.id},
            complete: ()=>this.action_loading=false,
            success: ()=>{
                this.$notify.success({message: 'App Home Deleted', position:'top right', timeOut: 5000});
                this.getData();
            },
        }, this)
    },
    viewMerchant(obj, type){
        this.app_main_screen_merchant_id=obj.id;
        this.app_main_screen_merchant_type=type;
        this.app_main_screen_merchant=true;
    },
    dragStart(index, type){
        this.drag_index=index; 
        this.drag_type=type;      
    },
    dropHere(index, type){
        if(this.drag_type!=type){
            return false;
        }
        if(type=="ADS"){
            let temp=this.ads[this.drag_index];
            let _ads=[...this.ads];
            _ads[this.drag_index]=_ads[index];
            _ads[index]=temp;
            this.ads=_ads;
            let data=[temp.id, _ads[this.drag_index].id];
            ajax({
                url: "admin/switch_ads_sequence",
                data: {ads: data},
                success: ()=>{
                    this.$notify.success({message: 'Ads Sequence Updated', position:'top right', timeOut: 5000});
                },
            }, this)        
        }else if(type=="BANNER"){
            let temp=this.promotion_banner[this.drag_index];
            let _banner=[...this.promotion_banner];
            _banner[this.drag_index]=_banner[index];
            _banner[index]=temp;
            this.promotion_banner=_banner;
            let data=[temp.id, _banner[this.drag_index].id];
            ajax({
                url: "admin/switch_banner_sequence",
                data: {banner: data},
                success: ()=>{
                    this.$notify.success({message: 'Banner Sequence Updated', position:'top right', timeOut: 5000});
                },
            }, this)   
        }else
        if(type=="APPHOME"){
            let temp=this.app_home[this.drag_index];
            let _app_home=[...this.app_home];
            _app_home[this.drag_index]=_app_home[index];
            _app_home[index]=temp;
            this.app_home=_app_home;
            ajax({
                url: "admin/switch_app_home",
                data: {id_1: temp.id, id_2: _app_home[this.drag_index].id},
                success: ()=>{
                    this.$notify.success({message: 'App Home Sequence Updated', position:'top right', timeOut: 5000});
                },
            }, this)  
        }
    },
  },
  mounted: function(){
      this.getData();
  }
}
</script>

<style scoped>
.promotion-img{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 100%;
    background-color: black;
}
.active-ad{
    background-color: #00C851;
    border-radius: 15px;
    height: 15px;
    width: 15px;
    margin: 10px
}
.inactive-ad{
    background-color: #ff4444;
    border-radius: 15px;
    height: 15px;
    width: 15px;
    margin: 10px
}
</style>