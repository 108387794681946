<template>
    <AppNavigation>
        <mdb-container fluid>
            <h1 class="h1-responsive">Cashback List <mdb-spinner v-if="loading" small /></h1>
            <hr>
            <mdb-tbl responsive striped>
                <mdb-tbl-head>
                  <tr>
                      <th width="5%">#</th>
                      <th>Event Name</th>
                      <th>Date</th>
                      <th>Properties</th>
                      <th>Notifications</th>
                      <th>Actions</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body v-for="(cashback, index) in cashback_list" :key="index">
                    <tr>
                        <td>{{index+1}}</td>
                        <td>{{cashback.name}}</td>
                        <td>
                            <div>Start: <strong>{{momentFormat(cashback.date_start)}}</strong></div>
                            <div>End: <strong>{{momentFormat(cashback.date_end)}}</strong></div>
                        </td>
                        <td>
                            <div>Percentage: <strong>{{cashback.percentage * 100}}%</strong></div>
                            <div>Cap: <strong>RM {{cashback.amount_cap}}</strong></div>
                            <div>Valid Days: <strong>{{cashback.valid_days}}</strong></div>
                            <div>Min Spend: <strong>RM {{cashback.min_spend}}</strong></div>
                            <div>Merchant Bear: <strong>{{cashback.merchant_percentage * 100}}%</strong></div>
                        </td>
                        <td>
                            <div>Title: {{cashback.notification_title}}</div>
                            <div>Body: {{cashback.notification_body}}</div>
                        </td>
                        <td>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Edit</span>
                                <a slot="reference">
                                    <mdb-btn color="primary" size="sm"><mdb-icon icon="edit" /></mdb-btn>
                                </a>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Delete</span>
                                <a slot="reference">
                                    <mdb-btn color="danger" size="sm"><mdb-icon icon="times" /></mdb-btn>
                                </a>
                            </mdb-tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-wrap">
                                <div v-for="merchant in cashback.merchants" class="selected-merchant-badge" v-bind:key="merchant.id">{{merchant.merchants.name}}</div>
                            </div>
                        </td>
                    </tr>
              </mdb-tbl-body>
            </mdb-tbl>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbTooltip, mdbIcon, mdbSpinner } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';
import moment from 'moment';

export default {
  name: 'cashback_list',
  components: {
    mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbBtn, mdbTooltip, mdbIcon,
    AppNavigation, mdbSpinner
  },
  data: ()=>({
      loading: false,
      cashback_list: []
  }),
  methods: {
    getData(){
        if(this.loading){
            return false;
        }
        this.loading=true;
        ajax({
            url: "admin/list_cashbacks",
            data: {
                id: this.cashback_list.length==0?null:this.cashback_list[this.cashback_list.length-1].id,
            },
            complete: ()=>this.loading=false,
            success: result=>{
                let _temp=[...this.cashback_list];
                this.cashback_list=_temp.concat(result.data);
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
    },
    momentFormat16(date){
        if(date){
            return moment(date, "YYYY-MM-DD h:mm:ss").utcOffset('+16:00').format('DD/MM/YYYY h:mm a');
        }
        return "";
    },
    momentFormat(date){
        if(date){
            return moment(date, "YYYY-MM-DD h:mm:ss").format('DD/MM/YYYY');
        }
        return "";
    },
  },
  mounted: function(){
      this.getData();
  }
}
</script>

<style scoped>
.selected-merchant-badge{
  background-color: #eeeeee;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}
</style>