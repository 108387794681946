<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>{{id==0?'Add Role':'Edit Role'}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-input v-model="name" label="Name" size="sm" />
                    <mdb-input v-model="slug" label="Slug" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row v-for="permission in permissions" :key="permission.id" class="permission-row">
                <mdb-col col="12">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" :checked="checkSelected(permission)" @click="addSelected(permission)" class="custom-control-input" :id="'checkbox-'+permission.id">
                        <label class="custom-control-label" :for="'checkbox-'+permission.id">
                            {{permission.name}}
                        </label>
                    </div>
                </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
            <mdb-spinner v-if="loading" />
            <mdb-btn v-if="!loading" @click="submitRole" color="primary">{{this.id?"Edit":"Add"}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol,
  mdbInput, mdbSpinner } from 'mdbvue';
  import { ajax } from '../js/ajax';

  export default {
    components: {
      mdbModal, mdbSpinner,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter, mdbInput,
      mdbBtn,mdbContainer, mdbRow, mdbCol,
    },
    props: ['visible', 'close', "focus_role", 'permissions'],
    data: ()=>({
        loading:false,
        id: 0,
        slug: "",
        name: "",
        selected_permissions: []
    }),
    methods: {
        closeModal(){
            this.slug="";
            this.name="";
            this.id=0;
            this.loading=false;
            this.selected_permissions=[];
            this.close();
        },
        componentDidMount(){
            if(this.focus_role){
                this.slug=this.focus_role.slug;
                this.name=this.focus_role.name;
                this.id=this.focus_role.id;
                this.selected_permissions=this.focus_role.permissions;
            }
        },
        submitRole(){
          if(this.loading){
              return false;
          }
          this.loading=true;
          ajax({
              url: "admin/submit_role",
              data: {selected_permissions: this.selected_permissions, name: this.name, slug: this.slug, id: this.id},
              complete: ()=>this.loading=false,
              success: ()=>{
                  this.$notify.success({message: this.id==0?"Role Added":"Role Updated", position:'top right', timeOut: 5000});
                  this.close();
              },
          }, this)
        },
        addSelected(permission){
            const _index = this.selected_permissions.findIndex(el => {return el.id === permission.id});
            if(_index<0){
                this.selected_permissions.push(permission);
            }else{
                this.selected_permissions.splice(_index, 1);
            }
        },
        checkSelected(permission){
            const _index = this.selected_permissions.findIndex(el => {return el.id === permission.id});
            return _index<0?false:true;
        }
    }
  };
</script>

<style scoped>
.permission-row{
    padding: 1rem 0rem 1rem 0rem;
    border-bottom: 1px solid #eeeeee;
}

.permission-row:hover{
    background-color: #f5f5f5;
    cursor: pointer;
}
</style>