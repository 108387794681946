<template>
  <div class="page">
    <mdb-container v-if="merchant" class="subpage">
      <mdb-row class="mb-5">
        <mdb-col col="12" class="d-flex flex-row justify-content-between">
          <img :src="require('@/assets/lapar_transparent.png')" style="height: 120px; width: 120px" />
          <div class="d-flex flex-column">
            <div class="text-right">Lintasan Supremasi Sdn Bhd (830090-M)</div>
            <div class="text-right">A-3-1, Block A, Northpoint Offices, Mid Valley City,</div>
            <div class="text-right">No.1, Medan Syed Putra, 59200 Kuala Lumpur.</div>
            <div class="text-right">Tel: 03-2725 5678</div>
          </div>
        </mdb-col>
      </mdb-row>
      <h3 class="text-center h3-responsive font-weight-bold">Merchant Settlement Report</h3>
      <h5 class="text-center h5-responsive mb-3">Reporting Period: {{date_from}} to {{date_to}}</h5>
      <mdb-row>
        <mdb-col col="2">
          Merchant
        </mdb-col>
        <mdb-col col="7">
          <div class="text-left">{{merchant.name}}</div>
        </mdb-col>
        <mdb-col col="3">
          <div class="text-right">SST: {{merchant.tax==0?'0':'6'}}%</div>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-4">
        <mdb-col col="2">
          Address
        </mdb-col>
        <mdb-col col="7">
          <div class="text-left">{{merchant.address}}</div>
        </mdb-col>
        <mdb-col col="3">
          <div class="text-right">Platform Fee: {{parseInt(parseFloat(merchant.commission)*100)}}%</div>
        </mdb-col>
      </mdb-row>
      <h6 class="text-center h6-responsive font-weight-bold">Order Summary</h6>
      <mdb-tbl sm class="mb-5">
        <mdb-tbl-head>
          <tr>
            <th class="text-center">Completed</th>
            <th class="text-center">Cancelled</th>
            <th class="text-right">Order Amt</th>
            <th class="text-right">Platform Fee</th>
            <th class="text-right">Subsidy</th>
            <th class="text-right">Discount</th>
            <th class="text-right">Subtotal</th>
          </tr>
        </mdb-tbl-head>
        <mdb-tbl-body>
          <tr>
            <td class="text-center">{{completed_count}}</td>
            <td class="text-center">{{cancelled_count}}</td>
            <td class="text-right">{{total_item_price}}</td>
            <td class="text-right">{{total_commission}}</td>
            <td class="text-right">{{total_subsidy}}</td>
            <td class="text-right">{{total_discount}}</td>
            <td class="text-right">{{total_subtotal}}</td>
          </tr>
        </mdb-tbl-body>
      </mdb-tbl>
      <h6 class="text-center h6-responsive font-weight-bold">Deduction Summary</h6>
      <mdb-tbl sm class="mb-5">
        <mdb-tbl-head>
          <tr>
            <th class="text-left">Name / Description</th>
            <th class="text-right">Deduction Amount (RM)</th>
          </tr>
        </mdb-tbl-head>
        <mdb-tbl-body>
          <tr>
            <td class="text-left">Data Package</td>
            <td class="text-right">{{total_deduction_percentage}}</td>
          </tr>
           <tr>
            <td class="text-left">Mobile Package</td>
            <td class="text-right">{{total_deduction_amount}}</td>
          </tr>
        </mdb-tbl-body>
      </mdb-tbl>
      <mdb-row class="mb-3 summary-header">
        <mdb-col col="12">
          <h4 class="text-center h4-responsive m-0">Settlement Report Summary</h4>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-3">
        <mdb-col col="6" class="text-right">
          Total Earning for this period:
        </mdb-col>
        <mdb-col col="6">
          RM {{total_subtotal}}
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-3">
        <mdb-col col="6" class="text-right">
          Total Deduction for this period:
        </mdb-col>
        <mdb-col col="6">
          RM {{total_deduction}}
        </mdb-col>
      </mdb-row>
      <mdb-row class="summary-footer">
        <mdb-col col="6" class="text-right">
          Total Payment Expected:
        </mdb-col>
        <mdb-col col="6" class="font-weight-bold">
          RM {{grand_total}}
        </mdb-col>
      </mdb-row>
      <h5 class="h5-responsive text-center mt-5">Order List</h5>
      <mdb-tbl striped sm>
        <mdb-tbl-head>
          <tr>
            <th>Date</th>
            <th>Order</th>
            <th>Status</th>
            <th class="text-right">Order Amt</th>
            <th class="text-right">Merchant Fee</th>
            <th class="text-right">Subsidy</th>
            <th class="text-right">Discount</th>
            <th class="text-right">Subtotal</th>
          </tr>
        </mdb-tbl-head>
        <mdb-tbl-body>
          <tr v-for="order in orders" :key="order.order_number">
            <td>{{momentFormat16(order.created_at)}}</td>
            <td>{{order.order_number}}</td>
            <td>
              <div>{{order.status}}</div>
              <div style="font-size: 0.65rem;">{{order.cancel_reason}}</div>
            </td>
            <td class="text-right">{{parseFloat(order.food_price).toFixed(2)}}</td>
            <td class="text-right">{{order.commission?order.commission:"0.00"}}</td>
            <td class="text-right">{{order.subsidy?order.subsidy:"0.00"}}</td>
            <td class="text-right">{{order.discount?order.discount:'0.00'}}</td>
            <td class="text-right">{{order.subtotal?order.subtotal:"0.00"}}</td>
          </tr>
        </mdb-tbl-body>
      </mdb-tbl>
    </mdb-container>
  </div>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbTbl, mdbTblHead, mdbTblBody } from 'mdbvue';
import {ajax} from '../js/ajax';
// import {APP_FILE_URL} from '../js/config';
import {momentFormat16} from '../js/helper';

export default {
  name: 'merchant_settlement_print',
  components: {
    mdbContainer, mdbRow, mdbCol,
    mdbTbl, mdbTblHead, mdbTblBody
  },
  data: ()=>({
    date_from: "",
    date_to: "",
    merchant_id: "",
    loading:false,
    merchant: null,
    orders: [],
    completed_count: 0,
    cancelled_count: 0,
    total_item_price: 0,
    total_commission: 0,
    total_subsidy: 0,
    total_discount: 0,
    total_subtotal: 0,
    total_deduction_percentage: 0,
    total_deduction_amount: 0,
    total_deduction: 0,
    grand_total: 0
  }),
  methods: {
    getData(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
          url: "admin/generate_settlement",
          data: {
            merchant_id: this.merchant_id,
            date_from: this.date_from,
            date_to: this.date_to
          },
          complete: ()=>this.loading=false,
          success: result=>{
            this.merchant=result.data.merchant;
            this.orders=result.data.orders;
            this.completed_count=result.data.completed_count;
            this.cancelled_count=result.data.cancelled_count;
            this.total_item_price=result.data.total_item_price;
            this.total_commission=result.data.total_commission;
            this.total_subsidy=result.data.total_subsidy;
            this.total_discount=result.data.total_discount;
            this.total_subtotal=result.data.total_subtotal;
            this.total_deduction_amount=result.data.total_deduction_amount;
            this.total_deduction_percentage=result.data.total_deduction_percentage;
            this.total_deduction=result.data.total_deduction;
            this.grand_total=result.data.grand_total;
            document.title=result.data.merchant.name+'_'+this.date_from+"_"+this.date_to;
          },
      }, this)
    },
    momentFormat16(date){
      return momentFormat16(date);
    }
  },
  mounted: function(){
    this.merchant_id=this.$route.query.merchant_id;
    this.date_from=this.$route.query.from;
    this.date_to=this.$route.query.to;
    this.getData();
  }
}
</script>

<style scoped>
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
}
.page {
  width: 210mm;
  min-height: 297mm;
  padding: 0mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
  padding: 1cm;
  height: 256mm;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;        
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.summary-header{
  background-color: #eeeeee;
  border: 1px solid #2E2E2E;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.summary-footer{
  border-top: 1px solid #2E2E2E;
  border-bottom: 1px solid #2E2E2E;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table-header{
  background-color: #eeeeee;
}
</style>