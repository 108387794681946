<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>Operating Hour</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row v-if="loading">
                <mdb-col col="12" class="d-flex flex-center">
                    <mdb-spinner />
                </mdb-col>
            </mdb-row>
            <mdb-row v-for="(day, index) in operating_hours" class="mb-3" :key="day.id">
                <mdb-col col="6">
                    {{day.day}}
                </mdb-col>
                <mdb-col col="6">
                    <mdb-row>
                        <mdb-col col="12">
                            <div class="custom-control custom-switch" @click="toggleAvailable(day, index)">
                                <input type="checkbox" class="custom-control-input" v-model="day.is_available">
                                <label class="custom-control-label">Available</label>
                            </div>
                        </mdb-col>
                        <mdb-col col="6">
                            <mdb-time-picker
                                placeholder="From"
                                :hoursFormat="24"
                                label="From"
                                v-model="day.from"
                            />
                        </mdb-col>
                        <mdb-col col="6">
                            <mdb-time-picker
                                placeholder="To"
                                :hoursFormat="24"
                                label="To"
                                v-model="day.to"
                            />
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="action_loading" class="p-0 m-0" small color="blue"/>
        <mdb-btn color="primary" v-if="!action_loading" @click="updateOperatingHour">Update</mdb-btn>
        <mdb-btn color="secondary" v-if="!action_loading" @click.native="hideModal">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol, mdbSpinner, mdbTimePicker } from 'mdbvue';
  import {ajax} from '../js/ajax';

  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn, mdbContainer, mdbRow, mdbCol,
      mdbSpinner, mdbTimePicker
    },
    props: ['visible', 'close', 'merchant_id'],
    data: ()=>({
        loading:true,
        operating_hours: [],
        action_loading:false,
    }),
    methods: {
        getData(){
            ajax({
                url: "admin/get_operating_hour",
                data: {
                    id: this.merchant_id,
                },
                complete: ()=>this.loading=false,
                success: result=>{
                    this.operating_hours=result.data;
                },
            })
        },
        componentDidMount(){
            this.getData();
        },
        updateOperatingHour(){
            if(this.action_loading){
                return false;
            }
            this.action_loading=true;
            let pass=true;
            this.operating_hours.forEach(element => {
                if(!element.from || !element.to){
                    pass=false;
                }
            });
            if(!pass){
                this.$notify.error({message: "Time Cannot be null", position: 'top right', timeOut: 5000});
                this.action_loading=false;
                return false;
            }
            ajax({
                url: "admin/update_operating_hour",
                data: {
                    operating_hour: this.operating_hours,
                },
                complete: ()=>this.action_loading=false,
                success: ()=>{
                    this.$notify.success({message: 'Operating Hours has been updated.', position:'top right', timeOut: 5000});
                    this.close();
                    
                },
            })
        },
        hideModal(){
            this.operating_hours=[];
            this.loading=true;
            this.close();
        },
        toggleAvailable(day, index){
            let temp=[...this.operating_hours];
            temp[index].is_available=temp[index].is_available==0?1:0;
            this.operating_hours=temp;
        }
    },
  };
</script>