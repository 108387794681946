<template>
  <div>
    <mdb-modal size="lg" :show="visible" @shown="componentDidMount" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>{{this.merchant?this.merchant.name:""}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
            <mdb-row v-if="loading">
                <mdb-col col="12" class="d-flex flex-center">
                    <mdb-spinner />
                </mdb-col>
            </mdb-row>
            <mdb-container v-if="this.merchant">
                <mdb-row class="mb-3">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                                <div class="d-flex flex-center" style="width: 100%; height: 220px">
                                    <mdb-view :src="getAvatar(this.merchant.avatar)" alt="image" style="height: 200px; width: 400px;">
                                        <a @click="openFileSelect">
                                            <mdb-mask overlay="white-slight"></mdb-mask>
                                        </a>
                                        <input multiple type="file" id="file-upload" @change="avatarUpload" hidden />
                                    </mdb-view>
                                </div>
                                <span class="text-danger">720*360</span>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Auth Information</h4>
                                <mdb-row>
                                    <mdb-col md="6">
                                        <mdb-input disabled label="Username" v-model="merchant.user.username" size="sm" />
                                    </mdb-col>
                                    <mdb-col md="6">
                                        <mdb-input disabled label="Last Login" v-model="merchant.user.last_login" size="sm" />
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3" v-if="checkPermissions(4194304)">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                                <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Profile Information</h4>
                                <mdb-row>
                                    <mdb-col md="6">
                                        <mdb-input label="Name" v-model="merchant.name" size="sm" />
                                        <mdb-input type="number" label="Contact Number" v-model="merchant.contact_number" size="sm" />
                                        <mdb-input type="number" label="Alt Contact Number" v-model="merchant.alt_contact_number" size="sm" />
                                        <mdb-input label="Description" v-model="merchant.description" size="sm" />
                                        <mdb-input v-model="merchant.company_registration_name" label="Company Registration Name / Owner Name" size="sm" />
                                        <mdb-input v-model="merchant.company_registration_id" label="Company Registration ID / Ownder IC" size="sm" />
                                    </mdb-col>
                                    <mdb-col md="6">
                                        <mdb-input label="Email" v-model="merchant.email" size="sm" />
                                        <mdb-input label="Address" v-model="merchant.address" size="sm" />
                                        <mdb-input label="Website" v-model="merchant.website" size="sm" />
                                        <mdb-input label="Tax ID" v-model="merchant.tax_id" size="sm" />
                                        <mdb-input type="number" v-model="merchant.prepare_time" label="Prepare Time" size="sm" />
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3" v-if="checkPermissions(8388608)">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                                <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Financial Information</h4>
                                <mdb-row>
                                    <mdb-col md="6">
                                        <label style="font-size: 0.75rem; margin-bottom: 0px; color: #757575">Bank Name</label>
                                        <select v-model="merchant.bank_name" class="custom-select custom-select">
                                            <option value="08-AmBank Berhad">08-AmBank Berhad</option>
                                            <option value="12-Alliance Bank Berhad">12-Alliance Bank Berhad</option>
                                            <option value="53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad">53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad</option>
                                            <option value="32-Affin Bank Berhad">32-Affin Bank Berhad</option>
                                            <option value="49-Agro Bank">49-Agro Bank</option>
                                            <option value="42-Bank of China (Malaysia) Berhad">42-Bank of China (Malaysia) Berhad</option>
                                            <option value="41-Bank Muamalat Malaysia Berhad">41-Bank Muamalat Malaysia Berhad</option>
                                            <option value="45-Bank Islam Malaysia Berhad">45-Bank Islam Malaysia Berhad</option>
                                            <option value="02-Bank Rakyat Berhad">02-Bank Rakyat Berhad</option>
                                            <option value="10-Bank Simpanan Nasional Berhad">10-Bank Simpanan Nasional Berhad</option>
                                            <option value="07-Bank of America">07-Bank of America</option>
                                            <option value="52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad">52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad</option>
                                            <option value="60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)">60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)</option>
                                            <option value="35-CIMB Bank Berhad">35-CIMB Bank Berhad</option>
                                            <option value="17-Citibank">17-Citibank</option>
                                            <option value="19-Deutsche Bank (M) Bhd">19-Deutsche Bank (M) Bhd</option>
                                            <option value="24-Hong Leong Bank Berhad/ Hong Leong Finance">24-Hong Leong Bank Berhad/ Hong Leong Finance</option>
                                            <option value="22-HSBC Bank Berhad">22-HSBC Bank Berhad</option>
                                            <option value="59-Indust & Comm Bank of China (M) Berhad">59-Indust & Comm Bank of China (M) Berhad</option>
                                            <option value="48-JP Morgan Chase">48-JP Morgan Chase</option>
                                            <option value="47-Kuwait Finance House">47-Kuwait Finance House</option>
                                            <option value="27-Malayan Banking Berhad">27-Malayan Banking Berhad</option>
                                            <option value="73-Mizuho Bank (M) Berhad">73-Mizuho Bank (M) Berhad</option>
                                            <option value="29-OCBC Bank Berhad">29-OCBC Bank Berhad</option>
                                            <option value="33-Public Bank Berhad/ Public Finance Berhad">33-Public Bank Berhad/ Public Finance Berhad</option>
                                            <option value="18-RHB Bank Berhad">18-RHB Bank Berhad</option>
                                            <option value="14-Standard Chartered Bank Berhad">14-Standard Chartered Bank Berhad</option>
                                            <option value="51-Sumitomo Mitsui Banking Corporation (M) Bhd ">51-Sumitomo Mitsui Banking Corporation (M) Bhd </option>
                                            <option value="46-The Royal Bank of Scotland Bhd">46-The Royal Bank of Scotland Bhd</option>
                                            <option value="26-United Overseas Bank (M) Bhd">26-United Overseas Bank (M) Bhd</option>
                                        </select>
                                        <mdb-input v-model="merchant.registration_id" label="Registration ID  (without dash, all capital letter)" size="sm" />
                                        <mdb-input type="number" v-model="merchant.commission" label="Commission" size="sm" />
                                    </mdb-col>
                                    <mdb-col md="6">
                                        <mdb-input v-model="merchant.bank_account_name" label="Bank Beneficial Name" size="sm" />
                                        <mdb-input v-model="merchant.bank_account_number" label="Bank Account Number" size="sm" />
                                        <mdb-input disabled v-model="merchant.subsidy" label="Subsidy" size="sm" />
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3" v-if="checkPermissions(16777216)">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                                <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Contact Information</h4>
                                <mdb-row>
                                    <mdb-col md="6">
                                        <mdb-input v-model="merchant.owner_name" label="Owner Name" size="sm" />
                                        <mdb-input v-model="merchant.owner_ic" label="Owner IC" size="sm" />
                                        <mdb-input v-model="merchant.pic_name" label="PIC Name" size="sm" />
                                        <mdb-input v-model="merchant.pic_ic" label="PIC IC" size="sm" />
                                        <mdb-input v-model="merchant.financial_name" label="Finance Name" size="sm" />
                                        <mdb-input v-model="merchant.financial_ic" label="Finance IC" size="sm" />
                                    </mdb-col>
                                    <mdb-col md="6">
                                        <mdb-input v-model="merchant.owner_mobile" label="Owner Mobile" size="sm" />
                                        <mdb-input v-model="merchant.owner_email" label="Owner Email" size="sm" />
                                        <mdb-input v-model="merchant.pic_mobile" label="PIC Mobile" size="sm" />
                                        <mdb-input v-model="merchant.pic_email" label="PIC Email" size="sm" />
                                        <mdb-input v-model="merchant.financial_mobile" label="Finance Mobile" size="sm" />
                                        <mdb-input v-model="merchant.financial_email" label="Finance Email" size="sm" />
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3" v-if="checkPermissions(33554432)">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                                <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Extra Information</h4>
                                <mdb-row>
                                    <mdb-col md="4">
                                        <div class="d-flex flex-column">
                                            <label style="font-size: 0.75rem; margin-bottom: 0px; color: #757575">Halal Cert</label>
                                            <select v-model="merchant.is_halal_cert" class="custom-select custom-select">
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select>
                                        </div>
                                    </mdb-col>
                                    <mdb-col md="4">
                                        <mdb-input v-model="merchant.terminal_serial_number" label="Terminal Serial No." size="sm" />
                                    </mdb-col>
                                    <mdb-col md="4">
                                        <mdb-input v-model="merchant.sim_serial_number" label="Sim Serial No." size="sm" />
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3" v-if="checkPermissions(67108864)">
                    <mdb-col>
                        <mdb-card>
                            <mdb-card-body>
                                <mdb-row>
                                    <mdb-col md="12">
                                        <div id="map" />
                                        <img :src="require('@/assets/map_marker.png')" id="marker-center" />
                                        <mdb-btn size="sm" @click="updateGeolocation" color="primary">Update Geolocation</mdb-btn>
                                    </mdb-col>
                                </mdb-row>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                </mdb-row>
            </mdb-container>    
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="action_loading" class="p-0 m-0" small color="blue"/>
        <mdb-btn color="danger" v-if="!action_loading && checkPermissions(134217728)" @click="resetPassword">Reset Password</mdb-btn>
        <mdb-btn color="danger" v-if="!action_loading && checkPermissions(268435456)" @click="resetPin">Reset Pin</mdb-btn>
        <mdb-btn color="primary" v-if="!action_loading && checkPermissions(4194304)" @click="updateMerchant">Update</mdb-btn>
        <mdb-btn color="secondary" v-if="!action_loading" @click.native="hideModal">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <ConfirmationModal :visible="confirmation_modal" :title="action_type=='PASSWORD'?'Reset Password?':'Reset Pin?'" :close="closeModal" :submit="actionReset" />
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbCard, mdbCardBody, mdbRow, mdbCol,
  mdbContainer, mdbView, mdbMask, mdbInput, mdbSpinner } from 'mdbvue';
  import {APP_FILE_URL} from '../js/config';
  import {ajax, fileUploadAjax} from '../js/ajax';
  import {getColor} from '../js/colors';
  import {checkPermissions} from '../js/helper';
  import ConfirmationModal from '../components/ConfirmationModal';
  import {validateSpecialCharacter} from '../js/validation';

  export default {
    components: {
      mdbModal,
      mdbModalHeader, mdbSpinner,
      mdbModalTitle, mdbInput,
      mdbModalBody, mdbView, mdbMask, ConfirmationModal,
      mdbModalFooter, mdbContainer,
      mdbBtn, mdbCard, mdbCardBody, mdbRow, mdbCol
    },
    props: ['visible', 'close', 'focus_merchant'],
    data: ()=>({
        map: null,
        action_loading: false,
        merchant: null,
        loading:true,
        action_type: "",
        confirmation_modal:false,
    }),
    methods: {
        componentDidMount(){
            this.merchant=this.focus_merchant;  
            this.loading=false;
            setTimeout(()=>this.renderMap(), 800);
        },
        hideModal(){
            this.loading=true;
            this.map=null;
            this.merchant=null;
            this.action_loading=false;
            this.close();
        },
        getAvatar(avatar){
            if(!avatar){
                return require('@/assets/placeholder_empty.jpg');
            }
            return APP_FILE_URL+avatar;
        },
        renderMap(){
            if(!this.checkPermissions(67108864)){
                return false;
            }
            const google = window.google;
            if(!this.map){
                var mapProp = {
                    center:new google.maps.LatLng(this.merchant.lat,this.merchant.lng),
                    zoom:16,
                };
                this.map=new google.maps.Map(document.getElementById("map"),mapProp);
            }
        },
        updateGeolocation(){
            this.merchant.lat=this.map.getCenter().lat();
            this.merchant.lng=this.map.getCenter().lng();
            this.$notify.success({message: 'Geolocation set. Please click update profile.', position:'top right', timeOut: 5000});
        },
        updateMerchant(){
            if(validateSpecialCharacter(this.merchant.registration_id)){
                this.$notify.error({message: 'Registration ID cannot consists Special Characters', position:'top right', timeOut: 5000});
                return false;
            }
            if(this.action_loading){
                return false;
            }
            this.action_loading=true;
            ajax({
                url: "admin/update_merchant_profile",
                data: {...this.merchant},
                complete: ()=>this.action_loading=false,
                success: ()=>{
                    this.$notify.success({message: this.merchant.name+' has been updated.', position:'top right', timeOut: 5000});
                    this.close();
                },
            }, this)
        },
        avatarUpload(event){
            fileUploadAjax({
                url: "admin/update_merchant_avatar",
                data: {event: event.target.files, id: this.merchant.id},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    this.$notify.success({message: this.merchant.name+' profile image has been updated.', position:'top right', timeOut: 5000});
                    this.merchant.avatar=result.data.avatar;
                },
                isShowAlert: true
            }, this)
        },
        openFileSelect(){
            if(!this.checkPermissions(4194304)){
                this.$notify.error({message: 'Access Denied.', position:'top right', timeOut: 5000});
                return false;
            }
            document.getElementById("file-upload").click();
        },
        getColor(text){
            return getColor(text);
        },
        checkPermissions(permission){
            return checkPermissions(permission, this.$store.state.permissions);
        },
        resetPassword(){
            this.confirmation_modal=true;
            this.action_type="PASSWORD";
        },
        resetPin(){
            this.confirmation_modal=true;
            this.action_type="PIN";
        },
        closeModal(){
            this.confirmation_modal=false;
        },
        actionReset(){
            if(this.action_loading){
                return false;
            }
            this.action_loading=true;
            ajax({
                url: this.action_type=="PASSWORD"?"admin/reset_merchant_password":"admin/reset_merchant_pin",
                data: {id: this.action_type=="PASSWORD"?this.focus_merchant.user.id:this.focus_merchant.id},
                complete: ()=>this.action_loading=false,
                success: result=>{
                    this.action_type=="PASSWORD"?
                    this.$notify.success({message: this.merchant.name+' password changed to '+result.data, position:'top right', timeOut: 5000}):
                    this.$notify.success({message: this.merchant.name+' pin changed to '+result.data, position:'top right', timeOut: 5000})
                },
            }, this)
        }
    }
  };
</script>

<style scoped>
#map{
    height: 400px;
    width: 100%;
}
#marker-center{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    width: 30px;
    margin-left:-15px;
    margin-top:-30px;
    z-index: 1000;
}
</style>