<template>
  <div>
    <mdb-modal scrollable size="lg" @shown="componentDidMount" :show="visible" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>Order Detail {{this.order?this.order.order_number:""}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-container>
            <mdb-row v-if="loading">
                <mdb-col col="12" class="d-flex flex-center">
                    <mdb-spinner />
                </mdb-col>
            </mdb-row>
            <mdb-row  v-if="!loading" class="mb-3">
                <mdb-col md="6">
                    <mdb-card>
                        <mdb-card-body>
                            <div style="border-bottom: 1px solid grey">Pick Up</div>
                            <div class="text-primary">{{this.order?this.order.pickup.name:""}}</div>
                            <div class="small">{{this.order?this.order.pickup.address:""}}</div>
                            <div class="small">Numbering: {{this.order?this.order.pickup.remarks:""}}</div>
                            <div class="small" v-if="this.order && this.order.service_id==1">{{this.order?this.order.merchant.contact_number:""}}</div>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Google Map</span>
                                <a slot="reference" target="_blank" :href="`https://www.google.com/maps?q=${this.order.pickup.lat},${this.order.pickup.lng}`">
                                    <i class="far fa-map"></i> Open Map
                                </a>
                            </mdb-tooltip>
                            <!-- <textarea id="txt-pickup-textarea" class="copy-clipboard" v-model="textarea_pickup" />
                            
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Copy Clipboard</span>
                                <a slot="reference">
                                    <mdb-btn size="sm" color="primary" @click="copyClipboard('pickup')">
                                        <i class="far fa-clone"></i>
                                    </mdb-btn>      
                                </a>
                            </mdb-tooltip> -->
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
                <mdb-col md="6">
                    <mdb-card>
                        <mdb-card-body>
                            <div style="border-bottom: 1px solid grey">Deliver</div>
                            <div class="text-success">{{this.order?this.order.deliver.name:""}}</div>
                            <div class="small">{{this.order?this.order.deliver.address:""}}</div>
                            <div class="small">Unit/Tingkat: {{this.order?this.order.deliver.remarks:""}}</div>
                            <div class="small">Note to Rider: {{this.order?this.order.note_customer:""}}</div>
                            <div class="small">{{this.order?this.order.customer_user.phone_number:""}}</div>
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Google Map</span>
                                <a slot="reference" target="_blank" :href="`https://www.google.com/maps?q=${this.order.deliver.lat},${this.order.deliver.lng}`">
                                    <i class="far fa-map"></i> Open Map
                                </a>
                            </mdb-tooltip>
                            <!-- <textarea id="txt-deliver-textarea" class="copy-clipboard" v-model="textarea_deliver" />
                            <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                                <span slot="tip">Copy Clipboard</span>
                                <a slot="reference">
                                    <mdb-btn size="sm" color="primary" @click="copyClipboard('deliver')">
                                        <i class="far fa-clone"></i>
                                    </mdb-btn>
                                </a>
                            </mdb-tooltip> -->
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row  v-if="!loading" class="mb-3">
                <mdb-col md="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Order Information</h4>
                            <mdb-container fluid>
                                <mdb-row class="py-1" v-for="item in order.items" style="border-bottom: 1px solid grey" :key="item.id">
                                    <mdb-col col="6" class="text">
                                        <div>
                                            {{item.product.category.name}}
                                        </div>
                                        <div class="font-weight-bold">
                                            <span v-if="order.service_id==2" :class="{'text-danger': item.order.is_cancelled==1, 'text-success': item.order.is_accepted==1}">{{item.product.merchant.name}} - </span>{{item.name}}
                                        </div>
                                        <div style="font-size: 0.75rem">
                                            {{item.product.description}}
                                        </div>
                                        <div v-for="variety in item.std_variety" :key="variety.id">
                                            {{variety.name}}: <span v-for="option in variety.options" :key="option.name">
                                                <span v-if="option.checked">{{option.name}} ({{parseFloat(option.price).toFixed(2)}})</span>
                                            </span>
                                        </div>
                                        <div v-if="item.remarks">Remarks: {{item.remarks}}</div>
                                    </mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        <div class="text">{{item.quantity}} items</div>
                                        <div class="text">RM {{item.service_charge_node.amount}}</div>
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="py-1" style="border-bottom: 1px solid grey">
                                    <mdb-col col="6" class="text font-weight-bold">Total Items</mdb-col>
                                    <mdb-col col="6" class="text-right font-weight-bold">
                                        {{this.item_quantity}}
                                    </mdb-col>
                                </mdb-row>
                            </mdb-container>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row  v-if="!loading" class="mb-3">
                <mdb-col md="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Payment Information</h4>
                            <mdb-container fluid>
                                <mdb-row class="py-1">
                                    <mdb-col col="6" class="text">Subtotal</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        RM {{parsePrice(this.item_price)}}
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="py-1">
                                    <mdb-col col="6" class="text">Tax</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        RM {{parsePrice(this.order.service_charge.tax_amount)}}
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="py-1">
                                    <mdb-col col="6" class="text">Delivery Fees</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        RM {{parsePrice(this.order.deliver.service_charge_node.amount)}}
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="py-1">
                                    <mdb-col col="6" class="text">Combine Fees</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        RM {{parsePrice(this.order.service_charge.service_fee)}}
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="py-1">
                                    <mdb-col col="6" class="text">Promo Discount ({{this.order.service_charge.promo_code?this.order.service_charge.promo_code.promo_code_config.code:""}})</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        RM {{parsePrice(this.order.service_charge.promo_code_paid)}}
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row v-if="this.order.small_order_fee" class="py-1">
                                    <mdb-col col="6" class="text">Small Order Fee</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        RM {{parsePrice(this.order.small_order_fee.service_charge_node.amount)}}
                                    </mdb-col>
                                </mdb-row>
                                <mdb-row class="py-1" style="border-top: 1px solid #2E2E2E; border-bottom: 1px solid #2E2E2E">
                                    <mdb-col col="6" class="text font-weight-bold">Total</mdb-col>
                                    <mdb-col col="6" class="text-right font-weight-bold">
                                        RM {{parsePrice(this.order.service_charge.grand_total)}}
                                    </mdb-col>
                                </mdb-row>
                            </mdb-container>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row  v-if="!loading && order.service_order_event.length!=0" class="mb-3">
                <mdb-col md="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Event Information</h4>
                            <mdb-container fluid>
                                <mdb-row v-for="event in order.service_order_event" v-bind:key="event.id">
                                    <mdb-col col="4">
                                        {{event.code_type}}
                                    </mdb-col>
                                    <mdb-col col="4">
                                        {{event.description}}
                                    </mdb-col>
                                    <mdb-col col="4">
                                        {{momentFormat16(event.created_at)}}
                                    </mdb-col>
                                </mdb-row>
                            </mdb-container>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row  v-if="!loading" class="mb-3">
                <mdb-col md="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Promo Code (User Binded)</h4>
                            <mdb-container fluid>
                                <mdb-row>
                                    <mdb-col col="8">
                                        <select v-model="selected_promo_code" class="browser-default custom-select">
                                            <option value="" selected disabled>Select Promo Code</option>
                                            <option v-for="promo in promo_codes" v-bind:key="promo.id" :value="promo.id">{{promo.code}}</option>
                                        </select>
                                    </mdb-col>
                                    <mdb-col col="4">
                                        <mdb-btn v-if="!promo_code_loading" @click="submitPromoCode" color="primary" size="sm">
                                            Submit
                                        </mdb-btn>
                                        <mdb-spinner v-if="promo_code_loading" />
                                    </mdb-col>
                                </mdb-row>
                            </mdb-container>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row  v-if="!loading" class="mb-3">
                <mdb-col md="12">
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Admin Remarks</h4>
                            <div v-html="order.comment_admin" style="white-space: pre-wrap;"><br/></div>
                            <mdb-input outline type="textarea" v-model="admin_comment" :rows="2" />
                            <mdb-btn color="success" size="sm" @click="updateRemarks">Update Remarks</mdb-btn>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <!-- <mdb-row v-if="!loading">
                <mdb-col md="12">
                    <mdb-card>
                        <mdb-card-body>
                            <div id="map"></div>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row> -->
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="info" v-if="checkAcceptOrder()" @click.native="acceptOrder()">Accept Order</mdb-btn>
        <mdb-btn color="danger" v-if="checkCancelOrder()" @click.native="showConfirmationModal()">Cancel Order</mdb-btn>
        <mdb-btn color="secondary" @click.native="hideModal">Close</mdb-btn>
      </mdb-modal-footer>
      <ConfirmationModal :title="'Are you sure you want to cancel order?'" :visible="show_confirmation_modal" :close="closeConfirmationModal" :submit="cancelOrder" />
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol, mdbSpinner,
  mdbCard, mdbCardBody, mdbInput, mdbTooltip } from 'mdbvue';
  import {ajax} from '../js/ajax';
  import {momentFormat16, checkPermissions} from '../js/helper';
  import {getColor} from '../js/colors';
  import ConfirmationModal from '../components/ConfirmationModal';

  export default {
    components: {
      mdbModal, mdbInput, mdbTooltip,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn, mdbContainer, mdbRow, mdbCol,
      mdbSpinner, mdbCard, mdbCardBody, ConfirmationModal
    },
    props: ['visible', 'close', "id", 'callback', 'promo_codes'],
    data: ()=>({
        loading: true,
        order: null,
        item_quantity: 0,
        item_price: 0,
        map: null,
        admin_comment: "",
        show_confirmation_modal: false,
        textarea_pickup:"",
        textarea_deliver: "",
        selected_promo_code: "",
        promo_code_loading:false,
        order_merchants: [] 
    }),
    methods: {
        componentDidMount(){
            this.order=null;
            this.loading=true;
            this.getData();
        },
        getData(){
            this.loading=true;
            this.selected_promo_code="";
            ajax({
                url: "admin/get_order_detail",
                data: {
                    id:this.id
                },
                complete: ()=>this.loading=false,
                success: result=>{
                    this.item_quantity=0;
                    this.item_price=0;
                    result.data.items.forEach(element => {
                        this.item_quantity+=parseFloat(element.quantity);
                        this.item_price+=parseFloat(element.service_charge_node.amount);
                        element.std_variety=JSON.parse(element.variety);
                        element.order=result.data.virtual_order_merchants.find(order=>{
                            return element.partner_user_id==order.merchant_id;
                        })
                    });
                    this.order=result.data;
                    // this.order_merchants=result.data.virtual_order_merchants;
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        acceptOrder(){
            ajax({
                url: "admin/accept_merchant_order",
                data: {
                    id:this.order.id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.getData();
                    this.callback?this.callback():null;
                    this.$notify.success({message: "Order Accepted", position: 'top right', timeOut: 5000})
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        cancelOrder(){
            ajax({
                url: "admin/cancel_order",
                data: {
                    id:this.order.id
                },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.getData();
                    this.callback?this.callback():null;
                    this.$notify.success({message: "Order Cancelled", position: 'top right', timeOut: 5000})
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        hideModal(){
            this.order=null;
            this.loading=true;            
            this.close();
        },
        parsePrice(price){
            return parseFloat(price).toFixed(2);
        },
        renderMap(pickup, deliver){
            const google = window.google;
            var mapProp = {
                center:new google.maps.LatLng(3.0764785,101.4828676),
                zoom:12,
            };
            this.map=new google.maps.Map(document.getElementById("map"),mapProp);
            let pickup_point = new google.maps.LatLng(pickup.lat,pickup.lng);
            var pickup_marker_image = {
                url: require('@/assets/restaurant_marker.png'),
                scaledSize: new google.maps.Size(25, 25)
            };
            var pickup_marker = new google.maps.Marker({
                position: pickup_point,
                title:pickup.name,
                icon: pickup_marker_image
            });
            let destination = new google.maps.LatLng(deliver.lat,deliver.lng);
            var destination_marker_image = {
                url: require('@/assets/house_marker.png'),
                scaledSize: new google.maps.Size(25, 25)
            };
            var destination_marker = new google.maps.Marker({
                position: destination,
                title:deliver.name,
                icon:destination_marker_image
            });

            if(this.order.partner_online && this.order.status=="NEW"){
                let partner_location = new google.maps.LatLng(this.order.partner_online.lat,this.order.partner_online.lng);
                var partner_marker_image = {
                    url: require('@/assets/rider_marker.png'),
                    scaledSize: new google.maps.Size(25, 25)
                };
                var partner_marker = new google.maps.Marker({
                    position: partner_location,
                    title: this.order.partner_user.name,
                    icon:partner_marker_image
                });
                partner_marker.setMap(this.map);
            }

            if(this.order.service_order_event){
                this.order.service_order_event.forEach(element => {
                    if(element.code_type=="PARTNER_LOCATION"||element.code_type=="PICKUP"||element.code_type=="DELIVER"){
                        let location = new google.maps.LatLng(element.lat,element.lng);
                        let img='';
                        let description='';
                        switch(element.code_type){
                            case "PARTNER_LOCATION" : {
                                img=require('@/assets/received.png');
                                description="Accept: "+momentFormat16(element.created_at);
                                break;
                            }
                            case "PICKUP" : {
                                img=require('@/assets/pickup.png');
                                description="Pickup: "+momentFormat16(element.created_at);
                                break;
                            }
                            case "DELIVER" : {
                                img=require('@/assets/deliver.png');
                                description="Deliver: "+momentFormat16(element.created_at);
                                break;
                            }
                        }
                        var marker_image = {
                            url: img,
                            scaledSize: new google.maps.Size(25, 25)
                        };
                        var marker = new google.maps.Marker({
                            position: location,
                            title: description,
                            icon:marker_image
                        });
                        marker.setMap(this.map);
                    }
                    return;
                });
            }
            
            destination_marker.setMap(this.map);
            pickup_marker.setMap(this.map);
            let bounds = new google.maps.LatLngBounds(pickup_point,destination);
            this.map.fitBounds(bounds);
        },
        getColor(text){
            return getColor(text);
        },
        momentFormat16(date){
            return momentFormat16(date);
        },
        updateRemarks(){
            ajax({
                url: "admin/submit_remarks",
                data: {
                    id:this.order.id,
                    admin_comment: this.admin_comment
                },
                success: ()=>{
                    if(this.order.comment_admin){
                        this.order.comment_admin=this.order.comment_admin+'<br/>'+this.admin_comment;
                    }else{
                        this.order.comment_admin=this.admin_comment;
                    }
                    this.admin_comment="";
                    this.$notify.success({message: "Admin Remarks Updated", position: 'top right', timeOut: 5000});
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        },
        checkAcceptOrder(){
            if(!this.permissionCheck(256)){
                return false;
            }
            if(this.order && this.order.status=='NEW' && this.order.current_step=='NEW'){
                return true;
            }
            return false;
        },
        checkCancelOrder(){
            if(!this.permissionCheck(1024)){
                return false;
            }
            if(this.order && this.order.status=='NEW'){
                return true;
            }
            return false;
        },
        permissionCheck(permission){
            return checkPermissions(permission, this.$store.state.permissions);
        },
        closeConfirmationModal(){
            this.show_confirmation_modal=false;
        },
        showConfirmationModal(){
            this.show_confirmation_modal=true;
        },
        copyClipboard(type){
            var copyText=null;
            if(type=="pickup"){
                copyText = document.getElementById("txt-pickup-textarea");
            }else{
                copyText = document.getElementById("txt-deliver-textarea");
            }

            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            this.$notify.info({message: "Copied to clipboard", position: 'top right', timeOut: 5000});
        },
        submitPromoCode(){
            if(!this.permissionCheck(536870912)){
                this.$notify.error({message: "Permission Denied", position: 'top right', timeOut: 5000})
                return false;
            }
            if(this.promo_code_loading){
                return false;
            }
            this.promo_code_loading=true;
            ajax({
                url: "admin/submit_compensate_voucher",
                data: {
                    user_id:this.order.customer_user.id,
                    promo_code_id: this.selected_promo_code,
                    service_order_id: this.order.id
                },
                complete: ()=>this.promo_code_loading=false,
                success: ()=>{
                    this.$notify.success({message: "Promo Code Added", position: 'top right', timeOut: 5000})
                    this.getData();
                },
                fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
            })
        }
    }
  };
</script>

<style scoped>
#map{
    height: 400px;
    width: 100%;
}
.copy-clipboard{
    position: absolute;
    left: 999999999px;
}
</style>