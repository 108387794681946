<template>
    <AppNavigation>
        <mdb-container fluid>
            <mdb-row class="mb-3">
                <mdb-col col="1" class="d-flex flex-center">
                    <mdb-btn @click="$router.back()" size="sm" color="primary"><i class="fas fa-arrow-left"></i></mdb-btn>
                </mdb-col>
                <mdb-col col="11">
                    <h1 class="h1-responsive">Merchant Info</h1>
                </mdb-col>
            </mdb-row>
            <hr>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                      <mdb-card-body>
                          <div class="d-flex flex-center" style="width: 100%; height: 220px">
                              <mdb-view :src="getAvatar(merchant.avatar)" alt="image" style="height: 200px; width: 400px;">
                                  <a @click="openFileSelect">
                                      <mdb-mask overlay="white-slight"></mdb-mask>
                                  </a>
                                  <input multiple type="file" id="file-upload" @change="avatarUpload" hidden />
                              </mdb-view>
                          </div>
                          <span class="text-danger">720*360</span>
                      </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                      <mdb-card-body>
                      <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Auth Information</h4>
                          <mdb-row>
                              <mdb-col col="12">
                                  <mdb-input label="Login Username" v-model="merchant.username" size="sm" />
                              </mdb-col>
                          </mdb-row>
                      </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col>
                    <mdb-card>
                        <mdb-card-body>
                            <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Profile Information</h4>
                            <mdb-row>
                                <mdb-col md="6">
                                    <mdb-input label="Name" v-model="merchant.name" size="sm" />
                                    <mdb-input type="number" label="Contact Number" v-model="merchant.contact_number" size="sm" />
                                    <mdb-input type="number" label="Alt Contact Number" v-model="merchant.alt_contact_number" size="sm" />
                                    <mdb-input label="Description" v-model="merchant.description" size="sm" />
                                    <mdb-input v-model="merchant.company_registration_name" label="Company Registration Name / Owner Name" size="sm" />
                                    <mdb-input v-model="merchant.company_registration_id" label="Company Registration ID / Ownder IC" size="sm" />
                                </mdb-col>
                                <mdb-col md="6">
                                    <mdb-input label="Email" v-model="merchant.email" size="sm" />
                                    <mdb-input label="Address" v-model="merchant.address" size="sm" />
                                    <mdb-input label="Website" v-model="merchant.website" size="sm" />
                                    <mdb-input label="Tax ID" v-model="merchant.tax_id" size="sm" />
                                    <mdb-input type="number" v-model="merchant.prepare_time" label="Prepare Time" size="sm" />
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                      <mdb-card-body>
                          <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Financial Information</h4>
                          <mdb-row>
                              <mdb-col md="6">
                                  <label style="font-size: 0.75rem; margin-bottom: 0px; color: #757575">Bank Name</label>
                                  <select v-model="merchant.bank_name" class="custom-select custom-select">
                                      <option value="08-AmBank Berhad">08-AmBank Berhad</option>
                                      <option value="12-Alliance Bank Berhad">12-Alliance Bank Berhad</option>
                                      <option value="53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad">53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad</option>
                                      <option value="32-Affin Bank Berhad">32-Affin Bank Berhad</option>
                                      <option value="49-Agro Bank">49-Agro Bank</option>
                                      <option value="42-Bank of China (Malaysia) Berhad">42-Bank of China (Malaysia) Berhad</option>
                                      <option value="41-Bank Muamalat Malaysia Berhad">41-Bank Muamalat Malaysia Berhad</option>
                                      <option value="45-Bank Islam Malaysia Berhad">45-Bank Islam Malaysia Berhad</option>
                                      <option value="02-Bank Rakyat Berhad">02-Bank Rakyat Berhad</option>
                                      <option value="10-Bank Simpanan Nasional Berhad">10-Bank Simpanan Nasional Berhad</option>
                                      <option value="07-Bank of America">07-Bank of America</option>
                                      <option value="52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad">52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad</option>
                                      <option value="60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)">60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)</option>
                                      <option value="35-CIMB Bank Berhad">35-CIMB Bank Berhad</option>
                                      <option value="17-Citibank">17-Citibank</option>
                                      <option value="19-Deutsche Bank (M) Bhd">19-Deutsche Bank (M) Bhd</option>
                                      <option value="24-Hong Leong Bank Berhad/ Hong Leong Finance">24-Hong Leong Bank Berhad/ Hong Leong Finance</option>
                                      <option value="22-HSBC Bank Berhad">22-HSBC Bank Berhad</option>
                                      <option value="59-Indust & Comm Bank of China (M) Berhad">59-Indust & Comm Bank of China (M) Berhad</option>
                                      <option value="48-JP Morgan Chase">48-JP Morgan Chase</option>
                                      <option value="47-Kuwait Finance House">47-Kuwait Finance House</option>
                                      <option value="27-Malayan Banking Berhad">27-Malayan Banking Berhad</option>
                                      <option value="73-Mizuho Bank (M) Berhad">73-Mizuho Bank (M) Berhad</option>
                                      <option value="29-OCBC Bank Berhad">29-OCBC Bank Berhad</option>
                                      <option value="33-Public Bank Berhad/ Public Finance Berhad">33-Public Bank Berhad/ Public Finance Berhad</option>
                                      <option value="18-RHB Bank Berhad">18-RHB Bank Berhad</option>
                                      <option value="14-Standard Chartered Bank Berhad">14-Standard Chartered Bank Berhad</option>
                                      <option value="51-Sumitomo Mitsui Banking Corporation (M) Bhd ">51-Sumitomo Mitsui Banking Corporation (M) Bhd </option>
                                      <option value="46-The Royal Bank of Scotland Bhd">46-The Royal Bank of Scotland Bhd</option>
                                      <option value="26-United Overseas Bank (M) Bhd">26-United Overseas Bank (M) Bhd</option>
                                  </select>
                                  <mdb-input v-model="merchant.registration_id" label="Registration ID (without dash, all capital letter)" size="sm" />
                                  <mdb-input type="number" v-model="merchant.commission" label="Commission (0.28)" size="sm" />
                              </mdb-col>
                              <mdb-col md="6">
                                  <mdb-input v-model="merchant.bank_account_name" label="Bank Beneficial Name" size="sm" />
                                  <mdb-input v-model="merchant.bank_account_number" label="Bank Account Number" size="sm" />
                                  <mdb-input disabled v-model="merchant.subsidy" label="Subsidy" size="sm" />
                              </mdb-col>
                          </mdb-row>
                      </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                      <mdb-card-body>
                          <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Contact Information</h4>
                          <mdb-row>
                              <mdb-col md="6">
                                  <mdb-input v-model="merchant.owner_name" label="Owner Name" size="sm" />
                                  <mdb-input v-model="merchant.owner_ic" label="Owner IC" size="sm" />
                                  <mdb-input v-model="merchant.pic_name" label="PIC Name" size="sm" />
                                  <mdb-input v-model="merchant.pic_ic" label="PIC IC" size="sm" />
                                  <mdb-input v-model="merchant.financial_name" label="Finance Name" size="sm" />
                                  <mdb-input v-model="merchant.financial_ic" label="Finance IC" size="sm" />
                              </mdb-col>
                              <mdb-col md="6">
                                  <mdb-input v-model="merchant.owner_mobile" label="Owner Mobile" size="sm" />
                                  <mdb-input v-model="merchant.owner_email" label="Owner Email" size="sm" />
                                  <mdb-input v-model="merchant.pic_mobile" label="PIC Mobile" size="sm" />
                                  <mdb-input v-model="merchant.pic_email" label="PIC Email" size="sm" />
                                  <mdb-input v-model="merchant.financial_mobile" label="Finance Mobile" size="sm" />
                                  <mdb-input v-model="merchant.financial_email" label="Finance Email" size="sm" />
                              </mdb-col>
                          </mdb-row>
                      </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                      <mdb-card-body>
                          <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Extra Information</h4>
                          <mdb-row>
                              <mdb-col md="4">
                                  <div class="d-flex flex-column">
                                      <label style="font-size: 0.75rem; margin-bottom: 0px; color: #757575">Halal Cert</label>
                                      <select v-model="merchant.is_halal_cert" class="custom-select custom-select">
                                          <option value="0">No</option>
                                          <option value="1">Yes</option>
                                      </select>
                                  </div>
                              </mdb-col>
                              <mdb-col md="4">
                                  <mdb-input v-model="merchant.terminal_serial_number" label="Terminal Serial No." size="sm" />
                              </mdb-col>
                              <mdb-col md="4">
                                  <mdb-input v-model="merchant.sim_serial_number" label="Sim Serial No." size="sm" />
                              </mdb-col>
                          </mdb-row>
                      </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                      <mdb-card-body>
                          <mdb-row>
                              <mdb-col md="12">
                                  <div id="map" />
                                  <img :src="require('@/assets/map_marker.png')" id="marker-center" />
                              </mdb-col>
                          </mdb-row>
                          <mdb-row>
                            <mdb-col col="6">
                              <mdb-input type="number" v-model="merchant.lat" label="Lattitude" size="sm" />
                            </mdb-col>
                            <mdb-col col="6">
                              <mdb-input type="number" v-model="merchant.lng" label="Longitude" size="sm" />
                            </mdb-col>
                          </mdb-row>
                      </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col>
                  <mdb-card>
                    <mdb-card-body>
                        <mdb-row>
                            <mdb-col col="12" v-if="merchant.cover_image">
                                <mdb-view :src="getUrl(merchant.cover_image)">
                                    <a @click="openUrl(getUrl(merchant.cover_image))">
                                    <mdb-mask overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                                <p>Cover Photo</p>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col md="3" v-if="merchant.logo">
                                <mdb-view :src="getUrl(merchant.logo)">
                                    <a @click="openUrl(getUrl(merchant.logo))">
                                    <mdb-mask overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                                <p>Logo</p>
                            </mdb-col>
                            <mdb-col md="3" v-if="merchant.ssm">
                                <mdb-view :src="getUrl(merchant.ssm)">
                                    <a @click="openUrl(getUrl(merchant.ssm))">
                                    <mdb-mask overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                                <p>SSM</p>
                            </mdb-col>
                            <mdb-col md="3" v-if="merchant.ic_front">
                                <mdb-view :src="getUrl(merchant.ic_front)">
                                    <a @click="openUrl(getUrl(merchant.ic_front))">
                                    <mdb-mask overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                                <p>IC Front</p>
                            </mdb-col>
                            <mdb-col md="3" v-if="merchant.ic_back">
                                <mdb-view :src="getUrl(merchant.ic_back)">
                                    <a @click="openUrl(getUrl(merchant.ic_back))">
                                        <mdb-mask overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                                <p>IC Back</p>
                            </mdb-col>
                        </mdb-row>
                        <hr />
                        <h5 v-if="merchant.menu.length!=0">Menu</h5>
                        <mdb-row>
                            <mdb-col md="3" v-for="(menu, index) in merchant.menu" v-bind:key="index">
                                <mdb-view :src="getUrl(menu)">
                                    <a @click="openUrl(getUrl(menu))">
                                    <mdb-mask overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col col="12">
                                <mdb-input label="Remarks" disabled v-model="merchant.remarks" size="sm" />
                            </mdb-col>
                            <mdb-col col="12">
                                <mdb-input type="textarea" disabled v-model="merchant.alt_remarks" label="Waktu Operasi" :rows="7"/>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                  </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row v-if="merchant.is_approved==0 && merchant.is_deleted==0" >
                <mdb-col col="4" v-if="checkPermissionsBinary(39)">
                    <mdb-spinner v-if="loading" class="p-0 m-0" small color="blue"/>
                    <mdb-btn color="info" @click="updateMerchant" v-if="!loading">Update Merchant</mdb-btn>
                </mdb-col>
                <mdb-col col="8" class="d-flex justify-content-end align-items-end" v-if="checkPermissionsBinary(40)">
                    <mdb-btn color="primary" @click="createMerchant" v-if="!loading">Approve Merchant</mdb-btn>
                    <mdb-btn color="danger" @click="confirmRejectMerchant" v-if="!loading">Reject Merchant</mdb-btn>
                </mdb-col>
            </mdb-row>
            <mdb-row v-if="merchant.is_approved==0 && merchant.is_deleted==1" >
                <mdb-col col="12" class="d-flex justify-content-end align-items-end" v-if="checkPermissionsBinary(40)">
                    <mdb-btn color="primary" @click="restoreMerchant" v-if="!loading">Restore Merchant</mdb-btn>
                </mdb-col>
            </mdb-row>
            <ConfirmationModal :visible="confirmation_modal" :close="closeModal" :submit="rejectMerchant"/>
            <SuccessModal :visible="show_success_modal" :close="closeModal" title="Merchant Created" :text="success_modal_text" :submit="()=>$router.back()"/>    
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbBtn, mdbCard, mdbCardBody, mdbView, mdbMask, mdbInput, mdbSpinner} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax, fileUploadAjax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import {getColor} from '../js/colors';
import {validateRequired, validateEmail, validatePhone, validatePercentage, validateSpecialCharacter} from '../js/validation';
import SuccessModal from '../components/SuccessModal';
import ConfirmationModal from '../components/ConfirmationModal';
import {checkPermissionsBinary} from '../js/helper'

export default {
    name: 'view_pending_merchant',
    components: {
        mdbContainer, mdbRow, mdbCol, mdbBtn, mdbInput, mdbSpinner, ConfirmationModal,
        AppNavigation, mdbCard, mdbCardBody, mdbView, mdbMask, SuccessModal
    },
    data: ()=>({
        merchant: {
            avatar: "",
            username: "",
            name: "",
            email: "",
            lat: "3.078631",
            lng: "101.540585",
            address: "",
            company_registration_name: "",
            bank_name: "",
            bank_account_name: "",
            bank_account_number: "",
            contact_number: "",
            prepare_time: "",
            is_halal_cert: "0",
            registration_id: "",
            commission: "",
            terminal_serial_number:"",
            sim_serial_number: "",
            owner_name: "",
            owner_ic: "",
            pic_name: "",
            pic_ic: "",
            financial_name: "",
            financial_ic: "",
            owner_mobile: "",
            owner_email: "",
            pic_mobile: "",
            pic_email: "",
            financial_mobile: "",
            financial_email: "",
            subsidy: "0",
            tax_id: "",
            alt_contact_number:"",
            company_registration_id:""
        },
        loading:false,
        map:null,
        action_loading:false,
        show_success_modal:false,
        success_modal_text: "",
        confirmation_modal:false,
    }),
    methods: {
        getData(){
            if(this.loading){
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/get_merchant_approval",
                data: {
                    id: this.$route.params.id
                },
                complete: ()=>this.loading=false,
                success: result=>{
                    result.data.menu=JSON.parse(result.data.menu);
                    this.merchant={...this.merchant, ...result.data, username: result.data.email, registration_id: result.data.company_registration_id}
                },
            }, this)
        },
        getAvatar(avatar){
            if(!avatar){
                return require('@/assets/placeholder_empty.jpg');
            }
            return APP_FILE_URL+avatar;
        },
        avatarUpload(event){
            fileUploadAjax({
                url: "admin/upload_merchant_media",
                data: {event: event.target.files},
                complete: ()=>this.action_loading=false,
                success: result=>{
                this.merchant.avatar=result.data;
                },
                isShowAlert: true
            }, this)
        },
        openFileSelect(){
            document.getElementById("file-upload").click();
        },
        getColor(text){
            return getColor(text);
        },
        renderMap(){
            const google = window.google;
            if(!this.map){
                var mapProp = {
                center:new google.maps.LatLng(this.merchant.lat,this.merchant.lng),
                zoom:16,
                };
                this.map=new google.maps.Map(document.getElementById("map"), mapProp);
                google.maps.event.addListener(this.map, 'dragend', ()=>{
                    this.merchant.lat=parseFloat(this.map.getCenter().lat()).toFixed(6);
                    this.merchant.lng=parseFloat(this.map.getCenter().lng()).toFixed(6);
                });
            }
        },
        createMerchant(){
            if(this.loading){
                return false;
            }
            if(!validateEmail(this.merchant.username)){
                this.$notify.error({message: 'Invalid username format', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.name)){
                this.$notify.error({message: 'Name cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateEmail(this.merchant.email)){
                this.$notify.error({message: 'Invalid Merchant Email', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validatePhone(this.merchant.contact_number)){
                this.$notify.error({message: 'Invalid Merchant Contact Number', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.address)){
                this.$notify.error({message: 'Address cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(this.merchant.alt_contact_number && !validateRequired(this.merchant.alt_contact_number)){
                this.$notify.error({message: 'Invalid Alt Contact Number', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.company_registration_name)){
                this.$notify.error({message: 'Company Registration Name cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.prepare_time)){
                this.$notify.error({message: 'Prepare Time cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.company_registration_id)){
                this.$notify.error({message: 'Company Registration ID cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(validateSpecialCharacter(this.merchant.registration_id)){
                this.$notify.error({message: 'Registration ID cannot consists Special Characters', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.bank_name)){
                this.$notify.error({message: 'Bank Name cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.bank_account_name)){
                this.$notify.error({message: 'Bank Beneficial Name cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.merchant.bank_account_number)){
                this.$notify.error({message: 'Bank Account Number cannot be empty', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validatePercentage(this.merchant.commission)){
                this.$notify.error({message: 'Invalid Commission', position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateEmail(this.merchant.financial_email)){
                this.$notify.error({message: 'Invalid Finance Email', position:'top right', timeOut: 5000});
                return false;
            }
            if(!this.merchant.lat || !this.merchant.lng){
                this.$notify.error({message: 'Invalid LatLng', position:'top right', timeOut: 5000});
                return false;
            }
            this.loading=true;
            ajax({
                url: "admin/approve_merchant",
                data: {id: this.merchant.id},
                success: ()=>{
                    ajax({
                        url: "admin/register_merchant",
                        data: {...this.merchant},
                        complete: ()=>this.loading=false,
                        success: result=>{
                            this.show_success_modal=true;
                            this.success_modal_text=result.data;
                        },
                    }, this)
                },
            }, this)
            
        },
        confirmRejectMerchant(){
            this.confirmation_modal=true;
        },
        rejectMerchant(){
            ajax({
                url: "admin/reject_merchant",
                data: {id: this.merchant.id},
                success: ()=>{
                    this.show_success_modal=true;
                    this.success_modal_text="Merchant had been rejected";
                },
            }, this)
        },
        getUrl(url){
            if(url){
                return APP_FILE_URL+url;
            }
            return;
        },
        openUrl(url){
            if(url){
                window.open(url);
            }
            return;
        },
        closeModal(){
            this.success_modal_text=false;
            this.confirmation_modal=false;
        },
        updateMerchant(){
            ajax({
                url: "admin/update_pending_merchant",
                data: {...this.merchant},
                success: ()=>{
                    this.show_success_modal=true;
                    this.success_modal_text="Merchant has been updated";
                },
            }, this)
        },
        checkPermissionsBinary(position){
            return checkPermissionsBinary(this.$store.state.permissions, position);
        },
        restoreMerchant(){

        }
    },
    mounted: function(){
        this.getData();
        setTimeout(()=>this.renderMap(), 800);
    },
    watch: {
        merchant: {
            handler(val){
                if(this.map){
                    const google = window.google;
                    this.map.setCenter(new google.maps.LatLng(val.lat, val.lng));
                }
            },
            deep: true
        }
    },
}
</script>

<style scoped>
#map{
    height: 400px;
    width: 100%;
}
#marker-center{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    width: 30px;
    margin-left:-15px;
    margin-top:-30px;
    z-index: 1000;
}
</style>