<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>App Home</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row class="mb-3">
                <mdb-col>
                    <mdb-card>
                        <mdb-card-body>
                            <mdb-row>
                                <mdb-col col="12">
                                    <mdb-input v-model="name_bm" label="Name EN" size="sm" />
                                </mdb-col>
                                <mdb-col col="12">
                                    <mdb-input v-model="name_en" label="Name BM" size="sm" />
                                </mdb-col>
                            </mdb-row>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-spinner v-if="loading" />
        <mdb-btn v-if="!loading" @click="submitAdvertismentPopup" color="primary">{{this.id?"Edit":"Add"}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol,
  mdbInput, mdbCard, mdbCardBody, mdbSpinner } from 'mdbvue';
  import { ajax } from '../js/ajax';

  export default {
    components: {
      mdbModal, mdbSpinner,
      mdbModalHeader,
      mdbModalTitle, mdbCardBody,
      mdbModalBody, mdbCard,
      mdbModalFooter, mdbInput,
      mdbBtn,mdbContainer, mdbRow, mdbCol,
    },
    props: ['visible', 'close', "focus_app_home"],
    data: ()=>({
        name_en: "",
        name_bm: "",
        id:0,
        loading:false,
    }),
    methods: {
        closeModal(){
            this.name_bm="";
            this.name_en="";
            this.id=0;
            this.loading=false;
            this.close();
        },
        componentDidMount(){
            if(this.focus_app_home){
                this.name_bm=this.focus_app_home.name_bm;
                this.name_en=this.focus_app_home.name_en;
                this.id=this.focus_app_home.id;
            }
        },
        submitAdvertismentPopup(){
          if(this.loading){
              return false;
          }
          this.loading=true;
          ajax({
              url: "admin/submit_app_home",
              data: this.$data,
              complete: ()=>this.loading=false,
              success: ()=>{
                  this.$notify.success({message: 'App Home has been added.', position:'top right', timeOut: 5000});
                  this.close();
              },
          }, this)
        }
    }
  };
</script>