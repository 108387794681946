<template>
  <div>
    <mdb-modal size="lg" :show="visible" @close="hideModal">
      <mdb-modal-header>
        <mdb-modal-title>{{partner?partner.name:""}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row v-if="loading">
          <mdb-col col="12" class="d-flex flex-center">
            <mdb-spinner />
          </mdb-col>
        </mdb-row>
        <mdb-container v-if="partner">
          <mdb-row class="mb-3">
            <mdb-col>
              <mdb-card>
                <mdb-card-body>
                  <mdb-row>
                    <mdb-col col="12" class="d-flex flex-center">
                      <div class="avatar-image" @click="openAvatarPicker()" :style="{backgroundImage:`url('`+getAvatar()+`')`}" />
                      <input multiple type="file" id="avatar-upload" @change="avatarUpload" hidden />
                    </mdb-col>
                  </mdb-row>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
          <mdb-row class="mb-3">
            <mdb-col>
                <mdb-card>
                    <mdb-card-body>
                        <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">User Information</h4>
                        <mdb-row>
                            <mdb-col md="6">
                                <mdb-input label="Login Username Email" disabled v-model="partner.username" size="sm" />
                            </mdb-col>
                            <mdb-col md="6">
                                <mdb-input label="Code Name [ SAF-0003 ] - Amirul Nur Arieff B Ruslan" disabled v-model="partner.name" size="sm" />
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
          </mdb-row>
          <mdb-row class="mb-3">
            <mdb-col>
                <mdb-card>
                    <mdb-card-body>
                        <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Profile Information</h4>
                        <mdb-row>
                            <mdb-col md="6">
                                <mdb-input label="Fullname" v-model="partner.partner_profile.fullname" size="sm" />
                            </mdb-col>
                            <mdb-col md="6">
                                <mdb-input type="number" label="IC Number" v-model="partner.partner_profile.identification_number" size="sm" />
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col md="6">
                                <mdb-input type="number" label="Phone Number" v-model="partner.partner_profile.phone_number" size="sm" />
                            </mdb-col>
                            <mdb-col md="6">
                                <mdb-input label="Full Address" v-model="partner.partner_profile.full_address" size="sm" />
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col md="6">
                                <mdb-input label="Email Settlement" v-model="partner.partner_profile.business_email" size="sm" />
                            </mdb-col>
                            <mdb-col md="6">
                                <mdb-input label="Vehicle Registration Number" v-model="partner.partner_profile.vehicle_registration_number" size="sm" />
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col md="6">
                                <mdb-input label="Vehicle Type" v-model="partner.partner_profile.car_type" size="sm" />
                            </mdb-col>
                            <mdb-col md="6">
                                <mdb-input label="Vehicle Color" v-model="partner.partner_profile.car_color" size="sm" />
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col md="6">
                                <mdb-input type="number" label="Payout Percentage" v-model="partner.partner_profile.payout_percentage" size="sm" />
                            </mdb-col>
                            <mdb-col md="6">
                                <label style="font-size: 0.8rem; color: #757575">Partner Type</label>
                                <select v-model="partner.partner_profile.type" class="custom-select custom-select">
                                    <option value="PART">Part Time</option>
                                    <option value="FULL">Full Time</option>
                                    <option value="TEST">Tester</option>
                                </select>
                            </mdb-col>
                        </mdb-row>
                        <mdb-row>
                            <mdb-col md="6">
                              <mdb-date-picker :autoHide="true" v-model="partner.partner_profile.license_expiry_date" label="License Expiry" />
                            </mdb-col>
                            <mdb-col md="6">
                              <mdb-date-picker :autoHide="true" v-model="partner.partner_profile.roadtax_expiry_date" label="Road Tax Expiry" />
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                </mdb-card>
            </mdb-col>
          </mdb-row>
          <mdb-row class="mb-3">
            <mdb-col col="12">
              <mdb-card>
                <mdb-card-body>
                    <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Financial Information</h4>
                    <mdb-row>
                      <mdb-col md="6">
                          <mdb-input label="Bank Benefially Name" v-model="partner.partner_profile.bank_account_name" size="sm" />
                      </mdb-col>
                      <mdb-col md="6">
                          <mdb-input type="number" label="Bank Account Number" v-model="partner.partner_profile.bank_account_number" size="sm" />
                      </mdb-col>
                    </mdb-row>
                    <mdb-row>
                      <mdb-col md="6">
                          <select v-model="partner.partner_profile.bank_name" class="custom-select custom-select">\
                            <option value="" disabled>Select Bank Name</option>
                            <option value="08-AmBank Berhad">08-AmBank Berhad</option>
                            <option value="12-Alliance Bank Berhad">12-Alliance Bank Berhad</option>
                            <option value="53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad">53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad</option>
                            <option value="32-Affin Bank Berhad">32-Affin Bank Berhad</option>
                            <option value="49-Agro Bank">49-Agro Bank</option>
                            <option value="42-Bank of China (Malaysia) Berhad">42-Bank of China (Malaysia) Berhad</option>
                            <option value="41-Bank Muamalat Malaysia Berhad">41-Bank Muamalat Malaysia Berhad</option>
                            <option value="45-Bank Islam Malaysia Berhad">45-Bank Islam Malaysia Berhad</option>
                            <option value="02-Bank Rakyat Berhad">02-Bank Rakyat Berhad</option>
                            <option value="10-Bank Simpanan Nasional Berhad">10-Bank Simpanan Nasional Berhad</option>
                            <option value="07-Bank of America">07-Bank of America</option>
                            <option value="52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad">52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad</option>
                            <option value="60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)">60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)</option>
                            <option value="35-CIMB Bank Berhad">35-CIMB Bank Berhad</option>
                            <option value="17-Citibank">17-Citibank</option>
                            <option value="19-Deutsche Bank (M) Bhd">19-Deutsche Bank (M) Bhd</option>
                            <option value="24-Hong Leong Bank Berhad/ Hong Leong Finance">24-Hong Leong Bank Berhad/ Hong Leong Finance</option>
                            <option value="22-HSBC Bank Berhad">22-HSBC Bank Berhad</option>
                            <option value="59-Indust & Comm Bank of China (M) Berhad">59-Indust & Comm Bank of China (M) Berhad</option>
                            <option value="48-JP Morgan Chase">48-JP Morgan Chase</option>
                            <option value="47-Kuwait Finance House">47-Kuwait Finance House</option>
                            <option value="27-Malayan Banking Berhad">27-Malayan Banking Berhad</option>
                            <option value="73-Mizuho Bank (M) Berhad">73-Mizuho Bank (M) Berhad</option>
                            <option value="29-OCBC Bank Berhad">29-OCBC Bank Berhad</option>
                            <option value="33-Public Bank Berhad/ Public Finance Berhad">33-Public Bank Berhad/ Public Finance Berhad</option>
                            <option value="18-RHB Bank Berhad">18-RHB Bank Berhad</option>
                            <option value="14-Standard Chartered Bank Berhad">14-Standard Chartered Bank Berhad</option>
                            <option value="51-Sumitomo Mitsui Banking Corporation (M) Bhd ">51-Sumitomo Mitsui Banking Corporation (M) Bhd </option>
                            <option value="46-The Royal Bank of Scotland Bhd">46-The Royal Bank of Scotland Bhd</option>
                            <option value="26-United Overseas Bank (M) Bhd">26-United Overseas Bank (M) Bhd</option>
                          </select>
                      </mdb-col>
                    </mdb-row>
                </mdb-card-body>
              </mdb-card>
          </mdb-col>
        </mdb-row>
          <mdb-row class="mb-3">
            <mdb-col col="12">
              <mdb-card>
                <mdb-card-body>
                  <h4 class="h4-responsive" :style="{color: getColor('APP_1')}">Attachments</h4>
                  <mdb-row>
                      <mdb-col md="6" class="d-flex flex-column align-items-center">
                          <img @click="openDocumentPicker('LICENSE')" class="waves-effect img-fluid" :src="renderDocument('LICENSE')" />
                          <p>License</p>
                      </mdb-col>
                      <mdb-col md="6" class="d-flex flex-column align-items-center">
                          <img @click="openDocumentPicker('ROADTAX')" class="waves-effect img-fluid" :src="renderDocument('ROADTAX')" />
                          <p>Road Tax</p>
                      </mdb-col>
                      <input multiple type="file" id="document-upload" @change="documentUpload" hidden />
                  </mdb-row>
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
        </mdb-row>
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-spinner v-if="action_loading" class="p-0 m-0" small color="blue"/>
        <mdb-btn color="success" v-if="!action_loading" @click="updatePartner">Update</mdb-btn>
        <mdb-btn color="secondary" v-if="!action_loading" @click.native="hideModal">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbCard, mdbCardBody, mdbRow, mdbCol,
  mdbContainer, mdbInput, mdbSpinner, mdbDatePicker } from 'mdbvue';
  import {APP_FILE_URL} from '../js/config';
  import {fileUploadAjax, ajax} from '../js/ajax';
  import {getColor} from '../js/colors';
//   import {checkPermissions} from '../js/helper';
import {validateRequired, validateEmail, validateIC, validatePhone, validatePercentage} from '../js/validation';

  export default {
    components: {
      mdbModal,
      mdbModalHeader, mdbSpinner,
      mdbModalTitle, mdbInput,
      mdbModalBody, mdbDatePicker,
      mdbModalFooter, mdbContainer,
      mdbBtn, mdbCard, mdbCardBody, mdbRow, mdbCol
    },
    props: ['visible', 'close', 'partner'],
    data: ()=>({
      loading: false,
      action_loading: false,
    }),
    methods: {
      hideModal(){
        this.close();
      },
      getAvatar(){
        return APP_FILE_URL+this.partner.partner_profile.avatar;
      },
      openAvatarPicker(){
        document.getElementById("avatar-upload").click();
      },
      avatarUpload(){
        fileUploadAjax({
          url: "admin/upload_partner_avatar",
          data: {event: event.target.files},
          complete: ()=>this.action_loading=false,
          success: result=>{
            this.partner.partner_profile.avatar=result.data;
          },
          isShowAlert: true
        }, this)
      },
      getColor(color){
        return getColor(color);
      },
      renderDocument(type){
        if(type=="ROADTAX"){
            return this.partner.partner_profile.license_back?APP_FILE_URL+this.partner.partner_profile.license_back:require('@/assets/picture.png');
        }
        if(type=="LICENSE"){
            return this.partner.partner_profile.license_front?APP_FILE_URL+this.partner.partner_profile.license_front:require('@/assets/picture.png');
        }
      },
      documentUpload(){
        fileUploadAjax({
          url: "admin/upload_document",
          data: {event: event.target.files},
          complete: ()=>this.action_loading=false,
          success: result=>{
            if(this.document_type=="ROADTAX"){
              this.partner.partner_profile.license_back=result.data;
            }
            if(this.document_type=="LICENSE"){
              this.partner.partner_profile.license_front=result.data;
            }
          },
          isShowAlert: true
        }, this)
      },
      openDocumentPicker(type){
        this.document_type=type;
        document.getElementById("document-upload").click();
      },
      updatePartner(){
        if(!validateRequired(this.partner.partner_profile.avatar)){
          this.$notify.error({message: 'Please Upload Avatar', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.fullname)){
          this.$notify.error({message: 'Fullname cannot be empty', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateIC(this.partner.partner_profile.identification_number)){
          this.$notify.error({message: 'Invalid Identification Number', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validatePhone(this.partner.partner_profile.phone_number)){
          this.$notify.error({message: 'Invalid Phone Number', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.full_address)){
          this.$notify.error({message: 'Address cannot be empty', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateEmail(this.partner.partner_profile.business_email)){
          this.$notify.error({message: 'Invalid Email Settlement', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.vehicle_registration_number)){
          this.$notify.error({message: 'Vehicle registration number cannot be empty', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.car_type)){
          this.$notify.error({message: 'Vehicle type cannot be empty', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.car_color)){
          this.$notify.error({message: 'Vehicle Color cannot be empty', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validatePercentage(this.partner.partner_profile.payout_percentage)){
          this.$notify.error({message: 'Invalid Payout Percentage', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.type)){
          this.$notify.error({message: 'Please select partner type', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.license_expiry_date)){
          this.$notify.error({message: 'Please insert license expiry date', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.roadtax_expiry_date)){
          this.$notify.error({message: 'Please insert roadtax expiry date', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.bank_account_name)){
          this.$notify.error({message: 'Please insert Bank Beneficial Name', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.bank_account_number)){
          this.$notify.error({message: 'Please insert Bank Account Number', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.bank_name)){
          this.$notify.error({message: 'Please Select Bank', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.license_front)){
          this.$notify.error({message: 'Please Upload License Image', position:'top right', timeOut: 5000});
          return false;
        }
        if(!validateRequired(this.partner.partner_profile.license_back)){
          this.$notify.error({message: 'Please Upload RoadTax Image', position:'top right', timeOut: 5000});
          return false;
        }
        this.action_loading=true;
        ajax({
            url: "admin/update_partner_profile",
            data: {...this.partner.partner_profile},
            complete: ()=>this.action_loading=false,
            success: ()=>{
              this.$notify.success({message: "Rider Profile Updated", position: 'top right', timeOut: 5000})
              this.hideModal();
            },
            fail: fail=>this.$notify.error({message: fail.message, position: 'top right', timeOut: 5000})
        })
      }
    }
  };
</script>

<style scoped>
.avatar-image{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 200px;
  border-radius: 200px;
}
</style>