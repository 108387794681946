<template>
  <div>
    <mdb-modal :show="visible" @close="onClose">
      <mdb-modal-header>
        <mdb-modal-title>Partner Activity</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-tbl responsive striped>
            <mdb-tbl-head>
            <tr>
                <th width="5%">#</th>
                <th width="40%">Lat,Lng</th>
                <th width="40%">Activity</th>
                <th width="15%">DateTime</th>
            </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
            <tr v-for="(activity, index) in activities" :key="activity.id">
                <td>
                    {{index+1}}
                </td>
                <td>
                    <mdb-tooltip trigger="hover" :options="{placement: 'top'}">
                        <span slot="tip">Google Map</span>
                        <a slot="reference" target="_blank" :href="`https://www.google.com/maps?q=${activity.lat},${activity.lng}`">
                            Google Map
                        </a>
                    </mdb-tooltip>
                </td>
                <td>
                    {{activity.type}}
                </td>
                <td>
                    {{momentFormat16(activity.created_at)}}
                </td>
            </tr>
            </mdb-tbl-body>
        </mdb-tbl>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-btn @click="onClose" color="danger">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn, mdbModalBody, mdbTbl, mdbTblHead, mdbTblBody, mdbTooltip} from 'mdbvue';
  import {momentFormat16} from '../js/helper';

  export default {
    components: {
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalFooter,
      mdbBtn, mdbTbl, mdbTblHead, mdbTblBody,
      mdbModalBody, mdbTooltip
    },
    props: ['visible', 'close', 'activities'],
    methods: {
        onClose(){
          this.close();
        },
        momentFormat16(date){
            return momentFormat16(date);
        }
    },
  };
</script>

<style scoped>
</style>