import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Landing from './views/Landing.vue'
import MerchantList from './views/MerchantList.vue'
import PartnerOnline from './views/PartnerOnline.vue'
import PartnerList from './views/PartnerList.vue'
import Orders from './views/Orders.vue'
import ActiveOrders from './views/ActiveOrders.vue'
import AppVersionControl from './views/AppVersionControl.vue'
import AppMainConfig from './views/AppMainConfig.vue'
import MerchantCategoryProduct from './views/MerchantCategoryProducts.vue'
import Admins from './views/Admins.vue'
import AdminPermissions from './views/AdminPermissions'
import AdminRoles from './views/AdminRoles'
import PartnerPayout from './views/PartnerPayout'
import Users from './views/Users'
import MerchantPayout from './views/MerchantPayout'
import MerchantSettlement from './views/MerchantSettlement'
import MerchantSettlementPrint from './views/MerchantSettlementPrint'
import ZoneCoverage from './views/ZoneCoverage'
import AddPartner from './views/AddPartner'
import PartnerCollection from './views/PartnerCollection'
import AddPromotion from './views/AddPromotion'
import PromotionList from './views/PromotionList'
import AddMerchant from './views/AddMerchant'
import PendingApprovalMerchant from './views/PendingApprovalMerchants'
import ViewPendingMerchant from './views/ViewPendingMerchant'
import RefundOrders from './views/RefundOrders'
import CashbackList from './views/CashbackList'
import AddCashback from './views/AddCashback'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/landing',
      name: 'landing',
      component: Landing
    },
    {
      path: '/merchant_list',
      name: 'merchant_list',
      component: MerchantList
    },
    {
      path: '/partner_online',
      name: 'partner_online',
      component: PartnerOnline
    },
    {
      path: '/partner_list',
      name: 'partner_list',
      component: PartnerList
    },
    {
      path: '/orders',
      name: 'orders',
      component: Orders
    },
    {
      path: '/active_orders',
      name: 'active_orders',
      component: ActiveOrders
    },
    {
      path: '/app_version_control',
      name: 'app_version_control',
      component: AppVersionControl
    },
    {
      path: '/app_configuration',
      name: 'app_configuration',
      component: AppMainConfig
    },
    {
      path: '/merchant_category_products/:id',
      name: 'merchant_category_products',
      component: MerchantCategoryProduct,
      props: true
    },
    {
      path: '/admins',
      name: 'admins',
      component: Admins
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: AdminPermissions
    },
    {
      path: '/roles',
      name: 'roles',
      component: AdminRoles
    },
    {
      path: '/partner_payout',
      name: 'partner_payout',
      component: PartnerPayout
    },
    {
      path: '/users',
      name: 'users',
      component: Users
    },
    {
      path: '/merchant_payout',
      name: 'merchant_payout',
      component: MerchantPayout
    },
    {
      path: '/merchant_settlement',
      name: 'merchant_settlement',
      component: MerchantSettlement
    },
    {
      path: '/merchant_settlement_print',
      name: "merchant_settlement_print",
      component: MerchantSettlementPrint
    },
    {
      path: '/zone_coverage',
      name: "zone_coverage",
      component: ZoneCoverage
    },
    {
      path: '/add_partner',
      name: "add_partner",
      component: AddPartner
    },
    {
      path: '/partner_collection',
      name: "partner_collection",
      component: PartnerCollection
    },
    {
      path: '/add_promotion',
      name: "add_promotion",
      component: AddPromotion
    },
    {
      path: '/promotion_list',
      name: "promotion_list",
      component: PromotionList
    },
    {
      path: '/add_merchant',
      name: "add_merchant",
      component: AddMerchant
    },
    {
      path: '/pending_approve',
      name: "pending_approve",
      component: PendingApprovalMerchant
    },
    {
      path: '/view_pending_merchant/:id',
      name: "view_pending_merchant",
      component: ViewPendingMerchant
    },
    {
      path: '/refund_orders',
      name: "refund_orders",
      component: RefundOrders
    },
    {
      path: '/add_cashback',
      name: "add_cashback",
      component: AddCashback
    },
    {
      path: '/cashback_list',
      name: "cashback_list",
      component: CashbackList
    }
  ],scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
