<template>
    <AppNavigation>
        <mdb-container fluid>
          <h1 class="h1-responsive">Version Control</h1>
          <hr>
          <mdb-tbl responsive striped>
            <mdb-tbl-head>
              <tr>
                <th width="20%">App</th>
                <th width="20%">Version ID</th>
                <th width="20%">Version Name</th>
                <th width="20%">Date Time</th>
                <th width="20%">Created By</th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body>
              <tr v-for="config in configs"  :key="config.id">
                <td>{{config.type}}</td>
                <td><mdb-input v-model="config.version" label="Version" size="sm" class="m-0" /></td>
                <td><mdb-input v-model="config.version_name" label="Version" size="sm" class="m-0" /></td>
                <td>{{momentFormat16(config.created_at)}}</td>
                <td>{{config.user.name}}</td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
          <mdb-row>
            <mdb-col col="12">
              <mdb-spinner v-if="loading" />
              <mdb-btn v-if="!loading" @click="updateAppConfig" color="primary">Save Changes</mdb-btn>
            </mdb-col>
          </mdb-row>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbInput, mdbRow, mdbCol, mdbBtn, mdbSpinner } from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {momentFormat16} from '../js/helper';
// import {APP_FILE_URL} from '../js/config';

export default {
  name: 'app_version_control',
  components: {
    mdbContainer, mdbInput, mdbBtn,
    AppNavigation, mdbRow, mdbCol,
    mdbTbl, mdbTblHead, mdbTblBody, mdbSpinner
  },
  data: ()=>({
    loading:false,
    configs:[],
    services: []
  }),
  methods: {
    getData(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
        url: "admin/list_app_config",
        complete: ()=>this.loading=false,
        success: result=>{
          this.configs=result.data.configs;
          this.services=result.data.services;
        },
      }, this)
    },
    momentFormat16(date){
      return momentFormat16(date);
    },
    updateAppConfig(){
      if(this.loading){
        return false;
      }
      this.loading=true;
      ajax({
        url: "admin/update_app_config",
        data: { configs: this.configs},
        complete: ()=>this.loading=false,
        success: ()=>{
          this.$notify.success({message: 'App Config has been updated.', position:'top right', timeOut: 5000});
          this.getData();
        },
      }, this)
    }
  },
  mounted: function(){
    this.getData();
  }
}
</script>
