const time=500;

let execute=true;

export default function init(func){
    window.onscroll = function() {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            if(execute==true){
                execute=false;
                func?func():null;
                setTimeout(()=>execute=true, time)
            }
        }
    };
}