<template>
  <div>
    <mdb-modal size="lg" @show="componentDidMount" :show="visible" @close="onClose">
      <mdb-modal-header>
        <mdb-modal-title>Promo Code: <strong>{{focus_promo?focus_promo.code: ""}}</strong></mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-container>
            <mdb-row class="mb-3">
                <mdb-col md="3">
                  <mdb-input disabled v-model="promo_config.code" label="Code" size="sm" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.description" label="Description" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_config.is_global==1">
                  <mdb-input disabled v-model="promo_config.quantity" label="Quantity" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_config.is_global!=1">
                  <mdb-input v-model="promo_config.valid_days" label="Valid Days" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3" v-if="promo_config.is_global==1">
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.date_from" label="Date Start" size="sm" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.date_to" label="Date End" size="sm" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.time_from" label="Time Start" size="sm" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.time_to" label="Time End" size="sm" />
                </mdb-col>
            </mdb-row>
            <hr />
            <mdb-row>
                <mdb-col col="12">
                  Promo Code Type: <strong>{{promo_type}}</strong>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.min_spend" label="Minimum Spend" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_type=='AMOUNT'">
                  <mdb-input v-model="promo_config.amount" label="Amount" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_type=='PERCENTAGE'">
                  <mdb-input v-model="promo_config.amount_percentage" label="Percentage (0.25)" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_type=='PERCENTAGE'">
                  <mdb-input v-model="promo_config.amount_cap" label="Amount Cap" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
                <mdb-col md="3">
                  <mdb-input v-model="promo_config.merchant_percentage" label="Merchant Bear Percentage (0.00 - 1.00)" size="sm" />
                </mdb-col>
                <mdb-col md="3" class="d-block" v-if="promo_config.is_global==1">
                  <label>Frequency</label>
                  <select v-model="promo_config.frequency" class="browser-default custom-select">
                    <option value="" selected>None</option>
                    <option value="DAY">Daily</option>
                    <option value="WEEK">Weekly (Mon - Sat)</option>
                    <option value="MONTH">Month (1th to 28,29,30,31)</option>
                    <option value="YEAR">Yearly (1st Jan - 31 Dec)</option>
                  </select>
                </mdb-col>
                <mdb-col md="3" v-if="promo_config.frequency">
                  <mdb-input v-model="promo_config.frequency_count" label="Frequency Count" size="sm" />
                </mdb-col>
            </mdb-row>
            <hr />
            <mdb-row v-if="promo_config.is_merchant_based==1">
                <mdb-col md="6">
                    <mdb-input v-model="search" label="Search" size="sm" />
                </mdb-col>  
            </mdb-row>
            <mdb-row v-if="promo_config.is_merchant_based==1 && selection_merchants.length!=0" class="autocomplete-container">
                <mdb-col @click.native="addSelection(merchant)" class="autocomplete-select" md="12" v-for="merchant in selection_merchants" v-bind:key="merchant.id">
                    {{merchant.name}}
                </mdb-col>  
            </mdb-row>
            <hr />
            <mdb-row v-if="promo_config.is_merchant_based==1">  
                <mdb-col md="12" v-for="(merchant, index) in selected_merchants" v-bind:key="merchant.id">
                    <mdb-row>
                        <mdb-col col="8" class="d-flex align-items-center">{{index+1}}. {{merchant.name}}</mdb-col>
                        <mdb-col col="4" class="d-flex justify-content-end">
                            <mdb-btn @click="deleteMerchant(index)" color="danger" size="sm"><mdb-icon far icon="trash-alt" /></mdb-btn>
                        </mdb-col>
                    </mdb-row>
                </mdb-col>
            </mdb-row>           
        </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-btn @click="confirmation_modal=true" v-if="!loading" color="primary">Confirm & Submit</mdb-btn>
          <mdb-btn v-if="!loading" @click="onClose" color="danger">Close</mdb-btn>
          <mdb-spinner v-if="loading" small />
      </mdb-modal-footer>
    </mdb-modal>
    <ConfirmationModal :visible="confirmation_modal" :close="()=>confirmation_modal=false" :submit="()=>updatePromoCodeConfig()"/>
  </div>
</template>

<script>
    import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalFooter, mdbBtn, mdbModalBody, mdbContainer, mdbRow, 
    mdbCol, mdbSpinner, mdbInput, mdbIcon} from 'mdbvue';
    import {ajax} from '../js/ajax';
    import ConfirmationModal from '../components/ConfirmationModal';
    import {checkPermissionsBinary} from '../js/helper';
    import {validatePercentage, validateRequired, validateNumber} from '../js/validation';

    export default {
        components: {
        mdbModal, mdbIcon,
        mdbModalHeader,
        mdbModalTitle, mdbInput,
        mdbModalFooter,
        mdbBtn, mdbContainer, mdbRow, mdbCol,
        mdbModalBody, mdbSpinner, ConfirmationModal
        },
        props: ['visible', 'close', 'submit', 'focus_promo', 'all_merchants'],
        data: ()=>({
            loading: true,
            promo_config: {},
            promo_type: "", 
            selected_merchants: [],
            search: "",
            selection_merchants:[],
            confirmation_modal: false
        }),
        methods: {
            onClose(){
                this.close();
            },
            componentDidMount(){
                this.loading=true;
                this.promo_config={...this.focus_promo}
                if(this.focus_promo.amount_percentage>0){
                    this.promo_type="PERCENTAGE";
                }else{
                    this.promo_type="AMOUNT";
                }
                ajax({
                    url: "admin/get_promo_code_detail",
                    data: {id: this.focus_promo.id},
                    complete: ()=>this.loading=false,
                    success: result=>{
                        this.selected_merchants=result.data.merchants;
                    },
                }, this)
            },
            deleteMerchant(index){
                let selected_merchants=this.selected_merchants;
                selected_merchants.splice(index, 1);
                this.selected_merchants=selected_merchants;
            },
            addSelection(merchant){
                this.selection_merchants=[];
                let temp=[...this.selected_merchants];
                temp.push(merchant);
                this.selected_merchants=temp;
                this.search="";
            },
            updatePromoCodeConfig(){
            if(!checkPermissionsBinary(this.$store.state.permissions, 44)){
                this.$notify.error({message: "HELO! Access Denied", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.promo_config.description)){
                this.$notify.error({message: "HELO! MANA description?", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.promo_config.date_from)){
                this.$notify.error({message: "Date Start?", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.promo_config.date_to)){
                this.$notify.error({message: "Date To?", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.promo_config.time_from)){
                this.$notify.error({message: "Time Start?", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateRequired(this.promo_config.time_to)){
                this.$notify.error({message: "Time End?", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validateNumber(this.promo_config.min_spend)){
                this.$notify.error({message: "Minimum Spend must be number without decimal", position:'top right', timeOut: 5000});
                return false;
            }
            if(this.promo_type=="PERCENTAGE" && !validatePercentage(this.promo_config.amount_percentage)){
                this.$notify.error({message: "HELO! Percentage Discount Must be value between 0 to 1", position:'top right', timeOut: 5000});
                return false;
            }
            if(this.promo_type=="PERCENTAGE" && !validateNumber(this.promo_config.amount_cap)){
                this.$notify.error({message: "Amount Cap must be number without decimal", position:'top right', timeOut: 5000});
                return false;
            }
            if(!validatePercentage(this.promo_config.merchant_percentage)){
                this.$notify.error({message: "HELO! Merchant Percentage Must be value between 0 to 1", position:'top right', timeOut: 5000});
                return false;
            }
            ajax({
                url: "admin/update_promocode_config",
                data: {...this.promo_config, selected_merchants: this.selected_merchants },
                complete: ()=>this.loading=false,
                success: ()=>{
                    this.$notify.success({message: "Hi there! Promo had been updated!", position:'top right', timeOut: 5000});
                    this.close();
                },
            }, this)
            }
        },
        watch: {
            search(val){
                if(!val){
                    this.selection_merchants=[];
                    return;
                }
                var search = new RegExp(val , 'i');
                let searched_merchants = this.all_merchants.filter(merchant => search.test(merchant.name));
                this.selection_merchants=searched_merchants;
                
            }
        }
    };
</script>

<style scoped>
.lapar-title{
  color: #ffbb33;
}
.autocomplete-select{
    padding: 5px;
    cursor: pointer;

}
.autocomplete-select:hover{
    background-color: #f0bb1b;
    color: #fff;
}
.autocomplete-container{
    border: 1px solid #bdbdbd;
}
</style>