import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  state: {
    api_token: "",
    user: null,
    permissions: [],
  },
  mutations: {
    setUser(state, payload){
      let user = {
        id: payload.id,
        name: payload.name,
        username: payload.username,
        email: payload.email
      }
      state.api_token=payload.api_token;
      state.user=user;
    },
    setPermissions(state, payload){
      state.permissions=payload;
    }
  },
  actions: {
    setUser(context, payload) {
      context.commit('setUser', payload)
    },
    setPermissions(context, payload) {
      context.commit('setPermissions', payload)
    },
  }
})
