<template>
    <AppNavigation>
        <mdb-container fluid>
          <h1 class="h1-responsive">Add Promo Code</h1>
          <hr>
          <mdb-card class="mb-4">
            <mdb-card-body>
              <h4 class="lapar-title">Promo Code Type</h4>
              <mdb-form-inline>
                <mdb-input type="radio" id="global_1" name="global_type" radioValue="1" v-model="is_global" label="Global Code" />
                <mdb-input type="radio" id="global_2" name="global_type" radioValue="0" v-model="is_global" label="User Code" />
              </mdb-form-inline>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="mb-4">
            <mdb-card-body>
              <h4 class="lapar-title">Promo Code Basic Info</h4>
              <mdb-row class="mb-3">
                <mdb-col md="3">
                  <mdb-input v-model="code" label="Code" size="sm" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input v-model="description" label="Description" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="is_global==1">
                  <mdb-input v-model="quantity" label="Quantity" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="is_global==0">
                  <mdb-input v-model="valid_days" label="Valid Days" size="sm" />
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-3" v-if="is_global==1">
                <mdb-col md="3">
                  <mdb-date-picker :autoHide="true" v-model="date_from" label="Date From" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-date-picker :autoHide="true" v-model="date_to" label="Date To" />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-time-picker
                  label="Time From"
                  :hours-format="24"
                  v-model="time_from"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-time-picker
                  label="Time To"
                  :hours-format="24"
                  v-model="time_to"
                  />
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="mb-4">
            <mdb-card-body>
              <h4 class="lapar-title">Promo Code Configurations</h4>
              <mdb-row>
                <mdb-col col="2">
                  Promo Code Type
                </mdb-col>
                <mdb-col col="10">
                  <mdb-form-inline>
                    <mdb-input type="radio" id="promo_type_1" name="promo_type" radioValue="AMOUNT" v-model="promo_type" label="Amount" />
                    <mdb-input type="radio" id="promo_type_2" name="promo_type" radioValue="PERCENTAGE" v-model="promo_type" label="Percentage" />
                  </mdb-form-inline>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-3">
                <mdb-col md="3">
                  <mdb-input v-model="min_spend" label="Minimum Spend" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_type=='AMOUNT'">
                  <mdb-input v-model="amount" label="Amount" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_type=='PERCENTAGE'">
                  <mdb-input v-model="amount_percentage" label="Percentage (0.25)" size="sm" />
                </mdb-col>
                <mdb-col md="3" v-if="promo_type=='PERCENTAGE'">
                  <mdb-input v-model="amount_cap" label="Amount Cap" size="sm" />
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-3">
                <mdb-col md="3">
                  <mdb-input v-model="merchant_percentage" label="Merchant Bear Percentage (0.00 - 1.00)" size="sm" />
                </mdb-col>
                <mdb-col md="3" class="d-block" v-if="is_global==1">
                  <label>Frequency</label>
                  <select v-model="frequency" class="browser-default custom-select">
                    <option value="" selected>None</option>
                    <option value="DAY">Daily</option>
                    <option value="WEEK">Weekly (Mon - Sat)</option>
                    <option value="MONTH">Month (1th to 28,29,30,31)</option>
                    <option value="YEAR">Yearly (1st Jan - 31 Dec)</option>
                  </select>
                </mdb-col>
                <mdb-col md="3" v-if="frequency">
                  <mdb-input v-model="frequency_count" label="Frequency Count" size="sm" />
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="mb-4" v-if="is_global==1">
            <mdb-card-body>
              <h4 class="lapar-title">Merchants Involved</h4>
              <mdb-row class="mb-3">
                <mdb-col col="12">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" v-model="is_merchant_based" class="custom-control-input" id="merchant-check">
                    <label class="custom-control-label" for="merchant-check">Merchant Based</label>
                  </div>
                </mdb-col>
              </mdb-row>
              <template v-if="is_merchant_based">
                <hr />
                <mdb-row>
                  <mdb-col md="4">
                    <mdb-input v-model="search_merchant" label="Search Merchant" size="sm" />
                  </mdb-col>
                  <mdb-col md="8" class="d-block">
                    <div class="d-flex align-items-center h-100">
                      <mdb-btn size="sm" @click="showSlectedMerchants" color="secondary">Show Selected</mdb-btn>
                    </div>
                  </mdb-col>
                </mdb-row>
                <mdb-row class="mb-3">
                  <mdb-col col="4">
                      <select v-model="zone" class="browser-default custom-select">
                        <option value="" selected>All Zones</option>
                        <option :value="zone.id" selected v-for="zone in zones" v-bind:key="zone.id">{{zone.name}}</option>
                      </select>
                  </mdb-col>
                  <mdb-col col="8">
                    <mdb-btn size="sm" @click="checkAllMerchants" color="info">Select all Merchants</mdb-btn>
                    <mdb-btn size="sm" @click="clearSelectedMerchants" color="danger">Clear Merchants</mdb-btn>
                  </mdb-col>
                </mdb-row>
                <hr />
                <mdb-row class="mt-3">
                  <mdb-col class="merchant-list" md="6" v-for="merchant in display_merchants" v-bind:key="merchant.id" @click.native="toggleMerchant(merchant)">
                    <mdb-row>
                      <mdb-col col="1" class="d-flex flex-center">
                        <mdb-icon class="text-success" v-if="checkSelect(merchant)" far icon="check-square" />
                        <mdb-icon v-if="!checkSelect(merchant)" far icon="square" />
                      </mdb-col>
                      <mdb-col col="11" class="mb-1">
                        <div style="font-size: 0.8rem; font-weight: bold;">{{merchant.name}}</div>
                        <div style="font-size: 0.75rem; color: #2E2E2E">{{merchant.zone?merchant.zone.name:'No Zone Found'}}</div>
                      </mdb-col>
                    </mdb-row>
                  </mdb-col>
                </mdb-row>
              </template>
            </mdb-card-body>
          </mdb-card>
          <mdb-card v-if="is_global==0" class="mb-4">
            <mdb-card-body>
              <h4 class="lapar-title">Push Notification when Retrieve</h4>
              <mdb-row>
                <mdb-col col="12">
                  <mdb-input v-model="notification_title" label="Notification Title" size="sm" />
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col col="12">
                  <mdb-input v-model="notification_body" label="Notification Body" size="sm" />
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
          <mdb-card v-if="is_global==0" class="mb-4">
            <mdb-card-body>
              <h4 class="lapar-title">Selected Users</h4>
              <mdb-tbl responsive striped v-if="selected_users.length!=0">
                <mdb-tbl-head>
                  <tr>
                      <th width="5%">#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Count</th>
                      <th>Action</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr v-for="(user, index) in selected_users" v-bind:key="user.id">
                    <td>{{index+1}}</td>
                    <td>{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.phone_number}}</td>
                    <td>
                      <mdb-input class="m-0" v-model="user.count" label="Number of Voucher" size="sm" />
                    </td>
                    <td>
                      <mdb-btn @click="deleteUser(user)" color="danger" size="sm"><mdb-icon icon="trash" /></mdb-btn>
                    </td>
                  </tr> 
                </mdb-tbl-body>
              </mdb-tbl>
              <mdb-row>
                <mdb-col col="4">
                  <mdb-input @keydown.enter.native="searchUser()" v-model="search" label="Search User Phone Number / Name" size="sm" />
                </mdb-col>
                <mdb-col col="4" class="d-flex align-items-center">
                  <mdb-btn v-if="!search_loading" @click="searchUser" size="sm">Search</mdb-btn>
                  <mdb-spinner v-if="search_loading" small />
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
          <mdb-card v-if="is_global==0 && search_results.length!=0" class="mb-4">
            <mdb-card-body>
              <mdb-row>
                <mdb-col col="3" class="mb-3 user" v-for="user in search_results" v-bind:key="user.id">
                  <mdb-row>
                    <mdb-col col="8">
                      <div class="d-flex flex-column">
                        <div style="font-size:0.85rem; font-weight: bold">{{user.name}}</div>
                        <div style="font-size:0.85rem;">{{user.email}}</div>
                        <div style="font-size:0.85rem;">{{user.phone_number}}</div>
                      </div>
                    </mdb-col>
                    <mdb-col col="4">
                      <mdb-btn @click="addUser(user)" color="primary" size="sm"><mdb-icon far icon="plus-square" /></mdb-btn>
                    </mdb-col>
                  </mdb-row>
                </mdb-col>
              </mdb-row>
            </mdb-card-body>
          </mdb-card>
          <mdb-card class="mb-4">
            <mdb-card-body>
              <mdb-btn v-if="!loading" @click="submitPromoCodeConfig" color="primary">Submit</mdb-btn>
              <mdb-spinner v-if="loading" small />
            </mdb-card-body>
          </mdb-card>
          <mdb-modal :scrollable="true" removeBackdrop @close="show_selected_merchants_modal = false" position="right" fullHeight direction="right" :show="show_selected_merchants_modal && is_merchant_based" primary>
            <mdb-modal-header>
              <mdb-modal-title>Selected Merchant ({{this.selected_merchants.length}})</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
              <mdb-row v-for="(merchant, index) in modal_merchants" v-bind:key="merchant.id">
                <mdb-col>
                  {{index+1}}
                  {{merchant.name}}
                </mdb-col>
              </mdb-row>
            </mdb-modal-body>
          </mdb-modal>
        </mdb-container>
    </AppNavigation>
</template>

<script>
// @ is an alias to /src
import {mdbContainer, mdbRow, mdbCol, mdbInput, mdbDatePicker, mdbTimePicker, mdbFormInline, mdbCard, mdbCardBody, mdbBtn, mdbSpinner,
mdbIcon, mdbTbl, mdbTblHead, mdbTblBody, mdbModal, mdbModalHeader, mdbModalBody, mdbModalTitle} from 'mdbvue';
import AppNavigation from '../components/AppNavigation';
import {ajax} from '../js/ajax';
import {APP_FILE_URL} from '../js/config';
import {validatePercentage, validateRequired, validateNumber} from '../js/validation';

export default {
  name: 'add_promotion',
  components: {
    mdbContainer, mdbRow, mdbCol, mdbTimePicker, mdbBtn, mdbSpinner, mdbIcon,
    AppNavigation, mdbInput, mdbDatePicker, mdbFormInline, mdbCard, mdbCardBody,
    mdbTbl, mdbTblHead, mdbTblBody, mdbModal, mdbModalHeader, mdbModalBody, mdbModalTitle
  },
  data: ()=>({
    promo_type: "AMOUNT",
    is_merchant_based: false,
    code: "",
    description: "",
    quantity: 0,
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    min_spend: 0,
    amount_cap: 0,
    amount: 0,
    amount_percentage: 0,
    is_global: "1",
    loading:false,
    all_merchants: [],
    display_merchants: [],
    selected_merchants: [],
    search: "",
    search_results: [],
    selected_users: [],
    search_loading: false,
    notification_title: "",
    notification_body: "",
    valid_days: 0,
    zones: [],
    zone: "",
    frequency_count: 0,
    merchant_percentage: 0,
    frequency: "",
    show_selected_merchants_modal: false,
    modal_merchants: [],
    search_merchant: "",
    timer: null,
  }),
  methods: {
    getMerchants(){
      this.loading=true;
      ajax({
        url: "admin/merchant_selections",
        data: {zone: this.zone},
        complete: ()=>this.loading=false,
        success: result=>{
          this.display_merchants=result.data;
          if(this.all_merchants.length==0){
            this.all_merchants=result.data;
          }
        },
      }, this)
      ajax({
        url: "admin/get_zone_coverage",
        success: result=>{
          this.zones=result.data;
        },
      }, this)
    },
    getAvatar(url){
      return APP_FILE_URL+url;
    },
    toggleMerchant(merchant){
      let selected_merchants=[...this.selected_merchants];
      let modal_merchants=[...this.modal_merchants];
      let found_index = selected_merchants.findIndex(element=>{
        return element==merchant.id;
      })
      if(found_index>=0){
        //delete
        selected_merchants.splice(found_index, 1);
        modal_merchants.splice(found_index,1);
      }else{
        //add
        selected_merchants.push(merchant.id);
        modal_merchants.push(merchant);
      }
      this.selected_merchants=selected_merchants;
      this.modal_merchants=modal_merchants;
    },
    checkSelect(merchant){
      let found_index= this.selected_merchants.findIndex(element=>{
        return element==merchant.id;
      })
      return found_index>=0?true:false;
    },
    submitPromoCodeConfig(){
      // this.loading=true;
      if(!validateRequired(this.code)){
        this.$notify.error({message: "HELO! How to start without Promo CODE?", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateRequired(this.description)){
        this.$notify.error({message: "HELO! MANA description?", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.is_global==1 && !validateNumber(this.quantity)){
        this.$notify.error({message: "HELO! Quantity must be number without decimal please", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.is_global==1 && !validateRequired(this.date_from)){
        this.$notify.error({message: "Date Start?", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.is_global==1 && !validateRequired(this.date_to)){
        this.$notify.error({message: "Date To?", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.is_global==1 && !validateRequired(this.time_from)){
        this.$notify.error({message: "Time Start?", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.is_global==1 && !validateRequired(this.time_to)){
        this.$notify.error({message: "Time End?", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validateNumber(this.min_spend)){
        this.$notify.error({message: "Minimum Spend must be number without decimal", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.promo_type=="PERCENTAGE" && !validatePercentage(this.amount_percentage)){
        this.$notify.error({message: "HELO! Percentage Discount Must be value between 0 to 1", position:'top right', timeOut: 5000});
        return false;
      }
      if(this.promo_type=="PERCENTAGE" && !validateNumber(this.amount_cap)){
        this.$notify.error({message: "Amount Cap must be number without decimal", position:'top right', timeOut: 5000});
        return false;
      }
      if(!validatePercentage(this.merchant_percentage)){
        this.$notify.error({message: "HELO! Merchant Percentage Must be value between 0 to 1", position:'top right', timeOut: 5000});
        return false;
      }
      ajax({
        url: "admin/submit_promocode_config",
        data: {...this.$data},
        complete: ()=>this.loading=false,
        success: ()=>{
          this.$notify.success({message: "Promo Code Added", position:'top right', timeOut: 5000});
          this.$router.push({ name: "promotion_list" })
        },
      }, this)
    },
    searchUser(){
      this.search_loading=true;
      ajax({
        url: "admin/search_user",
        data: {search: this.search},
        complete: ()=>this.search_loading=false,
        success: result=>{
          this.search_results=result.data;
        },
      }, this)
    },
    addUser(user){
      let selected_users=[...this.selected_users];
      let found_index = selected_users.findIndex(element=>{
        return element.id==user.id;
      })
      if(found_index==-1){
        selected_users.push({...user, count: 1});
      }
      this.selected_users=selected_users;
    },
    deleteUser(user){
      let selected_users=[...this.selected_users];
      let found_index = selected_users.findIndex(element=>{
        return element.id==user.id;
      })
      if(found_index!=-1){
        selected_users.splice(found_index,1);
      }
      this.selected_users=selected_users;
    },
    checkAllMerchants(){
      this.selected_merchants=[];
      let modal_merchants=[];
      let temp=[];
      this.all_merchants.forEach(element => {
        temp.push(element.id);
        modal_merchants.push(element);
      });
      this.selected_merchants=temp;
      this.modal_merchants=modal_merchants;
    },
    clearSelectedMerchants(){
      this.selected_merchants=[];
      this.modal_merchants=[];
    },
    showSlectedMerchants(){
      this.zone="";
      let modal_merchants=[];
      this.selected_merchants.forEach(selected_id => {
        let _index= this.all_merchants.findIndex(merchant=>{
          return merchant.id==selected_id;
        })
        if(_index>=0){
          modal_merchants.push(this.all_merchants[_index]);
        }
      });
      this.show_selected_merchants_modal=true;
      this.modal_merchants=modal_merchants;
    }
  },
  mounted: function(){
    this.getMerchants();
  },
  watch: {
    is_merchant_based(){
      this.selected_merchants=[];
    },
    is_global(){
      this.search= "";
      this.search_results=[];
      this.selected_users=[];
      this.selected_merchants=[];
      this.is_merchant_based=false;
    },
    zone(){
      this.getMerchants();
    },
    search_merchant(val){
      clearTimeout(this.timer);
      if(!val){
        this.display_merchants=[];
        return false;
      }
      this.timer=setTimeout(()=>{
        var search = new RegExp(val , 'i');
        let searched_merchants = this.all_merchants.filter(merchant => search.test(merchant.name));
        this.display_merchants=searched_merchants;
      }, 1500)
    }
  }
}
</script>

<style scoped>
.selected-container{
  border: 2px solid #4285F4;
}
.user{
  border-bottom: 1px solid #eeeeee;
  padding: 5px;
}
.lapar-title{
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffbb33;
}
.merchant-list:hover{
  cursor: pointer;
  background-color: #eeeeee;
}
.merchant-list{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>