<template>
  <div>
    <mdb-modal @shown="componentDidMount" :show="visible" @close="closeModal">
      <mdb-modal-header>
        <mdb-modal-title>{{id==0?'Add Admin':'Edit Admin'}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
          <mdb-container>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-input v-model="username" label="Username" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-input v-model="name" label="Name" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col col="12">
                    <mdb-input type="password" v-model="password" label="Password" size="sm" />
                </mdb-col>
            </mdb-row>
            <mdb-row v-for="role in roles" :key="role.id" class="permission-row">
              <mdb-col col="12">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" :checked="checkSelected(role)" @click="addSelected(role)" class="custom-control-input" :id="'checkbox-'+role.id">
                      <label class="custom-control-label" :for="'checkbox-'+role.id">
                          {{role.name}}
                      </label>
                  </div>
              </mdb-col>
            </mdb-row>
          </mdb-container>
      </mdb-modal-body>
      <mdb-modal-footer>
          <mdb-spinner v-if="loading" />
        <mdb-btn v-if="!loading" @click="submitAdminUser" color="primary">{{this.id?"Edit":"Add"}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbContainer, mdbRow, mdbCol,
  mdbInput, mdbSpinner } from 'mdbvue';
  import { ajax } from '../js/ajax';

  export default {
    components: {
      mdbModal, mdbSpinner,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter, mdbInput,
      mdbBtn,mdbContainer, mdbRow, mdbCol,
    },
    props: ['visible', 'close', "focus_user", 'roles'],
    data: ()=>({
        loading:false,
        id: 0,
        username: "",
        name: "",
        password: "",
        selected_roles: [],
    }),
    methods: {
        closeModal(){
            this.username="";
            this.name="";
            this.password="";
            this.id=0;
            this.loading=false;
            this.close();
        },
        componentDidMount(){
            if(this.focus_user){
                this.username=this.focus_user.username;
                this.name=this.focus_user.name;
                this.id=this.focus_user.id;
                this.password=this.focus_user.password;
                this.selected_roles=this.focus_user.roles;
            }
        },
        submitAdminUser(){
          if(this.loading){
              return false;
          }
          this.loading=true;
          ajax({
              url: this.id==0?"admin/submit_admin":"admin/edit_admin",
              data: {id: this.id, selected_roles: this.selected_roles, name: this.name, username: this.username, password: this.password},
              complete: ()=>this.loading=false,
              success: ()=>{
                  this.$notify.success({message: this.id==0?"Admin Added":"Admin Updated", position:'top right', timeOut: 5000});
                  this.close();
              },
          }, this)
        },
        addSelected(role){
          const _index = this.selected_roles.findIndex(el => {return el.id === role.id});
          if(_index<0){
              this.selected_roles.push(role);
          }else{
              this.selected_roles.splice(_index, 1);
          }
        },
        checkSelected(role){
          const _index = this.selected_roles.findIndex(el => {return el.id === role.id});
          return _index<0?false:true;
        }
    }
  };
</script>